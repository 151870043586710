/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    alignItems: 'stretch',
    backgroundColor: 'transparent',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    padding: 0,
  },
  item: {
    alignItems: 'stretch',
    color: 'white',
    flex: 1,
    fontSize: 10,
    justifyContent: 'flex-start',
    paddingHorizontal: 2,
    textAlign: 'left',
  },
  textContainer: {
    borderRadius: 3,
    flexDirection: 'row',
    marginVertical: 1,
    overflow: 'hidden',
  },
  title: {
    color: 'black',
    flex: 1,
    fontSize: 12,
    padding: 2,
    paddingHorizontal: 10,
    textAlign: 'center',
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

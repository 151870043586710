/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';
import { BORDER_RADIUS, FONT_SIZE, GENERAL_STYLES, MARGIN_HORIZONTAL, MARGIN_VERTICAL, PADDING } from '#constants/styles';
import COLORS, { BACKGROUND_COLORS } from '#constants/colors';

export default StyleSheet.create({
  ...GENERAL_STYLES,
  buttons: {
    ...GENERAL_STYLES.buttons,
    alignItems: 'stretch',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: MARGIN_HORIZONTAL,
    paddingTop: MARGIN_HORIZONTAL,
  },
  checkBox: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 0,
    borderWidth: 0,
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-start',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    padding: PADDING,
  },
  checkBoxText: {
    flex: 1,
    flexWrap: 'wrap',
    fontSize: FONT_SIZE,
    fontWeight: 'normal',
    margin: 0,
    padding: 0,
    textAlign: 'justify',
  },
  page: {
    ...GENERAL_STYLES.page,
    backgroundColor: BACKGROUND_COLORS.MAIN,
  },
  scrollView: {
    ...GENERAL_STYLES.scrollView,
    backgroundColor: COLORS.CHROME_GREY,
    marginTop: 0,
    marginVertical: 0,
    paddingVertical: 0,
  },
  section: {
    alignItems: 'stretch',
    backgroundColor: BACKGROUND_COLORS.MAIN,
    borderRadius: BORDER_RADIUS,
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: MARGIN_VERTICAL,
    marginHorizontal: 0,
    paddingHorizontal: PADDING,
  },
  sectionTitle: {
    fontSize: FONT_SIZE,
    fontWeight: 'bold',
    paddingBottom: PADDING,
    textTransform: 'capitalize',
  },
  subSection: {
    alignItems: 'stretch',
    backgroundColor: BACKGROUND_COLORS.CONTENT,
    borderRadius: BORDER_RADIUS,
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 0,
    overflow: 'hidden',
    padding: 0,
  },
  text: {
    fontSize: FONT_SIZE,
    textAlign: 'left',
  },
  title: {
    color: COLORS.BLACK,
    fontWeight: 'bold',
    paddingVertical: PADDING,
    textAlign: 'center',
  },
});

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import { MESSAGES } from '#constants/index';
import bookOpenIcon from '#assets/book-open.png';
import bookWriteIcon from '#assets/book-write.png';
import settingsIcon from '#assets/settings.png';
import BaseButton from '#components/buttons';
import Card from '#components/buttons/card';
import Day from '#components/calendars/day.calendar';
import DatePicker from '#components/inputs/datepicker.input';
import COLORS, { BACKGROUND_COLORS } from '#constants/colors';
import { DIARY_OPTION_COLOR_MAPPING } from '#constants/diary';
import { BORDER_RADIUS, FONT_LABEL_SIZE, FONT_SIZE, FONT_TITLE_SIZE, MARGIN_HORIZONTAL, MARGIN_VERTICAL, PADDING } from '#constants/styles';
import { Messages } from '#data/extras';
import { Settings } from '#data/settings';
import { User } from '#data/user';
import { default as Loading, default as LoadingPage, NoDataPage } from '#pages/loading.page';
import { NavigationProp, useFocusEffect } from '@react-navigation/core';
import { CheckBox, Icon, Input, Switch, Text } from '@rneui/themed';
import diaryService from '#services/diary.service';
import notificationService from '#services/notification.service';
import appUtil from '#utils/app.util';
import { defaultsDeep, get, isEmpty, isNaN } from 'lodash';
import moment from 'moment';
import 'moment/min/locales';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, Alert, FlatList, SafeAreaView, ScrollView, View, useWindowDimensions } from 'react-native';
import { Calendar } from 'react-native-calendars';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import RenderHtml from 'react-native-render-html';
import styles, { checkBoxTextHtmlStyle } from './styles/diary.page';

interface DiarySavePageProps {
  navigation: NavigationProp<any>;
  messages: Messages;
  user: User;
  setError: Dispatch<SetStateAction<string>>;
  route: any;
}

export function DiarySavePage(props: DiarySavePageProps) {
  const [hasDaytimeSymptomsMoreThan3m, setHasDaytimeSymptomsMoreThan3m] = useState(false);
  const [hasNightWaking, setHasNightWaking] = useState(false);
  const [hasRelieverUsedPast24h, setHasRelieverUsedPast24h] = useState(false);
  const [numRelieverUsedPast24h, setNumRelieverUsedPast24h]: [any, any] = useState(null);
  const [timeIntervalBetweenRelieverUsages, setTimeIntervalBetweenRelieverUsages]: [any, any] = useState(null);
  const [hasActivityLimitation, setHasActivityLimitation] = useState(false);
  // const [hasPreventerUsedPast24h, setHasPreventerUsedPast24h] = useState(false);
  const [hasPreventerUsedPast24hInMorning, setHasPreventerUsedPast24hInMorning] = useState(false);
  const [hasPreventerUsedPast24hInNight, setHasPreventerUsedPast24hInNight] = useState(false);
  const [inNormalCondition, setInNormalCondition] = useState(false);

  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numRelieverUsedPast24hError, setNumRelieverUsedPast24hError] = useState('');
  const [timeIntervalBetweenRelieverUsagesError, setTimeIntervalBetweenRelieverUsagesError] = useState('');

  const { width } = useWindowDimensions();

  const clear = () => {
    setShowCancelDialog(false);
    setHasDaytimeSymptomsMoreThan3m(false);
    setHasNightWaking(false);
    setHasActivityLimitation(false);
    setHasRelieverUsedPast24h(false);
    // setHasPreventerUsedPast24h(false);
    setHasPreventerUsedPast24hInMorning(false);
    setHasPreventerUsedPast24hInNight(false);
    setInNormalCondition(false);
  };

  const onCancel = () => {
    clear();
    props.navigation.goBack();
  };

  const onSave = () => {
    setLoading(true);
    const data = {
      hasDaytimeSymptomsMoreThan3m,
      hasNightWaking,
      hasRelieverUsedPast24h,
      numRelieverUsedPast24h,
      timeIntervalBetweenRelieverUsages,
      hasActivityLimitation,
      // hasPreventerUsedPast24h,
      hasPreventerUsedPast24hInMorning,
      hasPreventerUsedPast24hInNight,
      inNormalCondition,
    };
    diaryService
      .saveDiary(props.user.id, props.user.token, data)
      .then(() => setShowMessageDialog(true))
      .catch((e: any) => props.setError(e.message))
      .then(() => {
        clear();
        setLoading(false);
      })
      .finally(() => {
        const redirect = get(props.route, 'params.redirect');
        if (redirect) {
          props.navigation.reset({ index: 0, routes: [{ name: redirect }] });
        }
      });
  };

  const onSetNumRelieverUsedPast24h = (value: any) => {
    setNumRelieverUsedPast24h(value);
    if (isNaN(Number(value))) {
      setNumRelieverUsedPast24hError(appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER]));
    } else {
      setNumRelieverUsedPast24hError('');
    }
  };

  const onSetTimeIntervalBetweenRelieverUsages = (value: any) => {
    setTimeIntervalBetweenRelieverUsages(value);
    if (isNaN(Number(value))) {
      setTimeIntervalBetweenRelieverUsagesError(appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER]));
    } else {
      setTimeIntervalBetweenRelieverUsagesError('');
    }
  };

  useEffect(() => {
    if (showCancelDialog) {
      Alert.alert(
        appUtil.formatSentence(props.messages[MESSAGES.ALERT]),
        appUtil.formatSentence(props.messages[MESSAGES.DIARY_SAVE_CANCEL_DESCRIPTION]),
        [
          {
            text: appUtil.formatSentence(props.messages[MESSAGES.YES]),
            onPress: onCancel,
            style: 'destructive',
          },
          {
            text: appUtil.formatSentence(props.messages[MESSAGES.NO]),
            onPress: () => setShowCancelDialog(false),
            style: 'cancel',
          },
        ],
      );
    }
  }, [showCancelDialog]);

  useEffect(() => {
    if (showMessageDialog) {
      Alert.alert(appUtil.formatSentence(props.messages[MESSAGES.ALERT]), appUtil.formatSentence(props.messages[MESSAGES.DIARY_SAVE_SUCCESS]), [
        {
          onPress: () => setShowMessageDialog(false),
          style: 'default',
        },
      ]);
    }
  }, [showMessageDialog]);

  useEffect(() => {
    if (
      [
        hasDaytimeSymptomsMoreThan3m,
        hasNightWaking,
        hasRelieverUsedPast24h,
        numRelieverUsedPast24h,
        hasActivityLimitation,
        // hasPreventerUsedPast24h,
        hasPreventerUsedPast24hInMorning,
        hasPreventerUsedPast24hInNight,
      ].includes(true)
    ) {
      setInNormalCondition(false);
    }
  }, [
    hasDaytimeSymptomsMoreThan3m,
    hasNightWaking,
    hasRelieverUsedPast24h,
    numRelieverUsedPast24h,
    hasActivityLimitation,
    hasPreventerUsedPast24hInMorning,
    hasPreventerUsedPast24hInNight,
  ]);

  return (
    <SafeAreaView style={styles.page}>
      {loading ? (
        <Loading />
      ) : (
        <KeyboardAwareScrollView contentContainerStyle={styles.scrollView}>
          <View style={styles.card}>
            <CheckBox
              title={
                <RenderHtml
                  baseStyle={checkBoxTextHtmlStyle}
                  contentWidth={width}
                  source={{ html: props.messages[MESSAGES.DIARY_HTML_DAYTIME_SYMPTOM] }}
                />
              }
              checked={hasDaytimeSymptomsMoreThan3m}
              onPress={() => setHasDaytimeSymptomsMoreThan3m(!hasDaytimeSymptomsMoreThan3m)}
              iconType='material-community'
              checkedIcon='checkbox-marked-circle'
              uncheckedIcon='checkbox-blank-circle-outline'
              checkedColor={DIARY_OPTION_COLOR_MAPPING.hasDaytimeSymptomsMoreThan3m}
              textStyle={styles.checkBoxText}
              containerStyle={{
                ...styles.checkBox,
                borderLeftWidth: 3,
                borderLeftColor: DIARY_OPTION_COLOR_MAPPING.hasDaytimeSymptomsMoreThan3m,
                borderStartColor: DIARY_OPTION_COLOR_MAPPING.hasDaytimeSymptomsMoreThan3m,
              }}
            />
            <CheckBox
              title={
                <RenderHtml
                  baseStyle={checkBoxTextHtmlStyle}
                  contentWidth={width}
                  source={{ html: props.messages[MESSAGES.DIARY_HTML_NIGHT_WAKING] }}
                />
              }
              checked={hasNightWaking}
              onPress={() => setHasNightWaking(!hasNightWaking)}
              iconType='material-community'
              checkedIcon='checkbox-marked-circle'
              uncheckedIcon='checkbox-blank-circle-outline'
              checkedColor={DIARY_OPTION_COLOR_MAPPING.hasNightWaking}
              textStyle={styles.checkBoxText}
              containerStyle={{
                ...styles.checkBox,
                borderLeftWidth: 3,
                borderLeftColor: DIARY_OPTION_COLOR_MAPPING.hasNightWaking,
                borderStartColor: DIARY_OPTION_COLOR_MAPPING.hasNightWaking,
              }}
            />
            <CheckBox
              title={
                <RenderHtml
                  baseStyle={checkBoxTextHtmlStyle}
                  contentWidth={width}
                  source={{ html: props.messages[MESSAGES.DIARY_HTML_RELIEVER_USED] }}
                />
              }
              checked={hasRelieverUsedPast24h}
              onPress={() => setHasRelieverUsedPast24h(!hasRelieverUsedPast24h)}
              iconType='material-community'
              checkedIcon='checkbox-marked-circle'
              uncheckedIcon='checkbox-blank-circle-outline'
              checkedColor={DIARY_OPTION_COLOR_MAPPING.hasRelieverUsedPast24h}
              textStyle={styles.checkBoxText}
              containerStyle={{
                ...styles.checkBox,
                borderLeftWidth: 3,
                borderLeftColor: DIARY_OPTION_COLOR_MAPPING.hasRelieverUsedPast24h,
                borderStartColor: DIARY_OPTION_COLOR_MAPPING.hasRelieverUsedPast24h,
              }}
            />
            {hasRelieverUsedPast24h && (
              <View
                style={{
                  ...styles.input,
                  flexDirection: 'column',
                  borderLeftWidth: 3,
                  borderLeftColor: DIARY_OPTION_COLOR_MAPPING.hasRelieverUsedPast24h,
                }}>
                <Input
                  label={appUtil.formatSentence(props.messages[MESSAGES.DIARY_NUM_RELIEVER_USED])}
                  placeholder={appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER])}
                  onChangeText={onSetNumRelieverUsedPast24h}
                  value={numRelieverUsedPast24h}
                  inputStyle={{ fontSize: FONT_SIZE }}
                  containerStyle={{ paddingLeft: 50, paddingRight: 18 }}
                  errorMessage={numRelieverUsedPast24hError}
                  labelStyle={{ fontSize: FONT_LABEL_SIZE }}
                  keyboardType='numeric'
                />
              </View>
            )}
            {hasRelieverUsedPast24h && numRelieverUsedPast24h >= 2 && (
              <View
                style={{
                  ...styles.input,
                  flexDirection: 'column',
                  borderLeftWidth: 3,
                  borderLeftColor: DIARY_OPTION_COLOR_MAPPING.hasRelieverUsedPast24h,
                }}>
                <Input
                  label={appUtil.formatSentence(props.messages[MESSAGES.DIARY_INTERVAL_RELIEVER_USED])}
                  placeholder={appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER])}
                  onChangeText={onSetTimeIntervalBetweenRelieverUsages}
                  value={timeIntervalBetweenRelieverUsages}
                  inputStyle={{ fontSize: FONT_SIZE }}
                  containerStyle={{ paddingLeft: 50, paddingRight: 18 }}
                  errorMessage={timeIntervalBetweenRelieverUsagesError}
                  labelStyle={{ fontSize: FONT_LABEL_SIZE }}
                  keyboardType='numeric'
                />
              </View>
            )}
            <CheckBox
              title={
                <RenderHtml
                  baseStyle={checkBoxTextHtmlStyle}
                  contentWidth={width}
                  source={{ html: props.messages[MESSAGES.DIARY_HTML_ACTIVITY_LIMITATION] }}
                />
              }
              checked={hasActivityLimitation}
              onPress={() => setHasActivityLimitation(!hasActivityLimitation)}
              iconType='material-community'
              checkedIcon='checkbox-marked-circle'
              uncheckedIcon='checkbox-blank-circle-outline'
              checkedColor={DIARY_OPTION_COLOR_MAPPING.hasActivityLimitation}
              textStyle={styles.checkBoxText}
              containerStyle={{
                ...styles.checkBox,
                borderLeftWidth: 3,
                borderLeftColor: DIARY_OPTION_COLOR_MAPPING.hasActivityLimitation,
                borderStartColor: DIARY_OPTION_COLOR_MAPPING.hasActivityLimitation,
              }}
            />
            <CheckBox
              title={appUtil.formatSentence(props.messages[MESSAGES.DIARY_PREVENTER_USED_MORNING])}
              checked={hasPreventerUsedPast24hInMorning}
              onPress={() => setHasPreventerUsedPast24hInMorning(!hasPreventerUsedPast24hInMorning)}
              iconType='material-community'
              checkedIcon='checkbox-marked-circle'
              uncheckedIcon='checkbox-blank-circle-outline'
              checkedColor={DIARY_OPTION_COLOR_MAPPING.hasPreventerUsedPast24hInMorning}
              textStyle={styles.checkBoxText}
              containerStyle={{
                ...styles.checkBox,
                borderLeftWidth: 3,
                borderLeftColor: DIARY_OPTION_COLOR_MAPPING.hasPreventerUsedPast24hInMorning,
                borderStartColor: DIARY_OPTION_COLOR_MAPPING.hasPreventerUsedPast24hInMorning,
              }}
            />
            <CheckBox
              title={appUtil.formatSentence(props.messages[MESSAGES.DIARY_PREVENTER_USED_NIGHT])}
              checked={hasPreventerUsedPast24hInNight}
              onPress={() => setHasPreventerUsedPast24hInNight(!hasPreventerUsedPast24hInNight)}
              iconType='material-community'
              checkedIcon='checkbox-marked-circle'
              uncheckedIcon='checkbox-blank-circle-outline'
              checkedColor={DIARY_OPTION_COLOR_MAPPING.hasPreventerUsedPast24hInNight}
              textStyle={styles.checkBoxText}
              containerStyle={{
                ...styles.checkBox,
                borderLeftWidth: 3,
                borderLeftColor: DIARY_OPTION_COLOR_MAPPING.hasPreventerUsedPast24hInNight,
                borderStartColor: DIARY_OPTION_COLOR_MAPPING.hasPreventerUsedPast24hInNight,
              }}
            />
            <CheckBox
              title={appUtil.formatSentence(props.messages[MESSAGES.DIARY_IS_NORMAL])}
              checked={inNormalCondition}
              onPress={() => {
                clear();
                setInNormalCondition(!inNormalCondition);
              }}
              iconType='material-community'
              checkedIcon='checkbox-marked-circle'
              uncheckedIcon='checkbox-blank-circle-outline'
              checkedColor={DIARY_OPTION_COLOR_MAPPING.isNormal}
              textStyle={styles.checkBoxText}
              containerStyle={{
                ...styles.checkBox,
                borderLeftWidth: 3,
                borderLeftColor: DIARY_OPTION_COLOR_MAPPING.isNormal,
                borderStartColor: DIARY_OPTION_COLOR_MAPPING.isNormal,
              }}
            />
          </View>
          <View style={{ ...styles.gridRow, marginHorizontal: MARGIN_HORIZONTAL }}>
            <BaseButton
              type='clear'
              backgroundColor='white'
              color={COLORS.BLACK}
              onPress={() => setShowCancelDialog(true)}
              containerStyle={{ marginRight: MARGIN_HORIZONTAL }}
              title={appUtil.formatSentence(props.messages[MESSAGES.CANCEL])}
            />
            <BaseButton
              type='solid'
              backgroundColor={COLORS.RED}
              color={COLORS.WHITE}
              onPress={onSave}
              icon={{
                name: 'content-save-all',
                type: 'material-community',
                size: FONT_SIZE,
                color: COLORS.WHITE,
              }}
              containerStyle={{ flexShrink: 0.5 }}
              title={appUtil.formatSentence(props.messages[MESSAGES.SAVE])}
              titleStyle={{ fontWeight: 'bold' }}
            />
          </View>
        </KeyboardAwareScrollView>
      )}
    </SafeAreaView>
  );
}

interface DiaryListPageProps {
  messages: Messages;
  user: User;
  setError: Dispatch<SetStateAction<string>>;
  route: any;
  settings: Settings;
}

export function DiaryListPage(props: DiaryListPageProps) {
  const [list, setList]: [any[], any] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [footerLoading, setFooterLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const { width } = useWindowDimensions();

  const getDiaryData = async (newPage: number) => {
    if (newPage == 0) setIsLoading(true);
    const { fromEpoch, toEpoch } = get(props.route, 'params', {});
    const res = await diaryService.getDiaries(props.user.id, props.user.token, newPage, 3, fromEpoch, toEpoch).catch((e) => console.error(e));
    if (newPage === 0) {
      setList(get(res, 'content', []));
      setIsLoading(false);
    } else {
      setList([...list, ...get(res, 'content', [])]);
    }
    setTotalPage(get(res, 'totalPages', 0));
    setPage(newPage);
  };

  const onEndReached = async () => {
    const nextPage = page + 1;
    if (nextPage >= totalPage) return;
    setFooterLoading(true);
    await getDiaryData(nextPage);
    setFooterLoading(false);
  };

  const keyExtractor = (item: any, index: number) => {
    return index.toString();
  };

  const renderFooter = () => {
    return footerLoading ? (
      <View style={styles.listFooter}>
        <ActivityIndicator
          size='small'
          color='rgba(82, 113, 255, 0.9)'
        />
      </View>
    ) : null;
  };

  const renderItem = ({ item, index }: any) => {
    const {
      hasDaytimeSymptomsMoreThan3m,
      hasNightWaking,
      hasRelieverUsedPast24h,
      numRelieverUsedPast24h,
      timeIntervalBetweenRelieverUsages,
      hasActivityLimitation,
      hasPreventerUsedPast24h,
      hasPreventerUsedPast24hInMorning,
      hasPreventerUsedPast24hInNight,
      inNormalCondition,
      createdAt: cAt,
    } = item;
    const createdAt = parseInt(String(cAt * 1000));
    return (
      <View
        style={styles.listItem}
        key={index}>
        <View style={{ ...styles.listItemTitle, borderBottomColor: COLORS.CHROME_GREY, borderBottomWidth: 1, paddingBottom: MARGIN_VERTICAL }}>
          <View style={styles.listItemTitleRow}>
            <Text style={{ fontSize: FONT_SIZE, fontWeight: 'bold', textTransform: 'capitalize', padding: 0, margin: 0 }}>
              {moment(createdAt)
                .local()
                .locale(props.settings.locale.includes('vi') ? 'vi' : 'en-gb')
                .format('ddd, DD MMM, YYYY')}
            </Text>
          </View>
          <View style={styles.listItemTitleRow}>
            <Icon
              name='clock'
              size={FONT_SIZE}
              type='material-community'
              containerStyle={{ marginRight: 4 }}
            />
            <Text style={{ fontSize: FONT_SIZE, padding: 0, margin: 0 }}>{moment(createdAt).local().format('HH:MM:ss')}</Text>
          </View>
        </View>
        <CheckBox
          title={
            <RenderHtml
              baseStyle={checkBoxTextHtmlStyle}
              contentWidth={width}
              source={{ html: props.messages[MESSAGES.DIARY_HTML_DAYTIME_SYMPTOM] }}
            />
          }
          checked={hasDaytimeSymptomsMoreThan3m}
          iconType='material-community'
          checkedIcon='checkbox-marked-circle'
          uncheckedIcon='checkbox-blank-circle-outline'
          textStyle={styles.checkBoxText}
          checkedColor={DIARY_OPTION_COLOR_MAPPING.hasDaytimeSymptomsMoreThan3m}
          containerStyle={{ ...styles.checkBox, ...styles.noMargin }}
          disabledTitleStyle={{ color: 'black' }}
          disabled={true}
        />
        <CheckBox
          title={
            <RenderHtml
              baseStyle={checkBoxTextHtmlStyle}
              contentWidth={width}
              source={{ html: props.messages[MESSAGES.DIARY_HTML_NIGHT_WAKING] }}
            />
          }
          checked={hasNightWaking}
          iconType='material-community'
          checkedIcon='checkbox-marked-circle'
          uncheckedIcon='checkbox-blank-circle-outline'
          textStyle={styles.checkBoxText}
          checkedColor={DIARY_OPTION_COLOR_MAPPING.hasNightWaking}
          containerStyle={{ ...styles.checkBox, ...styles.noMargin }}
          disabledTitleStyle={{ color: 'black' }}
          disabled={true}
        />
        <CheckBox
          title={
            <RenderHtml
              baseStyle={checkBoxTextHtmlStyle}
              contentWidth={width}
              source={{ html: props.messages[MESSAGES.DIARY_HTML_RELIEVER_USED] }}
            />
          }
          checked={hasRelieverUsedPast24h}
          iconType='material-community'
          checkedIcon='checkbox-marked-circle'
          uncheckedIcon='checkbox-blank-circle-outline'
          textStyle={styles.checkBoxText}
          checkedColor={DIARY_OPTION_COLOR_MAPPING.hasRelieverUsedPast24h}
          containerStyle={{ ...styles.checkBox, ...styles.noMargin }}
          disabledTitleStyle={{ color: 'black' }}
          disabled={true}
        />
        {!isEmpty(hasRelieverUsedPast24h) && (
          <Input
            label={appUtil.formatSentence(props.messages[MESSAGES.DIARY_NUM_RELIEVER_USED])}
            value={numRelieverUsedPast24h && String(numRelieverUsedPast24h)}
            inputStyle={{ fontSize: FONT_SIZE }}
            containerStyle={{ paddingLeft: 50, paddingRight: 18 }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
            editable={false}
          />
        )}
        {hasRelieverUsedPast24h && numRelieverUsedPast24h >= 2 && (
          <Input
            label={appUtil.formatSentence(props.messages[MESSAGES.DIARY_INTERVAL_RELIEVER_USED])}
            value={timeIntervalBetweenRelieverUsages && String(timeIntervalBetweenRelieverUsages)}
            inputStyle={{ fontSize: FONT_SIZE }}
            containerStyle={{ paddingLeft: 50, paddingRight: 18 }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
            editable={false}
          />
        )}
        <CheckBox
          title={
            <RenderHtml
              baseStyle={checkBoxTextHtmlStyle}
              contentWidth={width}
              source={{ html: props.messages[MESSAGES.DIARY_HTML_ACTIVITY_LIMITATION] }}
            />
          }
          checked={hasActivityLimitation}
          iconType='material-community'
          checkedIcon='checkbox-marked-circle'
          uncheckedIcon='checkbox-blank-circle-outline'
          textStyle={styles.checkBoxText}
          checkedColor={DIARY_OPTION_COLOR_MAPPING.hasActivityLimitation}
          containerStyle={{ ...styles.checkBox, ...styles.noMargin }}
          disabledTitleStyle={{ color: 'black' }}
          disabled={true}
        />
        {hasPreventerUsedPast24h && (
          <CheckBox
            title={appUtil.formatSentence(props.messages[MESSAGES.DIARY_PREVENTER_USED])}
            checked={hasPreventerUsedPast24h}
            iconType='material-community'
            checkedIcon='checkbox-marked-circle'
            uncheckedIcon='checkbox-blank-circle-outline'
            textStyle={styles.checkBoxText}
            checkedColor={DIARY_OPTION_COLOR_MAPPING.hasPreventerUsedPast24h}
            containerStyle={{ ...styles.checkBox, ...styles.noMargin }}
            disabledTitleStyle={{ color: 'black' }}
            disabled={true}
          />
        )}
        <CheckBox
          title={appUtil.formatSentence(props.messages[MESSAGES.DIARY_PREVENTER_USED_MORNING])}
          checked={hasPreventerUsedPast24hInMorning}
          iconType='material-community'
          checkedIcon='checkbox-marked-circle'
          uncheckedIcon='checkbox-blank-circle-outline'
          textStyle={styles.checkBoxText}
          checkedColor={DIARY_OPTION_COLOR_MAPPING.hasPreventerUsedPast24hInMorning}
          containerStyle={{ ...styles.checkBox, ...styles.noMargin }}
          disabledTitleStyle={{ color: 'black' }}
          disabled={true}
        />
        <CheckBox
          title={appUtil.formatSentence(props.messages[MESSAGES.DIARY_PREVENTER_USED_NIGHT])}
          checked={hasPreventerUsedPast24hInNight}
          iconType='material-community'
          checkedIcon='checkbox-marked-circle'
          uncheckedIcon='checkbox-blank-circle-outline'
          textStyle={styles.checkBoxText}
          checkedColor={DIARY_OPTION_COLOR_MAPPING.hasPreventerUsedPast24hInNight}
          containerStyle={{ ...styles.checkBox, ...styles.noMargin }}
          disabledTitleStyle={{ color: 'black' }}
          disabled={true}
        />
        <CheckBox
          title={appUtil.formatSentence(props.messages[MESSAGES.DIARY_IS_NORMAL])}
          checked={inNormalCondition}
          iconType='material-community'
          checkedIcon='checkbox-marked-circle'
          uncheckedIcon='checkbox-blank-circle-outline'
          textStyle={styles.checkBoxText}
          checkedColor={DIARY_OPTION_COLOR_MAPPING.isNormal}
          containerStyle={{ ...styles.checkBox, ...styles.noMargin }}
          disabledTitleStyle={{ color: 'black' }}
          disabled={true}
        />
      </View>
    );
  };

  useFocusEffect(
    useCallback(() => {
      getDiaryData(0);
    }, [props.route.params]),
  );

  if (isLoading) return <LoadingPage withImage />;

  return (
    <SafeAreaView style={styles.page}>
      <FlatList
        data={list}
        refreshing={refreshing}
        onRefresh={() => getDiaryData(0).then(() => setRefreshing(false))}
        initialNumToRender={3}
        keyExtractor={keyExtractor}
        contentContainerStyle={styles.scrollView}
        renderItem={renderItem}
        ListFooterComponent={renderFooter}
        onEndReached={onEndReached}
        onEndReachedThreshold={0}
        ListEmptyComponent={<NoDataPage {...props} />}
      />
    </SafeAreaView>
  );
}

interface DiaryViewPageProps {
  navigation: NavigationProp<any>;
  messages: Messages;
  user: User;
  setError: Dispatch<SetStateAction<string>>;
}

export function DiaryViewPage(props: DiaryViewPageProps) {
  const getListDataOfDay = async (day: number, month: number, year: number) => {
    const date = moment(`${day}/${month}/${year}`, 'DD/MM/yyyy');
    const startOfDay = date.startOf('day').valueOf();
    const endOfDay = date.endOf('day').valueOf();
    const listData: any[] = [];
    await diaryService
      .getDiaries(props.user.id, props.user.token, 0, 1, startOfDay, endOfDay)
      .then((res) => {
        const {
          hasDaytimeSymptomsMoreThan3m,
          hasNightWaking,
          hasRelieverUsedPast24h,
          hasActivityLimitation,
          hasPreventerUsedPast24h,
          hasPreventerUsedPast24hInMorning,
          hasPreventerUsedPast24hInNight,
          inNormalCondition,
        } = get(res, 'content[0]', {});
        hasDaytimeSymptomsMoreThan3m &&
          listData.push({
            color: DIARY_OPTION_COLOR_MAPPING.hasDaytimeSymptomsMoreThan3m,
            backgroundColor: COLORS.GREY_50,
            title: props.messages[MESSAGES.DIARY_DAYTIME_SYMPTOM_TITLE],
          });
        hasNightWaking &&
          listData.push({
            color: DIARY_OPTION_COLOR_MAPPING.hasNightWaking,
            backgroundColor: COLORS.GREY_50,
            title: props.messages[MESSAGES.DIARY_NIGHT_WAKING_TITLE],
          });
        hasRelieverUsedPast24h &&
          listData.push({
            color: DIARY_OPTION_COLOR_MAPPING.hasRelieverUsedPast24h,
            backgroundColor: COLORS.GREY_50,
            title: props.messages[MESSAGES.DIARY_RELIEVER_USED_TITLE],
          });
        hasActivityLimitation &&
          listData.push({
            color: DIARY_OPTION_COLOR_MAPPING.hasActivityLimitation,
            backgroundColor: COLORS.GREY_50,
            title: props.messages[MESSAGES.DIARY_ACTIVITY_LIMITATION_TITLE],
          });
        hasPreventerUsedPast24h &&
          listData.push({
            color: DIARY_OPTION_COLOR_MAPPING.hasPreventerUsedPast24h,
            backgroundColor: COLORS.GREY_50,
            title: props.messages[MESSAGES.DIARY_PREVENTER_USED_TITLE],
          });
        hasPreventerUsedPast24hInMorning &&
          listData.push({
            color: DIARY_OPTION_COLOR_MAPPING.hasPreventerUsedPast24hInMorning,
            backgroundColor: COLORS.GREY_50,
            title: props.messages[MESSAGES.DIARY_PREVENTER_USED_TITLE],
          });
        hasPreventerUsedPast24hInNight &&
          listData.push({
            color: DIARY_OPTION_COLOR_MAPPING.hasPreventerUsedPast24hInNight,
            backgroundColor: COLORS.GREY_50,
            title: props.messages[MESSAGES.DIARY_PREVENTER_USED_TITLE],
          });
        inNormalCondition &&
          listData.push({
            color: DIARY_OPTION_COLOR_MAPPING.isNormal,
            backgroundColor: COLORS.GREY_50,
            title: props.messages[MESSAGES.DIARY_IS_NORMAL],
          });
      })
      .catch((e) => console.error(e));
    return listData;
  };

  return (
    <SafeAreaView style={{ ...styles.page, margin: MARGIN_VERTICAL }}>
      <Calendar
        style={{
          flexBasis: '100%',
          backgroundColor: BACKGROUND_COLORS.CONTENT,
          borderRadius: BORDER_RADIUS,
          overflow: 'hidden',
        }}
        theme={{
          // @ts-expect-error
          'stylesheet.calendar.main': {
            container: {
              flex: 1,
              backgroundColor: BACKGROUND_COLORS.CONTENT,
              paddingLeft: 0,
              paddingRight: 0,
              overflow: 'hidden',
              borderRadius: BORDER_RADIUS,
            },
            monthView: {
              flex: 1,
              backgroundColor: 'white',
              overflow: 'hidden',
            },
            dayContainer: {
              borderColor: COLORS.CHROME_GREY,
              borderWidth: 1,
              borderRadius: 0,
              backgroundColor: BACKGROUND_COLORS.CONTENT,
              overflow: 'hidden',
              flex: 1,
            },
            emptyDayContainer: {
              borderColor: COLORS.CHROME_GREY,
              borderWidth: 1,
              borderRadius: 0,
              backgroundColor: BACKGROUND_COLORS.CONTENT,
              overflow: 'hidden',
              flex: 1,
            },
            week: {
              marginTop: 0,
              marginBottom: 0,
              flexDirection: 'row',
              justifyContent: 'space-around',
              flex: 1,
            },
          },
        }}
        showWeekNumbers={false}
        // hideExtraDays={true}
        monthFormat={'MMMM, yyyy'}
        enableSwipeMonths={false}
        onDayPress={(date) => {
          const currentDate = moment(`${date.day}/${date.month}/${date.year}`, 'DD/MM/yyyy');
          const fromEpoch = currentDate.startOf('day').valueOf();
          const toEpoch = currentDate.endOf('day').valueOf();
          props.navigation.navigate('DiaryList', { fromEpoch, toEpoch });
        }}
        dayComponent={(p) => (
          <Day
            {...p}
            selectedColor={COLORS.RED}
            selectedBackgroundColor={COLORS.TRANSPARENT_RED}
            getListDataOfDay={getListDataOfDay}
          />
        )}
      />
    </SafeAreaView>
  );
}

interface DiarySettingPageProps {
  user?: User;
  navigation: NavigationProp<any>;
  messages: Messages;
  settings: Settings;
  onChangeSettings(settings?: Settings, reload?: boolean, callback?: () => void): void;
}

export function DiarySettingPage(props: DiarySettingPageProps) {
  const defaultReminderDateTime = moment().local(true).set({ hour: 20, minute: 0, second: 0 }).toDate();

  const [diaryReminderLoading, setDiaryReminderLoading] = useState(false);
  const [diaryReminderEnabled, setDiaryReminderEnabled] = useState(true);
  const [diaryReminderDateTime, setDiaryReminderDateTime]: [any, any] = useState(defaultReminderDateTime);
  const [showReminderDatePicker, setShowReminderDatePicker]: [any, any] = useState(false);

  const onChangeReminder = (enabled: boolean, datetime?: Date) => {
    if (!datetime) return;
    setDiaryReminderLoading(true);
    const newSettings = defaultsDeep(
      {
        diaryReminder: {
          enabled,
          hour: datetime.getHours(),
          minute: datetime.getMinutes(),
        },
      },
      props.settings,
    );
    notificationService
      .registerDiaryReminder(props.messages, props.user, newSettings)
      .then((newerSettings) => {
        props.onChangeSettings(newerSettings, false, () => setDiaryReminderLoading(false));
      })
      .catch((e) => {
        console.error(e);
        setDiaryReminderLoading(false);
      })
      .finally(() => setDiaryReminderLoading(false));
  };

  const onChangeReminderDateTime = (event: any, datetime?: Date) => {
    setDiaryReminderDateTime(datetime);
    onChangeReminder(diaryReminderEnabled, datetime);
  };

  const onChangeReminderEnabled = (value: boolean) => {
    setDiaryReminderEnabled(value);
    onChangeReminder(value, diaryReminderDateTime);
  };

  useEffect(() => {
    setDiaryReminderEnabled(props.settings.diaryReminder.enabled);
    setDiaryReminderDateTime(
      moment().local(true).set({ hour: props.settings.diaryReminder.hour, minute: props.settings.diaryReminder.minute, second: 0 }).toDate(),
    );
  }, []);

  return (
    <SafeAreaView style={styles.page}>
      <KeyboardAwareScrollView contentContainerStyle={styles.scrollView}>
        <View style={{ ...styles.card, minHeight: undefined, paddingHorizontal: PADDING, paddingVertical: PADDING }}>
          <View style={{ ...styles.checkBox, paddingHorizontal: 8 }}>
            <Text style={{ ...styles.checkBoxText, marginLeft: 0 }}>{appUtil.formatSentence(props.messages[MESSAGES.DIARY_REMINDER_ENABLE])}</Text>
            {diaryReminderLoading ? (
              <ActivityIndicator />
            ) : (
              <Switch
                value={diaryReminderEnabled}
                onValueChange={onChangeReminderEnabled}
              />
            )}
          </View>
          <DatePicker
            messages={props.messages}
            value={diaryReminderDateTime}
            onChangeValue={onChangeReminderDateTime}
            label={appUtil.formatSentence(props.messages[MESSAGES.DIARY_REMINDER_DESCRIPTION])}
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.CHOOSE_TIME])}
            showDatePicker={showReminderDatePicker}
            setShowDatePicker={setShowReminderDatePicker}
            defaultValue={defaultReminderDateTime}
            mode='time'
          />
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

interface DiaryPageProps {
  navigation: NavigationProp<any>;
  messages: Messages;
}

function DiaryPage(props: DiaryPageProps) {
  return (
    <SafeAreaView style={styles.page}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Card
          image={bookWriteIcon}
          imageStyle={{ height: 32, width: 32 }}
          name={props.messages[MESSAGES.DIARY_SAVE]}
          color={COLORS.BLUE}
          headerStyle={{ flexDirection: 'column' }}
          titleStyle={{ fontSize: FONT_TITLE_SIZE }}
          descriptionStyle={{ color: 'black', textAlign: 'center' }}
          description={props.messages[MESSAGES.DIARY_SAVE_DESCRIPTION]}
          onPress={() => props.navigation.navigate('DiarySave')}
        />
        <Card
          image={bookOpenIcon}
          imageStyle={{ height: 32, width: 32 }}
          name={props.messages[MESSAGES.DIARY_GET]}
          color={COLORS.BLUE}
          headerStyle={{ flexDirection: 'column' }}
          titleStyle={{ fontSize: FONT_TITLE_SIZE }}
          descriptionStyle={{ color: 'black', textAlign: 'center' }}
          description={props.messages[MESSAGES.DIARY_GET_DESCRIPTION]}
          onPress={() => props.navigation.navigate('DiaryView')}
        />
        <Card
          image={settingsIcon}
          imageStyle={{ height: 32, width: 32 }}
          name={props.messages[MESSAGES.SETTINGS]}
          color={COLORS.GRAY}
          headerStyle={{ flexDirection: 'column' }}
          titleStyle={{ fontSize: FONT_TITLE_SIZE }}
          descriptionStyle={{ color: 'black', textAlign: 'center' }}
          description={props.messages[MESSAGES.DIARY_SETTING_DESCRIPTION]}
          onPress={() => props.navigation.navigate('DiarySetting')}
        />
      </ScrollView>
    </SafeAreaView>
  );
}

export default DiaryPage;

/* eslint-disable react-native/no-inline-styles */
import { MESSAGES } from '#constants/index';
import kaapIcon from '#assets/kaap-notification-icon.png';
import { MARGIN_HORIZONTAL, PADDING } from '#constants/styles';
import { ActionPlan, Messages } from '#data/extras';
import { Settings } from '#data/settings';
import { User } from '#data/user';
import AuthorizationPage from '#pages/authorization.page';
import ForgetPasswordPage from '#pages/forget-password.page';
import ProfilePage from '#pages/profile.page';
import ResetPasswordPage from '#pages/reset-password.page';
import { createNativeStackNavigator as createStackNavigator } from '@react-navigation/native-stack';
import appUtil from '#utils/app.util';
import { stackNavigationScreenOptions } from '#constants/options';
import React, { Dispatch, SetStateAction } from 'react';
import { Image } from 'react-native';

const Stack = createStackNavigator();

interface LoginNavigationProps {
  user?: User;
  onChangeUser(user?: User): Promise<User | undefined>;
  settings: Settings;
  onChangeSettings(settings?: Settings, reload?: boolean, callback?: () => void): void;
  messages: Messages;
  actionPlan: ActionPlan;
  setShowLogoutDialog: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function LoginNavigation(loginProps: LoginNavigationProps) {
  return (
    <Stack.Navigator
      initialRouteName='Login'
      screenOptions={{
        ...stackNavigationScreenOptions,
      }}>
      <Stack.Screen
        name='Login'
        options={{
          title: appUtil.formatSentence(loginProps.messages[MESSAGES.LOGIN]),
          headerLeft: () => (
            <Image
              source={kaapIcon}
              style={{ height: 36, width: 36, paddingHorizontal: PADDING, marginHorizontal: MARGIN_HORIZONTAL }}
            />
          ),
        }}>
        {(props: any) => (
          <AuthorizationPage
            {...loginProps}
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='Register'
        options={{ title: appUtil.formatSentence(loginProps.messages[MESSAGES.REGISTER]) }}>
        {(props: any) => (
          <ProfilePage
            {...props}
            isRegister
            {...loginProps}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='ForgetPassword'
        options={{ title: appUtil.formatSentence(loginProps.messages[MESSAGES.FORGOT_PASSWORD]) }}>
        {(props: any) => (
          <ForgetPasswordPage
            {...loginProps}
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='ResetPassword'
        options={{ title: appUtil.formatSentence(loginProps.messages[MESSAGES.RESET_PASSWORD]) }}>
        {(props: any) => (
          <ResetPasswordPage
            {...loginProps}
            {...props}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
}

/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import { Messages } from '#data/extras';
import React, { useState } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { ScrollView, View } from 'react-native';
import appUtil from '#utils/app.util';
import { CheckBox, Text } from '@rneui/themed';
import styles from './styles/terms-conditions.page';
import { MESSAGES } from '#constants/index';
import COLORS from '#constants/colors';
import { BORDER_RADIUS, PADDING } from '#constants/styles';
import BaseButton from '#components/buttons';

interface TermsAndConditionsPageProps {
  messages: Messages;
  tac: string | undefined;
  onAccept(): void | Promise<void>;
}

function TermsAndConditionsPage(props: TermsAndConditionsPageProps) {
  const [accept, setAccept] = useState(false);
  return (
    <SafeAreaView style={styles.page}>
      <View style={styles.title}>
        <Text style={styles.titleText}>{appUtil.formatUpper(props.messages[MESSAGES.TERMS_AND_CONDITIONS])}</Text>
      </View>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View>
          <Text style={styles.text}>{props.tac}</Text>
        </View>
      </ScrollView>
      <View style={styles.buttons}>
        <CheckBox
          title={appUtil.formatSentence(props.messages[MESSAGES.ACCEPT])}
          checked={accept}
          onPress={() => setAccept(!accept)}
          iconType='material-community'
          checkedIcon='checkbox-marked-circle'
          uncheckedIcon='checkbox-blank-circle-outline'
          checkedColor={COLORS.BLUE}
          textStyle={styles.checkBoxText}
          containerStyle={styles.checkBox}
        />
        <BaseButton
          type='clear'
          color={COLORS.BLUE}
          onPress={props.onAccept}
          disabled={!accept}
          icon={{
            size: 24,
            color: accept ? COLORS.BLUE : COLORS.TRANSPARENT_GRAY,
            name: 'arrow-right',
            type: 'material-community',
          }}
          containerStyle={{ flexBasis: undefined }}
          buttonStyle={{
            paddingHorizontal: PADDING,
            borderRadius: BORDER_RADIUS,
          }}
        />
      </View>
    </SafeAreaView>
  );
}

export default TermsAndConditionsPage;

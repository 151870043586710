import axios from 'axios';
import { get } from 'lodash';
import Constants from 'expo-constants';

// const OPEN_WEATHER_API_URL = get(Constants, 'manifest.extra.openWeatherApiUrl');
// const OPEN_WEATHER_API_KEY = get(Constants, 'manifest.extra.openWeatherApiKey');
// const IQAIR_API_URL = get(Constants, 'manifest.extra.iqairApiUrl');
// const IQAIR_API_KEY = get(Constants, 'manifest.extra.iqairApiKey');
const API_URL = get(Constants, 'manifest.extra.apiUrl');

const getCurrentWeatherInfo = async (locale: string, location: any) => {
  try {
    if (location) {
      const { latitude, longitude } = location.coords;
      return axios
        .get(`${API_URL}/air-info/current-weather`, {
          headers: { 'Accept-Language': locale },
          params: {
            lat: latitude,
            lon: longitude,
          },
        })
        .then((response) => get(response, 'data'));
    }
    return undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const getCurrentAirPollutionInfo = async (location: any) => {
  try {
    if (location) {
      const { latitude, longitude } = location.coords;
      return axios
        .get(`${API_URL}/air-info/current-pollution`, {
          params: {
            lat: latitude,
            lon: longitude,
          },
        })
        .then((response) => {
          const data = get(response, 'data');
          if (get(data, 'status') != 'success') {
            throw new Error(get(data, 'data.message'));
          }
          return get(data, 'data');
        });
    }
    return undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const getRecommendation = async (aqi: number, locale: string) => {
  const url = `${API_URL}/weather-recommendations?aqi=${aqi}`;
  return axios.get(url, { headers: { 'Accept-Language': locale } }).then((response) => get(response, 'data'));
};

export default {
  getCurrentWeatherInfo,
  getCurrentAirPollutionInfo,
  getRecommendation,
};

import axios from 'axios';
import { get } from 'lodash';
import Constants from 'expo-constants';

const ACTION_PLAN_URL = `${get(Constants, 'manifest.extra.apiUrl')}/action-plans`;

const getActionPlan = async (name: string, locale: string) => {
  const url = `${ACTION_PLAN_URL}/${name}`;
  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': locale,
    },
  });
  const data = get(response, 'data.actionPlanNodes');
  const result: { [id: string]: any } = {};
  data.forEach((node: any) => {
    result[node['id']] = node;
  });
  return result;
};

export default { getActionPlan };

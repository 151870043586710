import axios from 'axios';
import { get } from 'lodash';
import Constants from 'expo-constants';

const API_URL = get(Constants, 'manifest.extra.apiUrl');
const URL = `${API_URL}/terms-and-conditions`;

const getTermsAndConditionsLatestVersion = async (locale: string) => {
  const url = `${URL}/latest`;
  return axios.post(url, {}, { headers: { 'Accept-Language': locale } }).then((response) => get(response, 'data'));
};

const getTermsAndConditions = async (version: number, locale: string) => {
  const url = `${URL}/${version}`;
  return axios.get(url, { headers: { 'Accept-Language': locale } }).then((response) => get(response, 'data'));
};

export default {
  getTermsAndConditionsLatestVersion,
  getTermsAndConditions,
};

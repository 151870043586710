/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View, ActivityIndicator, Image, SafeAreaView } from 'react-native';
import styles from '#pages/styles/loading.page';
import kaapLogo from '#assets/kaap.png';
import { MARGIN_VERTICAL } from '#constants/styles';
import COLORS, { BACKGROUND_COLORS } from '#constants/colors';
import { Icon, Overlay } from '@rneui/base';
import { Text } from '@rneui/themed';
import { Messages } from '#data/extras';
import { MESSAGES } from '#constants/index';

interface LoadingProps {
  withImage?: boolean;
  color?: string;
}

export function Loading(props: LoadingProps) {
  return (
    <View style={styles.content}>
      {props.withImage && (
        <Image
          source={kaapLogo}
          style={styles.logo}
        />
      )}
      <ActivityIndicator
        style={{ marginVertical: MARGIN_VERTICAL }}
        size='small'
        color={props.color || COLORS.BLUE}
      />
    </View>
  );
}

interface NoDataProps {
  color?: string;
  messages: Messages;
}

export function NoDataPage(props: NoDataProps) {
  return (
    <SafeAreaView style={styles.page}>
      <View style={{ ...styles.content, backgroundColor: 'transparent' }}>
        <Icon
          name='null'
          type='material-community'
          size={24}
          color={props.color || COLORS.GREY_700}
        />
        <Text style={{ color: props.color || COLORS.GREY_700 }}>{props.messages[MESSAGES.NO_DATA]}</Text>
      </View>
    </SafeAreaView>
  );
}

interface OverlayLoadingPageProps {
  isLoading: boolean;
  withImage?: boolean;
}

export function OverlayLoadingPage(props: OverlayLoadingPageProps) {
  return (
    <Overlay
      isVisible={props.isLoading}
      fullScreen={true}
      overlayStyle={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: BACKGROUND_COLORS.MAIN,
      }}>
      <Loading withImage={props.withImage} />
    </Overlay>
  );
}

interface LoadingPageProps {
  style?: any;
  withImage?: boolean;
  color?: string;
}

export default function LoadingPage(props: LoadingPageProps) {
  return (
    <SafeAreaView style={{ ...styles.page, ...props.style }}>
      <Loading
        withImage={props.withImage}
        color={props.color}
      />
    </SafeAreaView>
  );
}

/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React, { useState } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { Icon, Input, Overlay } from '@rneui/themed';
import DateTimePicker from '@react-native-community/datetimepicker';
import moment from 'moment';
import { Messages } from '#data/extras';
import BaseButton from '#components/buttons';
import COLORS from '#constants/colors';
import { MESSAGES } from '#constants/index';
import { BORDER_RADIUS, FONT_LABEL_SIZE, FONT_SIZE, MARGIN_HORIZONTAL, MARGIN_VERTICAL } from '#constants/styles';
import appUtil from '#utils/app.util';
import { isNil } from 'lodash';

interface DatePickerProps {
  messages: Messages;
  label: string;
  placeholder?: string;
  mode?: 'date' | 'time';
  value?: Date;
  defaultValue?: Date;
  onChangeValue(event: any, date?: Date): void | Promise<void>;
  showDatePicker: boolean;
  setShowDatePicker(show?: boolean): void | Promise<void>;
  minDateTime?: Date;
  maxDateTime?: Date;
  editable?: boolean;
  renderErrorMessage?: boolean;
}

function DatePicker(props: DatePickerProps) {
  const [errorMessage, setErrorMessage] = useState('');

  const formatValue = (value?: Date) => {
    if (value) {
      if (props.mode === 'date') {
        return moment(value).format('DD/MM/YYYY');
      } else {
        return moment(value).format('HH:mm');
      }
    }
  };

  const onChangeValueWithValidation = (event: any, date?: Date) => {
    if (date) {
      if (props.minDateTime && date < props.minDateTime) {
        setErrorMessage(appUtil.formatSentence(`${props.messages[MESSAGES.ERROR_TIME_MIN]} ${formatValue(props.minDateTime)}`));
      } else if (props.maxDateTime && date > props.maxDateTime) {
        setErrorMessage(appUtil.formatSentence(`${props.messages[MESSAGES.ERROR_TIME_MAX]} ${formatValue(props.maxDateTime)}`));
      } else {
        setErrorMessage('');
      }
    } else {
      setErrorMessage('');
    }
    props.onChangeValue(event, date);
  };

  return (
    <>
      <Input
        label={props.label}
        placeholder={props.placeholder}
        editable={false}
        value={formatValue(props.value)}
        leftIcon={{
          name: 'calendar-range',
          type: 'material-community',
        }}
        rightIcon={
          props.editable ? (
            props.value && !props.defaultValue ? (
              <TouchableOpacity onPress={() => onChangeValueWithValidation(undefined, undefined)} disabled={!props.editable}>
                <Icon name='cancel' type='material-community' />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={props.editable ? () => props.setShowDatePicker(true) : undefined} disabled={!props.editable}>
                <Icon name='square-edit-outline' type='material-community' />
              </TouchableOpacity>
            )
          ) : undefined
        }
        inputStyle={{ fontSize: FONT_SIZE }}
        labelStyle={{ fontSize: FONT_LABEL_SIZE }}
        errorMessage={errorMessage}
        renderErrorMessage={isNil(props.renderErrorMessage) ? true : props.renderErrorMessage}
      />
      {Platform.OS === 'ios' ? (
        <Overlay
          isVisible={props.showDatePicker}
          overlayStyle={{
            position: 'absolute',
            bottom: MARGIN_VERTICAL,
            flex: 1,
            flexDirection: 'column',
            minWidth: '100%',
            padding: 0,
            borderRadius: 0,
            backgroundColor: 'transparent',
          }}
          onBackdropPress={() => props.setShowDatePicker(false)}>
          <DateTimePicker
            style={{
              backfaceVisibility: 'hidden',
              backgroundColor: 'white',
              borderRadius: BORDER_RADIUS,
              overflow: 'hidden',
              marginBottom: MARGIN_VERTICAL,
              marginHorizontal: MARGIN_HORIZONTAL,
            }}
            textColor={COLORS.BLACK}
            onChange={onChangeValueWithValidation}
            display='spinner'
            is24Hour={true}
            mode={props.mode || 'date'}
            value={props.value || props.defaultValue || new Date()}
            disabled={!props.editable}
          />
          <BaseButton
            type='solid'
            color={COLORS.BLUE}
            backgroundColor='white'
            title={props.messages[MESSAGES.DONE]}
            onPress={() => {
              onChangeValueWithValidation(undefined, props.value || props.defaultValue || new Date());
              props.setShowDatePicker(false);
            }}
            titleStyle={{ fontSize: 20 }}
            containerStyle={{ marginBottom: MARGIN_VERTICAL, marginHorizontal: MARGIN_HORIZONTAL }}
          />
        </Overlay>
      ) : props.showDatePicker ? (
        <DateTimePicker
          onChange={(event, date) => {
            props.setShowDatePicker(false);
            onChangeValueWithValidation(event, date);
          }}
          display='default'
          is24Hour={true}
          mode={props.mode || 'date'}
          value={props.value || props.defaultValue || new Date()}
          disabled={!props.editable}
        />
      ) : null}
    </>
  );
}

DatePicker.defaultProps = { editable: true };

export default DatePicker;

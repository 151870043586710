export interface Messages {
  [index: string]: string;
}

export const DEFAULT_MESSAGES: Messages | undefined = undefined;

export interface ActionPlan {
  [index: string]: {
    [index: string]: string | undefined | null;
  };
}

export const DEFAULT_ACTION_PLAN: ActionPlan | undefined = undefined;

export default {};

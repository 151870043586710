/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';
import COLORS from '#constants/colors';
import DiaryStyles from './diary.page';
import { BORDER_RADIUS, FONT_SIZE } from '#constants/styles';

export default StyleSheet.create({
  ...DiaryStyles,
  horizontalInputs: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: 8,
  },
  image: {
    borderRadius: BORDER_RADIUS,
    flex: 1,
    height: 400,
    width: '100%',
  },
  ingredientOverlay: {
    alignItems: 'stretch',
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '80%',
    maxWidth: '90%',
    minWidth: '90%',
    overflow: 'hidden',
  },
  unit: {
    alignItems: 'center',
    backgroundColor: COLORS.CHROME_GREY,
    borderRadius: BORDER_RADIUS,
    color: COLORS.BLACK,
    flexGrow: 1,
    flexShrink: 0,
    flexWrap: 'nowrap',
    fontSize: FONT_SIZE,
    justifyContent: 'center',
    overflow: 'hidden',
    paddingHorizontal: 24,
    paddingVertical: 8,
    textAlign: 'center',
  },
});

/* eslint-disable react-native/no-inline-styles */
import React, { useState } from 'react';
import styles from '#pages/styles/authorization.page';
import { TouchableOpacity, View, SafeAreaView, Alert } from 'react-native';
import { get, omit } from 'lodash';
import { Input, Icon, Text } from '@rneui/themed';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import authorizationService from '#services/authorization.service';
import { MESSAGES } from '#constants/index';
import COLORS from '#constants/colors';
import BaseButton from '#components/buttons';
import { User } from '#data/user';
import { Messages } from '#data/extras';
import { NavigationProp } from '@react-navigation/core';
import LoadingPage from './loading.page';
import appUtil from '#utils/app.util';
import { FONT_LABEL_SIZE, FONT_SIZE, MARGIN_HORIZONTAL, MARGIN_VERTICAL } from '#constants/styles';
import notificationService from '#services/notification.service';
import { Settings } from '#data/settings';

interface AuthorizationPageProps {
  navigation: NavigationProp<any>;
  user?: User;
  onChangeUser(user?: User): Promise<User | undefined>;
  messages: Messages;
  settings: Settings;
  onChangeSettings(settings?: Settings, reload?: boolean, callback?: () => void): void;
}

function AuthorizationPage(props: AuthorizationPageProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [secureTextEntry, setSecureTextEntry] = useState(true);

  const onLogin = async () => {
    setIsLoading(true);
    let user;
    try {
      user = await authorizationService.login(username, password);
    } catch (e) {
      Alert.alert(appUtil.formatSentence(props.messages[MESSAGES.ERROR]), appUtil.formatSentence(props.messages[MESSAGES.LOGIN_FAILED]));
    }
    if (user) {
      // this will unmount this page
      await props
        .onChangeUser({
          id: get(user, 'account.id'),
          token: get(user, 'token'),
          profile: omit(get(user, 'account') || {}, 'id'),
        })
        .then((newUser) => {
          if (newUser) {
            Promise.all([
              notificationService
                .registerDiaryReminder(props.messages, newUser, props.settings)
                .then((newerSettings) => props.onChangeSettings(newerSettings, false)),
              notificationService.registerAllMedicationReminders(props.messages, newUser),
            ]).finally(() => {
              setIsLoading(false);
              props.navigation.navigate('Home');
            });
          } else {
            setIsLoading(false);
          }
        });
    } else {
      setIsLoading(false);
    }
  };

  if (isLoading) return <LoadingPage withImage />;

  return (
    <SafeAreaView style={styles.page}>
      <KeyboardAwareScrollView style={styles.scrollView}>
        <View style={styles.content}>
          <Input
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.LOGIN_EMAIL_PLACEHOLDER])}
            label='Email'
            value={username}
            onChangeText={setUsername}
            leftIcon={{
              type: 'material-community',
              name: 'email',
            }}
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
            onSubmitEditing={onLogin}
            keyboardType='email-address'
          />
          <Input
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.LOGIN_PASSWORD_PLACEHOLDER])}
            label={appUtil.formatSentence(props.messages[MESSAGES.PASSWORD])}
            secureTextEntry={secureTextEntry}
            value={password}
            onChangeText={setPassword}
            leftIcon={{
              type: 'material-community',
              name: 'lock',
            }}
            rightIcon={
              <TouchableOpacity onPress={() => setSecureTextEntry(!secureTextEntry)}>
                <Icon
                  name={secureTextEntry ? 'eye-off' : 'eye'}
                  type='material-community'
                />
              </TouchableOpacity>
            }
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
            onSubmitEditing={onLogin}
          />
        </View>
        <View style={{ ...styles.gridRow, marginHorizontal: MARGIN_HORIZONTAL, marginBottom: MARGIN_VERTICAL }}>
          <BaseButton
            type='clear'
            backgroundColor='white'
            color={COLORS.BLUE}
            containerStyle={{ marginRight: MARGIN_HORIZONTAL }}
            onPress={() => props.navigation.navigate('Register')}
            title={props.messages[MESSAGES.REGISTER]}
          />
          <BaseButton
            type='solid'
            backgroundColor={COLORS.TRANSPARENT_BLUE}
            color={COLORS.BLUE}
            onPress={onLogin}
            title={props.messages[MESSAGES.LOGIN]}
          />
        </View>
        <Text
          style={styles.hyperlink}
          onPress={() => props.navigation.navigate('ForgetPassword')}>
          {`${appUtil.formatSentence(props.messages[MESSAGES.FORGOT_PASSWORD])}?`}
        </Text>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

export default AuthorizationPage;

/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';
import COLORS from '#constants/colors';
import { BORDER_RADIUS, FONT_SIZE, GENERAL_STYLES, MARGIN_HORIZONTAL, MARGIN_VERTICAL, PADDING } from '#constants/styles';

export default StyleSheet.create({
  ...GENERAL_STYLES,
  button: { marginHorizontal: 16 },
  checkBox: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: BORDER_RADIUS,
    borderWidth: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: MARGIN_VERTICAL,
    marginLeft: MARGIN_HORIZONTAL,
    marginRight: MARGIN_HORIZONTAL,
    marginTop: 0,
    minHeight: 80,
    padding: PADDING,
  },
  checkBoxText: {
    color: 'black',
    flex: 1,
    flexWrap: 'wrap',
    fontSize: FONT_SIZE,
    fontWeight: 'normal',
    margin: 0,
    padding: 0,
  },
  listItem: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: BORDER_RADIUS,
    color: COLORS.BLUE,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: MARGIN_VERTICAL,
    marginHorizontal: MARGIN_HORIZONTAL,
    minHeight: 80,
    padding: PADDING,
  },
  listItemText: {
    color: COLORS.BLUE,
    flex: 1,
    fontSize: FONT_SIZE,
    paddingHorizontal: 24,
  },
});

import axios from 'axios';
import { get, omitBy, isNil } from 'lodash';
import Constants from 'expo-constants';
import moment from 'moment';

const api_url = get(Constants, 'manifest.extra.apiUrl');

const saveDiary = async (userId: string | undefined, token: string | undefined, data: any) => {
  if (!userId || !token) {
    throw new Error('Please login');
  }
  const url = `${api_url}/diaries/${userId}`;
  return axios
    .post(
      url,
      omitBy(data, (value) => isNil(value) || value === ''),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => get(response, 'data'));
};

const getDiaries = async (
  userId: string | undefined,
  token: string | undefined,
  page: number,
  size = 3,
  fromEpoch: any = undefined,
  toEpoch: any = undefined,
) => {
  if (!userId || !token) {
    throw new Error('Please login');
  }
  const epochQuery = fromEpoch && toEpoch ? `&fromEpoch=${moment(fromEpoch).valueOf()}&toEpoch=${moment(toEpoch).valueOf()}` : '';
  const url = `${api_url}/diaries/${userId}?page=${page}&size=${size}${epochQuery}`;
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => get(response, 'data'));
};

export default { saveDiary, getDiaries };

export interface Settings {
  locale: string;
  diaryReminder: {
    enabled: boolean;
    hour: number;
    minute: number;
    notiId: string | undefined;
  };
}

export const DEFAULT_SETTINGS: Settings = {
  locale: 'vi-vn',
  diaryReminder: {
    enabled: true,
    hour: 20,
    minute: 0,
    notiId: undefined,
  },
};

export default {};

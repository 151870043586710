import axios from 'axios';
import { get, reduce } from 'lodash';
import Constants from 'expo-constants';

const API_URL = get(Constants, 'manifest.extra.apiUrl');
const URL = `${API_URL}/user-messages/list`;
const SIZE = 100;

const getMessage = async (paths: string[], locale: string) => {
  console.log(URL);
  let result = {};
  let page = 0;
  let totalPage = 1;
  while (page < totalPage) {
    const response = await axios.post(
      URL,
      { paths },
      {
        headers: { 'Accept-Language': locale },
        params: {
          page: page,
          size: SIZE,
        },
      },
    );
    const data = get(response, 'data.content');
    if (data.length === 0) {
      break;
    }
    result = reduce(
      data,
      (total: any, value: any) => ({
        ...total,
        [value.path]: value.value,
      }),
      result,
    );
    page += 1;
    totalPage = get(response, 'data.totalPages');
  }
  return result;
};

export default { getMessage };

import COLORS from './colors';

export const DIARY_OPTION_COLOR_MAPPING = {
  hasDaytimeSymptomsMoreThan3m: COLORS.BLUE,
  hasNightWaking: COLORS.YELLOW,
  hasRelieverUsedPast24h: COLORS.RED,
  hasActivityLimitation: COLORS.BLACK,
  hasPreventerUsedPast24h: COLORS.ORANGE,
  hasPreventerUsedPast24hInMorning: COLORS.ORANGE,
  hasPreventerUsedPast24hInNight: COLORS.ORANGE,
  isNormal: COLORS.GREEN,
};

/* eslint-disable react-native/no-color-literals */
import { FONT_TITLE_SIZE, GENERAL_STYLES, MARGIN_HORIZONTAL, MARGIN_VERTICAL } from '#constants/styles';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  ...GENERAL_STYLES,
  card: {
    ...GENERAL_STYLES.card,
    flexBasis: '100%',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: MARGIN_HORIZONTAL,
    marginTop: 0,
    minHeight: 0,
  },
  cardDescription: {
    ...GENERAL_STYLES.cardDescription,
    color: 'black',
    textAlign: 'center',
  },
  cardHeader: {
    ...GENERAL_STYLES.cardHeader,
    flexDirection: 'column',
  },
  cardTitle: {
    ...GENERAL_STYLES.cardTitle,
    fontSize: FONT_TITLE_SIZE,
    textAlign: 'center',
  },
  column: {
    paddingRight: MARGIN_HORIZONTAL,
  },
  gridRow: {
    ...GENERAL_STYLES.gridRow,
    marginBottom: MARGIN_VERTICAL,
    marginLeft: MARGIN_HORIZONTAL,
  },
  row: {
    marginBottom: MARGIN_VERTICAL,
  },
  rowHeight: {
    height: 180,
  },
});

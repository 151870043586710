import { CheckBox } from '@rneui/themed';
import appUtil from '#utils/app.util';
import styles from './styles/checkbox.input';
import React from 'react';

function Checkbox({ title, checked, onPress, color, containerStyle, disabled }: any) {
  return (
    <CheckBox
      title={appUtil.formatSentence(title)}
      checked={checked}
      onPress={onPress}
      iconType='material-community'
      checkedIcon='checkbox-marked-circle'
      uncheckedIcon='checkbox-blank-circle-outline'
      textStyle={styles.checkBoxText}
      containerStyle={{ ...styles.checkBox, ...containerStyle }}
      disabled={disabled}
      checkedColor={color}
    />
  );
}

export default Checkbox;

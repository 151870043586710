/* eslint-disable max-len */
/* eslint-disable react-native/no-inline-styles */
import { EMERGENCY_PERSISTENCE_NAV_STATE_KEY, MESSAGES } from '#constants/index';
import BaseButton from '#components/buttons';
import COLORS from '#constants/colors';
import { stackNavigationScreenOptions } from '#constants/options';
import { FONT_SIZE, FONT_TITLE_SIZE, MARGIN_HORIZONTAL } from '#constants/styles';
import { ActionPlan, Messages } from '#data/extras';
import LoadingPage from '#pages/loading.page';
import styles from '#pages/styles/emergency.page';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NavigationProp } from '@react-navigation/core';
import { useFocusEffect } from '@react-navigation/native';
import { createNativeStackNavigator as createStackNavigator } from '@react-navigation/native-stack';
import { Icon, Text } from '@rneui/themed';
import locationService from '#services/location.service';
import storageService, { Delay } from '#services/storage.service';
import appUtil from '#utils/app.util';
import { Asset } from 'expo-asset';
import { ResizeMode, Video } from 'expo-av';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import { get, map, toPairs } from 'lodash';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Alert, Dimensions, Platform, SafeAreaView, ScrollView, View } from 'react-native';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';

const files_url = get(Constants, 'manifest.extra.filesUrl');
const mdi_guide_url = `${files_url}/emergency-mdi-guide.mp4`;
const emergency_nebulizer_with_mask_guide_url = `${files_url}/emergency-nebulizer-with-mask-guide.mp4`;

const Stack = createStackNavigator();

interface ActionProps {
  name: string;
  navigation: NavigationProp<any>;
  data: any;
  messages: Messages;
  route: any;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

function Action(props: ActionProps) {
  const [delay, setDelay]: [number | undefined, any] = useState(undefined);
  const [completeCountDown, setCompleteCountDown]: [boolean, any] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [delayNotiId, setDelayNotiId]: [string | undefined, any] = useState();
  const videoMDI = React.useRef(null);
  const videoNebulizer = React.useRef(null);

  const onYes = () => get(props.data, 'yes') && props.navigation.navigate(get(props.data, 'yes'));

  const onNo = () => get(props.data, 'no') && props.navigation.navigate(get(props.data, 'no'));

  const updateDelayFromStorage = async (savedDelay: Delay) => {
    const currentTime = Date.now();
    if (currentTime - savedDelay.startTime >= savedDelay.interval * 1000) {
      await storageService.deleteDelay();
      setDelay(undefined); // passed interval time
      setDelayNotiId(undefined);
    } else {
      setDelay(savedDelay.interval - Math.ceil((currentTime - savedDelay.startTime) / 1000));
      setDelayNotiId(savedDelay.notiId);
    }
  };

  const fireDelay = async (currentDelay: number) => {
    const savedDelay = await storageService.getDelay();
    if (savedDelay) {
      await updateDelayFromStorage(savedDelay);
      return;
    }
    let notiId;
    const startTime = moment().local(true);
    const nextTrigger = startTime.clone().add(currentDelay, 'seconds');
    if (Platform.OS != 'web') {
      notiId = await Notifications.scheduleNotificationAsync({
        content: {
          sound: true,
          vibrate: [10],
          autoDismiss: true,
          title: appUtil.formatSentence(
            `${props.messages[MESSAGES.EMERGENCY_DONE_WAITING]} ${currentDelay / 60} ${props.messages[MESSAGES.MINUTES]}`,
          ),
          body: appUtil.formatSentence(props.messages[MESSAGES.EMERGENCY_DONE_WAITING_DESCRIPTION]),
          data: {
            url: Linking.createURL(`/Main/Emergency/${props.name}`, { queryParams: { delay: '0' } }),
            nextTrigger: nextTrigger.toISOString(),
          },
        },
        trigger: {
          seconds: currentDelay,
          repeats: false,
        },
      });
    }
    await storageService.storeDelay({ interval: currentDelay, startTime: startTime.valueOf(), notiId });
    setDelay(currentDelay);
    setDelayNotiId(notiId);
  };

  const deleteDelay = async () => {
    if (delayNotiId) {
      await Notifications.cancelScheduledNotificationAsync(delayNotiId);
    }
    await storageService.deleteDelay();
    setDelay(undefined);
    setDelayNotiId(undefined);
  };

  const onCancel = async () => {
    await deleteDelay();
    await AsyncStorage.removeItem(EMERGENCY_PERSISTENCE_NAV_STATE_KEY).catch((e) => console.error(e));
    props.navigation.reset({ index: 0, stale: true, routes: [{ name: 'EmerInit' }] });
  };

  const makeCallOrOpenMap = async () => {
    setLoading(true);
    try {
      if (get(props.data, 'route') === 'call-115') {
        await Linking.openURL('tel:115');
      } else {
        const query = `${props.messages[MESSAGES.EMERGENCY_HOSPITAL]}`;
        let webURL = `https://www.google.com/maps/search/${query}`;
        if (Platform.OS == 'web') {
          window.open(encodeURI(webURL), '_blank');
          setLoading(false);
          return;
        }
        const isGranted = await locationService.getOrRequestLocationPermission();
        if (isGranted) {
          const location = await locationService.getCurrentPosition();
          const latlon = `${location.coords.latitude},${location.coords.longitude}`;
          webURL = encodeURI(`${webURL}/@${latlon},16z`);
          let url = Platform.select({
            ios: webURL,
            android: `geo:${latlon}?q=${query}`,
            web: webURL,
          });
          if (url) {
            const supported = await Linking.canOpenURL(url);
            if (!supported) {
              url = Platform.select({
                ios: `maps:${latlon}?q=${query}`,
                android: `geo:${latlon}?q=${query}`,
                web: webURL,
              });
            }
            if (url) {
              await Linking.openURL(url);
            }
          }
        } else {
          alert(appUtil.formatSentence(props.messages[MESSAGES.PLEASE_ENABLE_LOCATION_SERVICE]));
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const savedDelay = await storageService.getDelay();
      if (!savedDelay) {
        setDelay(undefined);
        return;
      }
      await updateDelayFromStorage(savedDelay);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (completeCountDown) {
      deleteDelay().then(() => setCompleteCountDown(false));
    }
  }, [completeCountDown]);

  useEffect(() => {
    if (showCancelDialog) {
      if (Platform.OS != 'web') {
        Alert.alert(
          appUtil.formatSentence(props.messages[MESSAGES.ALERT]),
          appUtil.formatSentence(props.messages[MESSAGES.DIARY_SAVE_CANCEL_DESCRIPTION]),
          [
            {
              text: appUtil.formatSentence(props.messages[MESSAGES.YES]),
              onPress: onCancel,
              style: 'destructive',
            },
            {
              text: appUtil.formatSentence(props.messages[MESSAGES.NO]),
              onPress: () => setShowCancelDialog(false),
              style: 'cancel',
            },
          ],
        );
      } else {
        const res = window.confirm(appUtil.formatSentence(props.messages[MESSAGES.DIARY_SAVE_CANCEL_DESCRIPTION]));
        if (res) onCancel();
        else setShowCancelDialog(false);
      }
    }
  }, [showCancelDialog]);

  useFocusEffect(
    useCallback(() => {
      setLoading(true);
      let currentDelay = get(props.route, 'params.delay');
      currentDelay = currentDelay && parseInt(currentDelay);
      if (currentDelay == 0) {
        deleteDelay().then(() => setLoading(false));
      } else {
        currentDelay = get(props.data, 'delay');
        if (currentDelay) {
          // currentDelay = 10; // TODO: test delay
          fireDelay(currentDelay).then(() => setLoading(false));
        } else {
          deleteDelay().then(() => setLoading(false));
        }
      }
    }, [props.route.params, props.data]),
  );

  if (loading) return <LoadingPage withImage />;

  return (
    <SafeAreaView style={styles.page}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        {delay && props.data?.delay ? (
          <>
            <View style={styles.card}>
              <Text style={styles.title}>
                {/* eslint-disable-next-line prettier/prettier */}
                {appUtil.formatUpper(`${props.messages[MESSAGES.EMERGENCY_PLEASE_CHECK_CONDITION_AFTER]}: \n${get(props.data, 'delay') / 60} ${props.messages[MESSAGES.MINUTES]}`)}
              </Text>
              <View style={styles.countDownTimer}>
                <CountdownCircleTimer
                  duration={props.data.delay}
                  initialRemainingTime={delay}
                  onComplete={() => setCompleteCountDown(true)}
                  isPlaying
                  colors='#fc7138'>
                  {({ remainingTime, color }) => {
                    const hours = Math.floor(remainingTime / 3600);
                    const minutes = Math.floor((remainingTime % 3600) / 60);
                    const seconds = remainingTime % 60;
                    return (
                      <Text style={{ ...styles.countDownText, color }}>
                        {hours}:{minutes}:{seconds}
                      </Text>
                    );
                  }}
                </CountdownCircleTimer>
              </View>
            </View>
            <View style={styles.buttons}>
              <BaseButton
                type='clear'
                backgroundColor='white'
                color={COLORS.ORANGE}
                borderColor={COLORS.ORANGE}
                onPress={deleteDelay}
                buttonStyle={{ borderRadius: 0, paddingVertical: 16 }}
                containerStyle={{ flex: 1, borderRadius: 0 }}
                titleStyle={{ fontSize: FONT_TITLE_SIZE }}
                title={appUtil.formatSentence(props.messages[MESSAGES.SKIP])}
              />
              <BaseButton
                type='solid'
                backgroundColor={COLORS.GREY_50}
                color={COLORS.BLUE}
                buttonStyle={{ borderRadius: 0 }}
                icon={{
                  name: 'close',
                  type: 'material-community',
                  size: FONT_SIZE,
                  color: COLORS.BLUE,
                }}
                containerStyle={{ flex: 1, borderRadius: 0 }}
                onPress={() => setShowCancelDialog(true)}
                title={appUtil.formatSentence(props.messages[MESSAGES.CANCEL])}
              />
              {props.name !== 'Q1' && (
                <BaseButton
                  type='solid'
                  backgroundColor='white'
                  color={COLORS.GRAY}
                  buttonStyle={{ borderRadius: 0 }}
                  icon={{
                    name: 'arrow-left',
                    type: 'material-community',
                    size: FONT_SIZE,
                    color: COLORS.GRAY,
                  }}
                  containerStyle={{ flex: 1, borderRadius: 0 }}
                  onPress={() => deleteDelay().then(() => props.navigation.goBack())}
                  title={appUtil.formatSentence(props.messages[MESSAGES.BACK])}
                />
              )}
            </View>
          </>
        ) : (
          <>
            <View style={styles.card}>
              <Text style={styles.title}>{appUtil.formatUpper(get(props.data, 'title'))}</Text>
              {get(props.data, 'bulletPoints') &&
                map(get(props.data, 'bulletPoints') || [], (value: string, index: number) => (
                  <View
                    style={styles.bulletPoint}
                    key={index}>
                    <Icon
                      name='checkbox-blank-circle'
                      type='material-community'
                      size={12}
                      color={COLORS.ORANGE}
                    />
                    <Text style={styles.text}>{appUtil.formatSentence(value)}</Text>
                  </View>
                ))}
            </View>
            {get(props.data, 'yes') || get(props.data, 'no') ? (
              <View style={styles.buttons}>
                {get(props.data, 'yes') && (
                  <BaseButton
                    type='solid'
                    backgroundColor={COLORS.TRANSPARENT_ORANGE}
                    color={COLORS.ORANGE}
                    onPress={onYes}
                    buttonStyle={{ borderRadius: 0, paddingVertical: 16 }}
                    containerStyle={{ flex: 1, borderRadius: 0 }}
                    titleStyle={{ fontSize: FONT_TITLE_SIZE }}
                    title={appUtil.formatSentence(get(props.data, 'yesLabel') || props.messages[MESSAGES.YES])}
                  />
                )}
                {get(props.data, 'no') && (
                  <BaseButton
                    type='clear'
                    backgroundColor='white'
                    color={COLORS.ORANGE}
                    borderColor={COLORS.ORANGE}
                    onPress={onNo}
                    buttonStyle={{ borderRadius: 0, paddingVertical: 16 }}
                    containerStyle={{ flex: 1, borderRadius: 0 }}
                    titleStyle={{ fontSize: FONT_TITLE_SIZE }}
                    title={appUtil.formatSentence(get(props.data, 'noLabel') || props.messages[MESSAGES.NO])}
                  />
                )}
                <BaseButton
                  type='solid'
                  backgroundColor={COLORS.GREY_50}
                  color={COLORS.BLUE}
                  buttonStyle={{ borderRadius: 0 }}
                  icon={{
                    name: 'close',
                    type: 'material-community',
                    size: FONT_SIZE,
                    color: COLORS.BLUE,
                  }}
                  containerStyle={{ flex: 1, borderRadius: 0 }}
                  onPress={() => setShowCancelDialog(true)}
                  title={appUtil.formatSentence(props.messages[MESSAGES.CANCEL])}
                />
                {props.name !== 'Q1' && (
                  <BaseButton
                    type='solid'
                    backgroundColor='white'
                    color={COLORS.GRAY}
                    buttonStyle={{ borderRadius: 0 }}
                    icon={{
                      name: 'arrow-left',
                      type: 'material-community',
                      size: FONT_SIZE,
                      color: COLORS.GRAY,
                    }}
                    containerStyle={{ flex: 1, borderRadius: 0 }}
                    onPress={() => props.navigation.goBack()}
                    title={appUtil.formatSentence(props.messages[MESSAGES.BACK])}
                  />
                )}
              </View>
            ) : ['call-115', 'nearby-hospitals'].includes(get(props.data, 'route')) ? (
              <View style={styles.buttons}>
                <BaseButton
                  type='clear'
                  backgroundColor={COLORS.TRANSPARENT_ORANGE}
                  color={COLORS.ORANGE}
                  onPress={makeCallOrOpenMap}
                  buttonStyle={{ borderRadius: 0 }}
                  containerStyle={{ flex: 1, borderRadius: 0 }}
                  titleStyle={{ fontSize: FONT_TITLE_SIZE }}
                  title={appUtil.formatSentence(
                    get(props.data, 'route') === 'call-115'
                      ? props.messages[MESSAGES.EMERGENCY_CALL]
                      : props.messages[MESSAGES.EMERGENCY_HOSPITAL_FIND],
                  )}
                />
                <BaseButton
                  type='clear'
                  backgroundColor='white'
                  color={COLORS.ORANGE}
                  onPress={onCancel}
                  buttonStyle={{ borderRadius: 0 }}
                  containerStyle={{ flex: 1, borderRadius: 0 }}
                  titleStyle={{ fontSize: FONT_TITLE_SIZE }}
                  title={props.messages[MESSAGES.DONE]}
                />
                {props.name !== 'Q1' && (
                  <BaseButton
                    type='solid'
                    backgroundColor={COLORS.GREY_50}
                    color={COLORS.GRAY}
                    buttonStyle={{ borderRadius: 0 }}
                    icon={{
                      name: 'arrow-left',
                      type: 'material-community',
                      size: FONT_SIZE,
                      color: COLORS.GRAY,
                    }}
                    containerStyle={{ flex: 1, borderRadius: 0 }}
                    onPress={() => props.navigation.goBack()}
                    title={appUtil.formatSentence(props.messages[MESSAGES.BACK])}
                  />
                )}
              </View>
            ) : (
              <View style={styles.buttons}>
                <BaseButton
                  type='clear'
                  backgroundColor={COLORS.TRANSPARENT_BLUE}
                  color={COLORS.BLUE}
                  onPress={onCancel}
                  buttonStyle={{ borderRadius: 0 }}
                  icon={{
                    name: 'check',
                    type: 'material-community',
                    size: FONT_SIZE,
                    color: COLORS.BLUE,
                  }}
                  titleStyle={{ fontSize: FONT_TITLE_SIZE }}
                  containerStyle={{ flex: 1, borderRadius: 0 }}
                  title={appUtil.formatSentence(props.messages[MESSAGES.DONE])}
                />
                {props.name !== 'Q1' && (
                  <BaseButton
                    type='solid'
                    backgroundColor={COLORS.GREY_50}
                    color={COLORS.GRAY}
                    buttonStyle={{ borderRadius: 0 }}
                    icon={{
                      name: 'arrow-left',
                      type: 'material-community',
                      size: FONT_SIZE,
                      color: COLORS.GRAY,
                    }}
                    containerStyle={{ flex: 1, borderRadius: 0 }}
                    onPress={() => props.navigation.goBack()}
                    title={appUtil.formatSentence(props.messages[MESSAGES.BACK])}
                  />
                )}
              </View>
            )}
            {['A1', 'A5'].includes(props.name) ? (
              <>
                <View
                  style={{
                    ...styles.videoContainer,
                    ...(Platform.OS == 'web' ? { minHeight: Math.ceil((Dimensions.get('window').width * 9) / 16) } : {}),
                  }}>
                  <Text style={{ ...styles.section, flexShrink: 1 }}>{appUtil.formatSentence(props.messages[MESSAGES.EMERGENCY_MDI_GUIDE])}</Text>
                  <View style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'stretch' }}>
                    <Video
                      ref={videoMDI}
                      style={{ ...styles.video, height: Platform.OS == 'web' ? '100%' : 250 }}
                      videoStyle={Platform.OS == 'web' ? styles.video : {}}
                      source={Asset.fromURI(mdi_guide_url)}
                      useNativeControls
                      isLooping={false}
                      isMuted={false}
                      shouldPlay={false}
                      resizeMode={ResizeMode.STRETCH}
                    />
                  </View>
                </View>
                <View
                  style={{
                    ...styles.videoContainer,
                    ...(Platform.OS == 'web' ? { minHeight: Math.ceil((Dimensions.get('window').width * 9) / 16) } : {}),
                  }}>
                  <Text style={{ ...styles.section, flexShrink: 1 }}>
                    {appUtil.formatSentence(props.messages[MESSAGES.EMERGENCY_NEBULIZER_WITH_MASK_GUIDE])}
                  </Text>
                  <View style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'stretch' }}>
                    <Video
                      ref={videoNebulizer}
                      style={{ ...styles.video, height: Platform.OS == 'web' ? '100%' : 250 }}
                      videoStyle={Platform.OS == 'web' ? styles.video : {}}
                      source={Asset.fromURI(emergency_nebulizer_with_mask_guide_url)}
                      useNativeControls
                      isLooping={false}
                      isMuted={false}
                      shouldPlay={false}
                      resizeMode={ResizeMode.STRETCH}
                    />
                  </View>
                </View>
              </>
            ) : null}
          </>
        )}
      </ScrollView>
    </SafeAreaView>
  );
}

function EmergencyInit(props: any) {
  return (
    <SafeAreaView style={{ ...styles.page, justifyContent: 'center', alignItems: 'stretch' }}>
      <View style={styles.card}>
        <Text style={{ ...styles.cardDescription, textAlign: 'center', fontSize: FONT_TITLE_SIZE, fontWeight: 'bold' }}>
          {appUtil.formatSentence(props.messages[MESSAGES.HOME_EMERGENCY_DESCRIPTION])}
        </Text>
      </View>
      <BaseButton
        type='solid'
        backgroundColor='white'
        color={COLORS.ORANGE}
        icon={{
          name: 'arrow-right',
          type: 'material-community',
          color: COLORS.ORANGE,
          size: FONT_TITLE_SIZE,
        }}
        iconPosition='right'
        title={props.messages[MESSAGES.BEGIN]}
        titleStyle={{ fontSize: FONT_TITLE_SIZE }}
        onPress={() => props.navigation.navigate('Q1')}
        containerStyle={{ marginHorizontal: MARGIN_HORIZONTAL, flexBasis: undefined }}
      />
    </SafeAreaView>
  );
}

interface EmergencyPageProps {
  actionPlan: ActionPlan;
  messages: Messages;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  navigation: any;
}

function EmergencyPage(props: EmergencyPageProps) {
  useEffect(() => {
    const unsubscribe = props.navigation.addListener('tabPress', (e: any) => {
      e.preventDefault();
      props.navigation.navigate('Emergency');
    });
    return unsubscribe;
  }, [props.navigation]);
  return (
    <Stack.Navigator
      screenOptions={{
        ...stackNavigationScreenOptions,
        headerShown: false,
      }}
      initialRouteName='EmerInit'>
      <Stack.Screen name='EmerInit'>
        {(screenProps: any) => (
          <EmergencyInit
            {...screenProps}
            {...props}
          />
        )}
      </Stack.Screen>
      {map(toPairs(props.actionPlan), ([key, value]: [string, any], index: number) => {
        return (
          <Stack.Screen
            name={key}
            key={index}>
            {(screenProps: any) => (
              <Action
                {...screenProps}
                name={key}
                messages={props.messages}
                data={value}
                setIsLoading={props.setIsLoading}
              />
            )}
          </Stack.Screen>
        );
      })}
    </Stack.Navigator>
  );
}

export default EmergencyPage;

/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import styles from './styles/day.calendar';
import { DayProps } from 'react-native-calendars/src/calendar/day';
import COLORS from '#constants/colors';
import appUtil from '#utils/app.util';
import { DateData } from 'react-native-calendars/src/types';
import { Loading } from '#pages/loading.page';
import moment from 'moment';
import { useFocusEffect } from '@react-navigation/core';

interface ListDataItem {
  color: string;
  backgroundColor: string;
  title: string;
}

interface DayCalendar extends DayProps {
  selectedColor: string;
  selectedBackgroundColor: string;
  getListDataOfDay?: (day: number, month: number, year: number) => Promise<ListDataItem[]>;
}

function Day(props: DayCalendar & { date?: DateData }) {
  const [listData, setListData]: [ListDataItem[], any] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const mountRef = useRef(true); // https://stackoverflow.com/a/60693711/11037553

  const isToday = () => {
    const now = moment().local(true);
    return props.date?.day === now.date() && props.date?.month === now.month() + 1 && props.date?.year === now.year();
  };

  const getTitleColor = () => {
    if (props.state === 'disabled') {
      return {
        color: COLORS.TRANSPARENT_BLACK,
        backgroundColor: 'transparent',
      };
    }
    if (isToday()) {
      return {
        color: props.selectedColor,
        backgroundColor: props.selectedBackgroundColor,
      };
    }
    return {
      color: 'black',
      backgroundColor: 'transparent',
    };
  };

  const init = useCallback(() => {
    if (props.getListDataOfDay && props.date) {
      if (!mountRef.current) return;
      setIsLoading(true);
      props
        .getListDataOfDay(props.date.day, props.date.month, props.date.year)
        .then((d) => {
          if (!mountRef.current) return;
          setListData(d);
          setIsLoading(false);
        })
        .catch((e) => {
          if (!mountRef.current) return;
          setIsLoading(false);
          throw e;
        });
    }
  }, [props.date?.day, props.date?.month, props.date?.year]);

  useFocusEffect(init);

  useEffect(() => {
    return () => {
      mountRef.current = false;
    };
  }, []);

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => props.onPress && props.onPress(props.date)}>
      <View style={styles.titleContainer}>
        <Text
          style={{
            ...styles.title,
            ...getTitleColor(),
          }}>
          {props.date && props.date.day}
        </Text>
      </View>
      {isLoading ? (
        <Loading color={COLORS.RED} />
      ) : (
        <ScrollView>
          {listData.map((value: ListDataItem, index: number) => (
            <View
              style={styles.textContainer}
              key={index}>
              <Text
                numberOfLines={1}
                style={{
                  ...styles.item,
                  backgroundColor: value.backgroundColor,
                  color: value.color,
                }}>
                {appUtil.formatSentence(value.title)}
              </Text>
            </View>
          ))}
        </ScrollView>
      )}
    </TouchableOpacity>
  );
}

export default Day;

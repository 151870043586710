/* eslint-disable react-native/no-color-literals */
import COLORS from '#constants/colors';
import { FONT_SIZE, PADDING } from '#constants/styles';

export default {
  button: {
    backgroundColor: 'transparent',
    borderRadius: 1000,
    borderWidth: 0,
    margin: 0,
    marginVertical: 0,
    padding: PADDING,
  },
  buttonContainer: {
    alignItems: 'stretch',
    borderRadius: 1000,
    borderWidth: 0,
    justifyContent: 'center',
    marginHorizontal: 0,
    padding: 0,
    paddingHorizontal: 0,
    flexShrink: 1,
    flexGrow: 0,
    flexBasis: '100%',
  },
  buttonTitle: {
    color: COLORS.BLUE,
    fontSize: FONT_SIZE,
    fontWeight: 'normal',
  },
};

import 'expo/build/Expo.fx';
import { Platform } from 'react-native';
import { registerRootComponent } from 'expo';
import { activateKeepAwakeAsync } from 'expo-keep-awake';
import { createRoot } from 'react-dom/client';
import App from './App'; /* CHANGE THE PATH BASED ON WHERE YOURS IS LOCATED */

import Ionicons from '@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf';
import MaterialCommunityIcons from '@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import MaterialIcons from '@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf';

if (__DEV__) {
  activateKeepAwakeAsync();
}

if (Platform.OS === 'web') {
  const IoniconsStyles = `@font-face {
    src: url(${Ionicons});
    font-family: Ionicons;
  }`;

  const MaterialCommunityIconsStyles = `@font-face {
    src: url(${MaterialCommunityIcons});
    font-family: MaterialCommunityIcons;
  }`;

  const MaterialIconsStyles = `@font-face {
    src: url(${MaterialIcons});
    font-family: MaterialIcons;
  }`;

  const style = document.createElement('style');
  style.type = 'text/css';

  if (style.styleSheet) {
    style.styleSheet.cssText = IoniconsStyles;
  } else {
    style.appendChild(document.createTextNode(IoniconsStyles));
  }

  style.appendChild(document.createTextNode(MaterialCommunityIconsStyles));
  style.appendChild(document.createTextNode(MaterialIconsStyles));

  document.head.appendChild(style);

  if (module.hot) {
    module.hot.accept();
  }
  const root = createRoot(document.getElementById('root') ?? document.getElementById('main'));
  // eslint-disable-next-line react/react-in-jsx-scope
  root.render(<App />);
} else {
  registerRootComponent(App);
}

import { toUpper } from 'lodash';
import moment from 'moment';

const formatTitleAQI = (text: string) => {
  const formattedText = text.replace('_', '').replace(' ', '');
  return `${toUpper(formattedText)} AQI: `;
};

const formatTitle = (text: string) => `${toUpper(text)}: `;

const formatSentence = (text: string) => text && `${toUpper(text[0])}${text.slice(1)}`;

const formatUpper = (text: string) => text && toUpper(text);

const parseBase64 = (text: string) => atob(text);

const getDatesBetween = (startTime: number, endTime: number) => {
  const dates = [];
  const currDate = moment(startTime).startOf('day');
  const endDate = moment(endTime).startOf('day');
  while (currDate.add(1, 'days').diff(endDate) < 0) {
    dates.push(currDate.clone().valueOf());
  }
  return dates;
};

const invertColor = (hex: string) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  const r: any = parseInt(hex.slice(0, 2), 16),
    g: any = parseInt(hex.slice(2, 4), 16),
    b: any = parseInt(hex.slice(4, 6), 16);
  // http://stackoverflow.com/a/3943023/112731
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
};

export default {
  formatTitle,
  formatTitleAQI,
  formatSentence,
  formatUpper,
  getDatesBetween,
  invertColor,
  parseBase64,
};

import { LOCAL_STORAGE_KEYS, LOCAL_STORAGE_UPDATE_SECONDS_INTERVALS } from '#constants/index';
import { ActionPlan, Messages } from '#data/extras';
import { DEFAULT_SETTINGS, Settings } from '#data/settings';
import { User } from '#data/user';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import * as SecureStore from 'expo-secure-store';
import { defaultsDeep, get } from 'lodash';
import moment from 'moment';

const env = get(Constants, 'manifest.extra.env');

/* -------------------------- terms and permissions ------------------------- */

const storeTermsAndConditions = async (termsAndConditions: string | undefined, version: number, isAccepted: boolean, locale: string) => {
  if (termsAndConditions && version >= 0) {
    await AsyncStorage.setItem(LOCAL_STORAGE_KEYS.TERMS_AND_CONDITIONS, termsAndConditions);
    await AsyncStorage.setItem(
      LOCAL_STORAGE_KEYS.TERMS_AND_CONDITIONS_AGREEMENT,
      JSON.stringify({
        locale,
        version,
        isAccepted,
      }),
    );
  }
};

const getTermsAndConditions = async () => {
  try {
    const tac = await AsyncStorage.getItem(LOCAL_STORAGE_KEYS.TERMS_AND_CONDITIONS);
    if (tac) {
      return tac;
    }
  } catch (e) {
    console.error(e);
  }
  return undefined;
};

const deleteTermsAndConditions = async () => {
  try {
    await AsyncStorage.removeItem(LOCAL_STORAGE_KEYS.TERMS_AND_CONDITIONS);
  } catch (e) {
    console.error(e);
  }
};

const getTermsAndConditionsAgreement = async () => {
  try {
    const tacAgreement = await AsyncStorage.getItem(LOCAL_STORAGE_KEYS.TERMS_AND_CONDITIONS_AGREEMENT);
    if (tacAgreement) {
      return JSON.parse(tacAgreement);
    }
  } catch (e) {
    console.error(e);
  }
  return undefined;
};
const deleteTermsAndConditionsAgreement = async () => {
  try {
    await AsyncStorage.removeItem(LOCAL_STORAGE_KEYS.TERMS_AND_CONDITIONS_AGREEMENT);
  } catch (e) {
    console.error(e);
  }
};

/* ---------------------------------- user ---------------------------------- */

const storeUser = async (user: User) => {
  if (user) {
    await SecureStore.setItemAsync(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
  }
};

const deleteUser = async () => SecureStore.deleteItemAsync(LOCAL_STORAGE_KEYS.USER).catch((e) => console.error(e));

const getUser = async (): Promise<User | undefined> => {
  try {
    const savedUser = await SecureStore.getItemAsync(LOCAL_STORAGE_KEYS.USER);
    if (savedUser) {
      const user: User = JSON.parse(savedUser);
      if (user.profile && user.profile.dob) {
        user.profile.dob = moment(user.profile.dob).toDate();
      }
      if (!user.token || !user.id) {
        await deleteUser();
        return undefined;
      }
      return user;
    }
  } catch (e) {
    console.error(e);
  }
  return undefined;
};

/* -------------------------------- settings -------------------------------- */

const storeSettings = async (settings?: Settings) => {
  if (settings) {
    await AsyncStorage.setItem(LOCAL_STORAGE_KEYS.SETTINGS, JSON.stringify(settings));
  }
};

const getSettings = async () => {
  const value = await AsyncStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS);
  if (value) {
    let settings: Settings = JSON.parse(value);
    settings = defaultsDeep(settings, DEFAULT_SETTINGS); // assign missing default saved settings
    return settings;
  }
  return undefined;
};

const deleteSettings = async () => {
  try {
    await AsyncStorage.removeItem(LOCAL_STORAGE_KEYS.SETTINGS);
  } catch (e) {
    console.error(e);
  }
};

/* -------------------------------- messages -------------------------------- */

const storeMessages = async (messages: Messages, locale: string) => {
  await AsyncStorage.setItem(LOCAL_STORAGE_KEYS.MESSAGES, JSON.stringify(messages));
  await AsyncStorage.setItem(LOCAL_STORAGE_KEYS.MESSAGES_LOCALE, locale);
  await AsyncStorage.setItem(LOCAL_STORAGE_KEYS.MESSAGES_UPDATED_AT, moment().local(true).format());
};

const getMessages = async (locale: string) => {
  const savedLocale = await AsyncStorage.getItem(LOCAL_STORAGE_KEYS.MESSAGES_LOCALE);
  if (locale != savedLocale) return undefined;
  const value = await AsyncStorage.getItem(LOCAL_STORAGE_KEYS.MESSAGES);
  if (!value) return undefined;
  const updatedAtValue = await AsyncStorage.getItem(LOCAL_STORAGE_KEYS.MESSAGES_UPDATED_AT);
  const updatedAt = updatedAtValue && moment(updatedAtValue).local(true);
  if (!updatedAt) return undefined;
  if (moment().local(true).diff(updatedAt, 'seconds', true) >= LOCAL_STORAGE_UPDATE_SECONDS_INTERVALS.MESSAGES_UPDATED_AT) return undefined;
  const messages: Messages = JSON.parse(value);
  return messages;
};

/* ------------------------------ action plans ------------------------------ */

const storeActionPlan = async (actionPlan: ActionPlan, locale: string) => {
  await AsyncStorage.setItem(LOCAL_STORAGE_KEYS.ACTION_PLAN, JSON.stringify(actionPlan));
  await AsyncStorage.setItem(LOCAL_STORAGE_KEYS.ACTION_PLAN_LOCALE, locale);
  await AsyncStorage.setItem(LOCAL_STORAGE_KEYS.ACTION_PLAN_UPDATES_AT, moment().local(true).format());
};

const getActionPlan = async (locale: string) => {
  const savedLocale = await AsyncStorage.getItem(LOCAL_STORAGE_KEYS.ACTION_PLAN_LOCALE);
  if (locale != savedLocale) return undefined;
  const value = await AsyncStorage.getItem(LOCAL_STORAGE_KEYS.ACTION_PLAN);
  if (!value) return undefined;
  const updatedAtValue = await AsyncStorage.getItem(LOCAL_STORAGE_KEYS.ACTION_PLAN_UPDATES_AT);
  const updatedAt = updatedAtValue && moment(updatedAtValue).local(true);
  if (!updatedAt) return undefined;
  if (moment().local(true).diff(updatedAt, 'seconds', true) >= LOCAL_STORAGE_UPDATE_SECONDS_INTERVALS.ACTION_PLAN_UPDATES_AT) return undefined;
  const actionPlan: ActionPlan = JSON.parse(value);
  return actionPlan;
};

/* ---------------------------------- delay --------------------------------- */

export type Delay = {
  interval: number;
  startTime: number;
  notiId?: string;
};

const storeDelay = async (delay: Delay) => {
  if (delay) {
    await AsyncStorage.setItem(LOCAL_STORAGE_KEYS.DELAY, JSON.stringify(delay));
  }
};

const getDelay = async () => {
  const value = await AsyncStorage.getItem(LOCAL_STORAGE_KEYS.DELAY);
  if (value) {
    const delay: Delay = JSON.parse(value);
    return delay;
  }
  return undefined;
};

const deleteDelay = async () => {
  await AsyncStorage.removeItem(LOCAL_STORAGE_KEYS.DELAY).catch((e) => console.error(e));
};

/* ------------------------------- preprocess ------------------------------- */

const preprocessStorages = async () => {
  if (env === 'development') {
    await deleteSettings();
    await deleteUser();
    await deleteDelay();
    await deleteTermsAndConditions();
    await deleteTermsAndConditionsAgreement();
  }
};

export default {
  storeTermsAndConditions,
  getTermsAndConditions,
  getTermsAndConditionsAgreement,
  storeUser,
  deleteUser,
  getUser,
  storeSettings,
  getSettings,
  storeMessages,
  getMessages,
  storeActionPlan,
  getActionPlan,
  storeDelay,
  getDelay,
  deleteDelay,
  preprocessStorages,
};

import React, { ReactElement } from 'react';
import styles from '#components/buttons/styles/base.button';
import { Button } from '@rneui/themed';
import { StyleProp } from 'react-native';
import appUtil from '#utils/app.util';

interface Properties {
  type: 'solid' | 'clear' | 'outline' | undefined;
  disabled?: boolean;
  backgroundColor?: string;
  color?: string;
  onPress: any;
  title?: string;
  icon?: ReactElement | StyleProp<any>;
  containerStyle?: StyleProp<any>;
  buttonStyle?: StyleProp<any>;
  titleStyle?: StyleProp<any>;
  borderColor?: string;
  iconPosition?: 'left' | 'right' | 'top' | 'bottom' | undefined;
}

function BaseButton(props: Properties) {
  return (
    <Button
      disabled={props.disabled}
      type={props.type}
      containerStyle={{
        ...styles.buttonContainer,
        ...props.containerStyle,
        borderColor: props.borderColor,
      }}
      buttonStyle={{
        ...styles.button,
        ...props.buttonStyle,
        backgroundColor: props.backgroundColor,
      }}
      titleStyle={{ ...styles.buttonTitle, ...props.titleStyle, color: props.color }}
      title={appUtil.formatSentence(props.title || '')}
      icon={props.icon}
      iconPosition={props.iconPosition}
      onPress={props.onPress}
    />
  );
}

export default BaseButton;

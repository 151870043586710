/* eslint-disable react-native/no-color-literals */
import { Platform, StyleSheet } from 'react-native';
import COLORS, { BACKGROUND_COLORS, FOREGROUND_COLORS } from './colors';

export const BORDER_RADIUS = 16;

export const MARGIN_VERTICAL = 12;

export const MARGIN_HORIZONTAL = 12;

export const PADDING = 14;
export const PADDING_VERTICAL = 16;

export const FONT_LABEL_SIZE = 12;
export const FONT_SIZE = 16;
export const FONT_TITLE_SIZE = 18;

export const GENERAL_STYLES = StyleSheet.create({
  buttons: {
    alignItems: 'stretch',
    borderRadius: BORDER_RADIUS,
    borderWidth: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: MARGIN_VERTICAL,
    marginHorizontal: MARGIN_HORIZONTAL,
    overflow: 'hidden',
  },
  card: {
    alignItems: 'stretch',
    backgroundColor: BACKGROUND_COLORS.CARD,
    borderColor: COLORS.CHROME_GREY,
    borderRadius: BORDER_RADIUS,
    borderWidth: 0,
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 1,
    justifyContent: 'center',
    marginBottom: MARGIN_VERTICAL,
    marginHorizontal: MARGIN_HORIZONTAL,
    marginVertical: 0,
    minHeight: 80,
    padding: PADDING,
    paddingVertical: PADDING_VERTICAL,
  },
  cardDescription: {
    color: FOREGROUND_COLORS.MAIN,
    fontSize: FONT_SIZE,
    textAlign: 'left',
  },
  cardHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  cardIcon: {
    fontWeight: 'bold',
    margin: 0,
    marginRight: 10,
    padding: 0,
  },
  cardTitle: {
    fontSize: FONT_TITLE_SIZE,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  content: {
    alignItems: 'stretch',
    backgroundColor: BACKGROUND_COLORS.CONTENT,
    borderRadius: BORDER_RADIUS,
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: MARGIN_VERTICAL,
    marginHorizontal: MARGIN_HORIZONTAL,
    marginVertical: 0,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 20,
  },
  gridCol: {
    alignItems: 'stretch',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  gridRow: {
    alignItems: 'stretch',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  page: {
    backgroundColor: BACKGROUND_COLORS.MAIN,
    flex: 1,
    overflow: 'hidden',
    ...(Platform.OS == 'web' ? { maxHeight: window.innerHeight } : {}),
  },
  scrollView: {
    flexGrow: 1,
    paddingTop: MARGIN_VERTICAL,
  },
});

export default {};

/* eslint-disable react-native/no-inline-styles */
import { NavigationProp } from '@react-navigation/core';
import { User } from '#data/user';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Messages } from '#data/extras';
import styles from './styles/forgot-password.page';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import appUtil from '#utils/app.util';
import { MESSAGES } from '#constants/index';
import { View, SafeAreaView, Alert, TouchableOpacity } from 'react-native';
import BaseButton from '#components/buttons';
import COLORS from '#constants/colors';
import authorizationService from '#services/authorization.service';
import LoadingPage from './loading.page';
import { Icon, Input } from '@rneui/themed';
import { Settings } from '#data/settings';
import { FONT_LABEL_SIZE, FONT_SIZE, MARGIN_HORIZONTAL } from '#constants/styles';

interface ChangePasswordPageProps {
  user?: User;
  navigation: NavigationProp<any>;
  settings: Settings;
  onChangeUser(user?: User): Promise<User | undefined>;
  messages: Messages;
  setError: Dispatch<SetStateAction<string>>;
  route: any;
}

function ChangePasswordPage(props: ChangePasswordPageProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [secureCurrentTextEntry, setSecureCurrentTextEntry] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [secureNewTextEntry, setSecureNewTextEntry] = useState(true);
  const [newRetypePassword, setNewRetypePassword] = useState('');
  const [secureRetypeTextEntry, setSecureRetypeTextEntry] = useState(true);
  const reset = () => {
    setCurrentPassword('');
    setNewPassword('');
    setNewRetypePassword('');
  };

  if (isLoading) return <LoadingPage withImage />;

  return (
    <SafeAreaView style={styles.page}>
      <KeyboardAwareScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.content}>
          <Input
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.LOGIN_PASSWORD_PLACEHOLDER])}
            label={appUtil.formatSentence(props.messages[MESSAGES.CURRENT_PASSWORD])}
            secureTextEntry={secureCurrentTextEntry}
            value={currentPassword}
            onChangeText={setCurrentPassword}
            leftIcon={{
              type: 'material-community',
              name: 'lock',
            }}
            rightIcon={
              <TouchableOpacity onPress={() => setSecureCurrentTextEntry(!secureCurrentTextEntry)}>
                <Icon
                  name={secureCurrentTextEntry ? 'eye-off' : 'eye'}
                  type='material-community'
                />
              </TouchableOpacity>
            }
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
          />
          <Input
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.LOGIN_PASSWORD_PLACEHOLDER])}
            label={appUtil.formatSentence(props.messages[MESSAGES.NEW_PASSWORD])}
            secureTextEntry={secureNewTextEntry}
            value={newPassword}
            onChangeText={setNewPassword}
            leftIcon={{
              type: 'material-community',
              name: 'update',
            }}
            rightIcon={
              <TouchableOpacity onPress={() => setSecureNewTextEntry(!secureNewTextEntry)}>
                <Icon
                  name={secureNewTextEntry ? 'eye-off' : 'eye'}
                  type='material-community'
                />
              </TouchableOpacity>
            }
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
          />
          <Input
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.LOGIN_PASSWORD_PLACEHOLDER])}
            label={appUtil.formatSentence(props.messages[MESSAGES.RETYPE_NEW_PASSWORD])}
            secureTextEntry={secureRetypeTextEntry}
            value={newRetypePassword}
            onChangeText={setNewRetypePassword}
            leftIcon={{
              type: 'material-community',
              name: 'lock-check',
            }}
            rightIcon={
              <TouchableOpacity onPress={() => setSecureRetypeTextEntry(!secureRetypeTextEntry)}>
                <Icon
                  name={secureRetypeTextEntry ? 'eye-off' : 'eye'}
                  type='material-community'
                />
              </TouchableOpacity>
            }
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
          />
        </View>
        <BaseButton
          type='solid'
          backgroundColor={COLORS.TRANSPARENT_BLUE}
          color={COLORS.BLUE}
          onPress={() => {
            setIsLoading(true);
            if (props.user && props.user.id && props.user.token) {
              authorizationService
                .changePassword(props.user.token, currentPassword, newPassword)
                .then((res) => {
                  Alert.alert(
                    appUtil.formatSentence(props.messages[MESSAGES.ALERT]),
                    appUtil.formatSentence(
                      res?.success ? props.messages[MESSAGES.CHANGE_PASSWORD_SUCCESS] : props.messages[MESSAGES.CHANGE_PASSWORD_FAIL],
                    ),
                  );
                  if (res?.success) {
                    reset();
                  }
                })
                .catch((e) => {
                  console.error(e);
                  Alert.alert(
                    appUtil.formatSentence(props.messages[MESSAGES.ERROR]),
                    appUtil.formatSentence(props.messages[MESSAGES.CHANGE_PASSWORD_FAIL]),
                  );
                })
                .finally(() => setIsLoading(false));
            }
          }}
          disabled={!(currentPassword && newPassword && newRetypePassword && newPassword === newRetypePassword)}
          title={props.messages[MESSAGES.CHANGE_PASSWORD]}
          containerStyle={{ marginHorizontal: MARGIN_HORIZONTAL }}
        />
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

export default ChangePasswordPage;

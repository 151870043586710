import * as Location from 'expo-location';
import { get } from 'lodash';
import { Platform } from 'react-native';

const isAllowed = async () => {
  const { granted } = await Location.getForegroundPermissionsAsync();
  return granted;
};

const requestLocation = async () => {
  const { granted } = await Location.requestForegroundPermissionsAsync();
  return granted;
};

const getOrRequestLocationPermission = async () => {
  let isGranted = await isAllowed();
  if (!isGranted) {
    isGranted = await requestLocation();
  }
  return isGranted;
};

const getCurrentPosition = async () => {
  const location = await Location.getCurrentPositionAsync({
    accuracy: Location.Accuracy.Balanced,
    timeInterval: 5 * 60 * 1000,
  });
  let info;
  if (Platform.OS != 'web') {
    info = await Location.reverseGeocodeAsync(location.coords);
  }
  return {
    ...location,
    info: get(info, '[0]'),
  };
};

export default {
  isAllowed,
  requestLocation,
  getCurrentPosition,
  getOrRequestLocationPermission,
};

/* eslint-disable react-native/no-inline-styles */
import { NavigationProp } from '@react-navigation/core';
import { User } from '#data/user';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Messages } from '#data/extras';
import styles from './styles/forgot-password.page';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import appUtil from '#utils/app.util';
import { MESSAGES } from '#constants/index';
import { View, SafeAreaView, Alert } from 'react-native';
import BaseButton from '#components/buttons';
import COLORS from '#constants/colors';
import authorizationService from '#services/authorization.service';
import LoadingPage from './loading.page';
import { Input } from '@rneui/themed';
import { Settings } from '#data/settings';
import * as Linking from 'expo-linking';
import { FONT_LABEL_SIZE, FONT_SIZE, MARGIN_HORIZONTAL } from '#constants/styles';

interface ForgetPasswordPageProps {
  navigation: NavigationProp<any>;
  settings: Settings;
  onChangeUser(user?: User): Promise<User | undefined>;
  messages: Messages;
  setError: Dispatch<SetStateAction<string>>;
}

function ForgetPasswordPage(props: ForgetPasswordPageProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');

  if (isLoading) return <LoadingPage withImage />;

  return (
    <SafeAreaView style={styles.page}>
      <KeyboardAwareScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.content}>
          <Input
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.LOGIN_EMAIL_PLACEHOLDER])}
            label='Email'
            value={username}
            onChangeText={setUsername}
            leftIcon={{
              type: 'material-community',
              name: 'email',
            }}
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
            keyboardType='email-address'
          />
        </View>
        <BaseButton
          type='solid'
          backgroundColor={COLORS.TRANSPARENT_BLUE}
          color={COLORS.BLUE}
          onPress={() => {
            setIsLoading(true);
            authorizationService
              .forgetPassword(
                username.trim(),
                props.settings.locale,
                Linking.createURL('/Main/LoginNav/ResetPassword', { queryParams: { token: '' } }),
              )
              .then((res) => {
                Alert.alert(
                  appUtil.formatSentence(res?.success ? props.messages[MESSAGES.ALERT] : props.messages[MESSAGES.ERROR]),
                  appUtil.formatSentence(
                    res?.success
                      ? props.messages[MESSAGES.SEND_FORGET_PASSWORD_REQUEST_SUCCESS]
                      : props.messages[MESSAGES.SEND_FORGET_PASSWORD_REQUEST_FAILED],
                  ),
                );
                if (res?.success) {
                  props.navigation.navigate('ResetPassword');
                }
              })
              .catch((e) => {
                console.error(e);
                Alert.alert(
                  appUtil.formatSentence(props.messages[MESSAGES.ERROR]),
                  appUtil.formatSentence(props.messages[MESSAGES.SEND_FORGET_PASSWORD_REQUEST_FAILED]),
                );
              })
              .finally(() => setIsLoading(false));
          }}
          disabled={!username}
          title={props.messages[MESSAGES.SEND_FORGET_PASSWORD_REQUEST]}
          containerStyle={{ marginHorizontal: MARGIN_HORIZONTAL }}
        />
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

export default ForgetPasswordPage;

/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';
import { BORDER_RADIUS, FONT_SIZE, GENERAL_STYLES, MARGIN_HORIZONTAL, PADDING } from '#constants/styles';
import COLORS from '#constants/colors';

export default StyleSheet.create({
  ...GENERAL_STYLES,
  buttons: {
    ...GENERAL_STYLES.buttons,
    alignItems: 'stretch',
    borderRadius: 0,
    borderTopColor: COLORS.CHROME_GREY,
    borderTopWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 0,
  },
  checkBox: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: BORDER_RADIUS,
    borderWidth: 0,
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-start',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: MARGIN_HORIZONTAL,
    marginTop: 0,
    padding: PADDING,
  },
  checkBoxText: {
    flex: 1,
    flexWrap: 'wrap',
    fontSize: FONT_SIZE,
    fontWeight: 'normal',
    margin: 0,
    padding: 0,
    textAlign: 'justify',
  },
  page: {
    ...GENERAL_STYLES.page,
    backgroundColor: 'white',
    flexDirection: 'column',
  },
  scrollView: {
    ...GENERAL_STYLES.scrollView,
    marginTop: 0,
    marginVertical: 0,
    padding: PADDING,
  },
  text: {
    fontSize: FONT_SIZE,
    textAlign: 'left',
  },
  title: {
    borderBottomColor: COLORS.CHROME_GREY,
    borderBottomWidth: 1,
    color: COLORS.BLACK,
    fontWeight: 'bold',
    padding: 8,
    textAlign: 'center',
  },
  titleText: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

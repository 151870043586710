/* eslint-disable react-native/no-inline-styles,react-native/no-color-literals */
import { MESSAGES } from '#constants/index';
import COLORS from '#constants/colors';
import { Messages } from '#data/extras';
import { Settings } from '#data/settings';
import LoadingPage, { Loading } from '#pages/loading.page';
import styles from '#pages/styles/weather.page';
import { useFocusEffect } from '@react-navigation/native';
import locationService from '#services/location.service';
import weatherService from '#services/weather.service';
import appUtil from '#utils/app.util';
import { FONT_SIZE } from '#constants/styles';
import { get, isNil, join, map, omitBy, pick, toPairs } from 'lodash';
import moment from 'moment';
import 'moment/min/locales';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Alert, Platform, RefreshControl, SafeAreaView, ScrollView, View } from 'react-native';
import { Text } from '@rneui/themed';

interface WeatherPageProps {
  messages: Messages;
  settings: Settings;
  setError: Dispatch<SetStateAction<string>>;
}

function WeatherPage(props: WeatherPageProps) {
  const [weather, setWeather] = useState();
  const [airPollution, setAirPollution] = useState();
  const [location, setLocation]: [any, any] = useState();
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [isLoadingAirPollution, setIsLoadingAirPollution] = useState(false);
  const [isLoadingWeather, setIsLoadingWeather] = useState(false);
  const [loading, setLoading] = useState(false);
  const [airPollutionRecommendation, setAirPollutionRecommendation] = useState();
  const [refreshing, setRefreshing] = useState(false);

  const refreshLocation = async () => {
    setIsLoadingLocation(true);
    setLocation(undefined);
    try {
      const locInfo = await locationService.getCurrentPosition();
      setLocation(locInfo);
    } catch (e) {
      props.setError(props.messages[MESSAGES.LOCATION]);
    }
    setIsLoadingLocation(false);
  };

  const refreshWeather = async () => {
    setIsLoadingWeather(true);
    try {
      const weatherInfo = await weatherService.getCurrentWeatherInfo(props.settings.locale, location);
      setWeather(weatherInfo);
    } catch (e) {
      props.setError(props.messages[MESSAGES.WEATHER_GET_FAILED]);
    }
    setIsLoadingWeather(false);
  };

  const refreshAirPollution = async () => {
    setIsLoadingAirPollution(true);
    try {
      const newWeather = await weatherService.getCurrentAirPollutionInfo(location);
      setAirPollution(newWeather);
      if (newWeather) {
        const recommendation = await weatherService.getRecommendation(get(newWeather, 'current.pollution.aqius'), props.settings.locale);
        setAirPollutionRecommendation(recommendation);
      }
    } catch (e) {
      props.setError(props.messages[MESSAGES.AIR_POLLUTION_GET_FAILED]);
    }
    setIsLoadingAirPollution(false);
  };

  const onRefresh = async () => {
    setRefreshing(true);
    setLoading(true);
    const isGranted = await locationService.getOrRequestLocationPermission();
    if (isGranted) {
      await refreshLocation();
    } else {
      if (Platform.OS == 'web') {
        alert(appUtil.formatSentence(props.messages[MESSAGES.PLEASE_ENABLE_LOCATION_SERVICE]));
      } else {
        Alert.alert(
          appUtil.formatSentence(props.messages[MESSAGES.ERROR]),
          appUtil.formatSentence(props.messages[MESSAGES.PLEASE_ENABLE_LOCATION_SERVICE]),
        );
      }
    }
    setRefreshing(false);
    setLoading(false);
  };

  useEffect(() => {
    if (location) {
      refreshAirPollution();
      refreshWeather();
    }
  }, [location]);

  useFocusEffect(
    useCallback(() => {
      onRefresh();
    }, []),
  );

  return loading ? (
    <LoadingPage withImage />
  ) : (
    <SafeAreaView style={styles.page}>
      <ScrollView
        contentContainerStyle={styles.scrollView}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }>
        <View style={styles.card}>
          <Text style={{ ...styles.title, color: COLORS.RED }}>{props.messages[MESSAGES.AIR_POLLUTION]}</Text>
          {airPollution ? (
            <>
              <View style={styles.cardRow}>
                <View style={styles.cardRowItem}>
                  <Text style={{ ...styles.cardRowItemTitle, color: COLORS.RED, fontWeight: 'bold', fontSize: 48 }}>
                    {appUtil.formatTitle('aqi')}
                  </Text>
                  <Text style={{ ...styles.cardRowItemContent, fontWeight: 'bold', fontSize: 48 }}>
                    {get(airPollution, 'current.pollution.aqius')}
                  </Text>
                </View>
              </View>
              {/* {chunk(toPairs(get(airPollution, 'list[0].components', {})), 2).map((value, index) => (
                <View style={styles.cardRow} key={index}>
                  <View style={styles.cardRowItem}>
                    <Text style={{ ...styles.cardRowItemTitle, color: COLORS.RED }}>{appUtil.formatTitleAQI(get(value, '[0][0]'))}</Text>
                    <Text style={styles.cardRowItemContent}>{get(value, '[0][1]')}</Text>
                  </View>
                  <View style={styles.cardRowItem}>
                    <Text style={{ ...styles.cardRowItemTitle, color: COLORS.RED }}>{appUtil.formatTitleAQI(get(value, '[1][0]'))}</Text>
                    <Text style={styles.cardRowItemContent}>{get(value, '[1][1]')}</Text>
                  </View>
                </View>
              ))} */}
              <View style={styles.cardRow}>
                <View style={styles.cardRowItem}>
                  <Text style={{ ...styles.cardRowItemTitle, color: COLORS.RED }}>{appUtil.formatTitle(props.messages[MESSAGES.LAST_UPDATED])}</Text>
                  <Text style={styles.cardRowItemContent}>
                    {moment(get(airPollution, 'ts'))
                      .local()
                      .locale(props.settings.locale.includes('vi') ? 'vi' : 'en-gb')
                      .format('LL, HH:mm:ss')}
                  </Text>
                </View>
              </View>
            </>
          ) : isLoadingAirPollution ? (
            <Loading />
          ) : (
            <View style={styles.cardRow}>
              <View style={styles.cardRowItem}>
                <Text style={styles.cardRowItemContent}>{appUtil.formatSentence(props.messages[MESSAGES.PLEASE_ENABLE_LOCATION_SERVICE])}</Text>
              </View>
            </View>
          )}
        </View>
        {airPollutionRecommendation && !isLoadingAirPollution ? (
          <View style={{ ...styles.card, backgroundColor: get(airPollutionRecommendation, 'color') }}>
            <Text
              style={{
                ...styles.title,
                color: appUtil.invertColor(get(airPollutionRecommendation, 'color', 'white')),
              }}>
              {`${appUtil.formatSentence(props.messages[MESSAGES.RISK])}: ${get(airPollutionRecommendation, 'outdoorPhysicalActivityRisk', '')}`}
            </Text>
            <View style={{ ...styles.cardRow, flexDirection: 'column' }}>
              {map(get(airPollutionRecommendation, 'recommendations', []), (s: string, index: number) => (
                <Text
                  style={{
                    fontSize: FONT_SIZE,
                    color: appUtil.invertColor(get(airPollutionRecommendation, 'color', 'white')),
                    textAlign: 'left',
                    width: '100%',
                    marginBottom: 5,
                  }}
                  key={index}>
                  {`- ${appUtil.formatSentence(s)}`}
                </Text>
              ))}
            </View>
          </View>
        ) : null}
        <View style={styles.card}>
          <Text style={{ ...styles.title, color: COLORS.BLUE }}>{props.messages[MESSAGES.WEATHER]}</Text>
          {weather ? (
            <>
              <View style={styles.cardRow}>
                <View style={styles.cardRowItem}>
                  <Text style={{ ...styles.cardRowItemTitle, color: COLORS.BLUE }}>{appUtil.formatTitle(props.messages[MESSAGES.INFO])}</Text>
                  <Text style={{ ...styles.cardRowItemContent, textTransform: 'capitalize' }}>
                    {join(
                      get(weather, 'weather', []).map((info: any) => info.description),
                      ',',
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.cardRow}>
                <View style={styles.cardRowItem}>
                  <Text style={{ ...styles.cardRowItemTitle, color: COLORS.BLUE }}>{appUtil.formatTitle(props.messages[MESSAGES.TEMPERATURE])}</Text>
                  <Text style={styles.cardRowItemContent}>{get(weather, 'main.temp')}°C</Text>
                </View>
              </View>
              <View style={styles.cardRow}>
                <View style={styles.cardRowItem}>
                  <Text style={{ ...styles.cardRowItemTitle, color: COLORS.BLUE }}>{appUtil.formatTitle(props.messages[MESSAGES.PRESSURE])}</Text>
                  <Text style={styles.cardRowItemContent}>{get(weather, 'main.pressure')}hPa</Text>
                </View>
              </View>
              <View style={styles.cardRow}>
                <View style={styles.cardRowItem}>
                  <Text style={{ ...styles.cardRowItemTitle, color: COLORS.BLUE }}>{appUtil.formatTitle(props.messages[MESSAGES.HUMIDITY])}</Text>
                  <Text style={styles.cardRowItemContent}>{get(weather, 'main.humidity')}%</Text>
                </View>
              </View>
              <View style={styles.cardRow}>
                <View style={styles.cardRowItem}>
                  <Text style={{ ...styles.cardRowItemTitle, color: COLORS.BLUE }}>{appUtil.formatTitle(props.messages[MESSAGES.WIND_SPEED])}</Text>
                  <Text style={styles.cardRowItemContent}>{get(weather, 'wind.speed')}m/s</Text>
                </View>
              </View>
              <View style={styles.cardRow}>
                <View style={styles.cardRowItem}>
                  <Text style={{ ...styles.cardRowItemTitle, color: COLORS.BLUE }}>{appUtil.formatTitle(props.messages[MESSAGES.LAST_UPDATED])}</Text>
                  <Text style={styles.cardRowItemContent}>
                    {moment(get(weather, 'dt') * 1000)
                      .local()
                      .locale(props.settings.locale.includes('vi') ? 'vi' : 'en-gb')
                      .format('LL, HH:mm:ss')}
                  </Text>
                </View>
              </View>
            </>
          ) : isLoadingWeather ? (
            <Loading />
          ) : (
            <View style={styles.cardRow}>
              <View style={styles.cardRowItem}>
                <Text style={styles.cardRowItemContent}>{appUtil.formatSentence(props.messages[MESSAGES.PLEASE_ENABLE_LOCATION_SERVICE])}</Text>
              </View>
            </View>
          )}
        </View>
        <View style={styles.card}>
          <Text style={{ ...styles.title, color: COLORS.GREEN }}>{props.messages[MESSAGES.LOCATION]}</Text>
          {location ? (
            <>
              <View style={styles.cardRow}>
                <View style={styles.cardRowItem}>
                  <Text style={{ ...styles.cardRowItemTitle, color: COLORS.GREEN, textAlignVertical: 'top' }}>
                    {appUtil.formatTitle(props.messages[MESSAGES.ADDRESS])}
                  </Text>
                  <Text style={styles.cardRowItemContent}>
                    {/* eslint-disable-next-line prettier/prettier */}
                    {join(toPairs(omitBy(pick(get(location, 'info'), ['name', 'subregion', 'district', 'city', 'country']), isNil)).map((keyVal) => keyVal[1]), ', ')}
                  </Text>
                </View>
              </View>
              <View style={styles.cardRow}>
                <View style={styles.cardRowItem}>
                  <Text style={{ ...styles.cardRowItemTitle, color: COLORS.GREEN }}>
                    {appUtil.formatTitle(props.messages[MESSAGES.LAST_UPDATED])}
                  </Text>
                  <Text style={styles.cardRowItemContent}>
                    {moment(get(location, 'timestamp'))
                      .local()
                      .locale(props.settings.locale.includes('vi') ? 'vi' : 'en-gb')
                      .format('LL, HH:mm:ss')}
                  </Text>
                </View>
              </View>
            </>
          ) : isLoadingLocation ? (
            <Loading />
          ) : (
            <View style={styles.cardRow}>
              <View style={styles.cardRowItem}>
                <Text style={styles.cardRowItemContent}>{appUtil.formatSentence(props.messages[MESSAGES.PLEASE_ENABLE_LOCATION_SERVICE])}</Text>
              </View>
            </View>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export default WeatherPage;

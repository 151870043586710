/* eslint-disable react-native/no-inline-styles */
import { NavigationProp, useFocusEffect } from '@react-navigation/core';
import { User } from '#data/user';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Messages } from '#data/extras';
import styles from './styles/forgot-password.page';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import appUtil from '#utils/app.util';
import { MESSAGES } from '#constants/index';
import { View, SafeAreaView, Alert, TouchableOpacity, Text } from 'react-native';
import BaseButton from '#components/buttons';
import COLORS from '#constants/colors';
import authorizationService from '#services/authorization.service';
import LoadingPage from './loading.page';
import { Icon, Input } from '@rneui/themed';
import { Settings } from '#data/settings';
import { get } from 'lodash';
import { FONT_LABEL_SIZE, FONT_SIZE, MARGIN_HORIZONTAL } from '#constants/styles';

interface ResetPasswordPageProps {
  navigation: NavigationProp<any>;
  settings: Settings;
  onChangeUser(user?: User): Promise<User | undefined>;
  messages: Messages;
  setError: Dispatch<SetStateAction<string>>;
  route: any;
}

function ResetPasswordPage(props: ResetPasswordPageProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  useFocusEffect(
    useCallback(() => {
      const paramToken = get(props.route, 'params.token');
      if (paramToken) setToken(paramToken);
    }, [props.route.params?.token]),
  );
  return isLoading ? (
    <LoadingPage withImage />
  ) : (
    <SafeAreaView style={styles.page}>
      <KeyboardAwareScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.card}>
          <Text style={{ fontSize: 18, textAlign: 'center' }}>{appUtil.formatSentence(props.messages[MESSAGES.RESET_PASSWORD_DESCRIPTION])}</Text>
        </View>
        <View style={styles.content}>
          <Input
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.TOKEN_PLACEHOLDER])}
            label='Token'
            value={token}
            onChangeText={setToken}
            leftIcon={{
              type: 'material-community',
              name: 'code-tags',
            }}
            editable={false}
            disabled={true}
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
          />
          <Input
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.LOGIN_PASSWORD_PLACEHOLDER])}
            label={appUtil.formatSentence(props.messages[MESSAGES.NEW_PASSWORD])}
            secureTextEntry={secureTextEntry}
            value={password}
            onChangeText={setPassword}
            leftIcon={{
              type: 'material-community',
              name: 'lock',
            }}
            rightIcon={
              <TouchableOpacity onPress={() => setSecureTextEntry(!secureTextEntry)}>
                <Icon
                  name={secureTextEntry ? 'eye-off' : 'eye'}
                  type='material-community'
                />
              </TouchableOpacity>
            }
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
          />
        </View>
        <BaseButton
          type='solid'
          backgroundColor={COLORS.TRANSPARENT_BLUE}
          color={COLORS.BLUE}
          onPress={() => {
            setIsLoading(true);
            authorizationService
              .resetPassword(token, password)
              .then((res) => {
                Alert.alert(
                  appUtil.formatSentence(props.messages[MESSAGES.ALERT]),
                  appUtil.formatSentence(
                    res?.success
                      ? props.messages[MESSAGES.SEND_RESET_PASSWORD_REQUEST_SUCCESS]
                      : props.messages[MESSAGES.SEND_RESET_PASSWORD_REQUEST_FAILED],
                  ),
                );
                props.navigation.reset({ index: 0, stale: true, routes: [{ name: 'Main' }] });
              })
              .catch((e) => {
                console.error(e);
                Alert.alert(
                  appUtil.formatSentence(props.messages[MESSAGES.ERROR]),
                  appUtil.formatSentence(props.messages[MESSAGES.SEND_RESET_PASSWORD_REQUEST_FAILED]),
                );
              });
          }}
          disabled={!(token && password)}
          title={props.messages[MESSAGES.SEND_RESET_PASSWORD_REQUEST]}
          containerStyle={{ marginHorizontal: MARGIN_HORIZONTAL }}
        />
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

export default ResetPasswordPage;

export const MESSAGES = {
  ACCOUNT_NOT_FOUND: 'account-not-found',
  LOGIN_SUCCESS: 'login-success',
  LOGIN_FAILED: 'login-failed',
  WEATHER_WARNINGS: 'weather-warnings',
  EDUCATION: 'education',
  EMERGENCY: 'emergency',
  HOME: 'home',
  LANGUAGE: 'language',
  VIETNAMESE: 'vietnamese',
  ENGLISH: 'english',
  SETTINGS: 'settings',
  FAQ: 'faq',
  LOGIN: 'login',
  LOGOUT: 'logout',
  DONE: 'done',
  REGISTER: 'register',
  NAME: 'name',
  ASTHMA_DIARY: 'asthma-diary',
  PROFILE: 'profile',
  MEDICATION: 'medication',
  HOME_WEATHER_WARNINGS_DESCRIPTION: 'home-weather-warnings-description',
  HOME_ASTHMA_DIARY_DESCRIPTION: 'home-asthma-diary-description',
  HOME_EDUCATION_DESCRIPTION: 'home-education-description',
  HOME_EMERGENCY_DESCRIPTION: 'home-emergency-description',
  HOME_SETTINGS_DESCRIPTION: 'home-settings-description',
  PASSWORD: 'password',
  LOGIN_PASSWORD_PLACEHOLDER: 'login-password-placeholder',
  LOGIN_EMAIL_PLACEHOLDER: 'login-email-placeholder',
  PROFILE_NAME_PLACEHOLDER: 'profile-name-placeholder',
  PROFILE_EMAIL_PLACEHOLDER: 'profile-email-placeholder',
  PROFILE_DOB_PLACEHOLDER: 'profile-dob-placeholder',
  DOB: 'dob',
  GENDER: 'gender',
  PROFILE_GENDER_PLACEHOLDER: 'profile-gender-placeholder',
  PROFILE_PASSWORD_PLACEHOLDER: 'profile-password-placeholder',
  CLEAR: 'clear',
  UPDATE: 'update',
  PROFILE_CLEAR_DESCRIPTION: 'profile-clear-description',
  PROFILE_LOGOUT_DESCRIPTION: 'profile-logout-description',
  NO: 'no',
  YES: 'yes',
  COUNTRY: 'country',
  DISTRICT: 'district',
  CITY: 'city',
  LAST_UPDATED: 'last-updated',
  REGION: 'region',
  SUBREGION: 'subregion',
  TEMPERATURE: 'temperature',
  PRESSURE: 'pressure',
  HUMIDITY: 'humidity',
  WIND_SPEED: 'wind-speed',
  LOCATION: 'location',
  AIR_POLLUTION: 'air-pollution',
  WEATHER: 'weather',
  PLEASE_ENABLE_LOCATION_SERVICE: 'please-enable-location-service',
  ERROR: 'error',
  GENERAL_REQUEST_ENABLE_NETWORK: 'general-request-enable-network',
  WEATHER_GET_FAILED: 'weather-get-failed',
  AIR_POLLUTION_GET_FAILED: 'air-pollution-get-failed',
  ALERT: 'alert',
  SAVE: 'save',
  CANCEL: 'cancel',
  DIARY_SAVE: 'diary-save',
  DIARY_SAVE_DESCRIPTION: 'diary-save-description',
  DIARY_GET: 'diary-get',
  DIARY_GET_DESCRIPTION: 'diary-get-description',
  DIARY_SUMMARY: 'diary-summary',
  DIARY_SUMMARY_DESCRIPTION: 'diary-summary-description',
  DIARY_SETTING: 'diary-setting',
  DIARY_SETTING_DESCRIPTION: 'diary-setting-description',
  DIARY_REMINDER_ENABLE: 'diary-reminder-enable',
  DIARY_REMINDER_DESCRIPTION: 'diary-reminder-description',
  DIARY_REMINDER_TITLE: 'diary-reminder-title',
  DIARY_REMINDER_TITLE_DESCRIPTION: 'diary-reminder-title-description',
  DIARY_DAYTIME_SYMPTOM: 'diary-daytime-symptom',
  DIARY_NIGHT_WAKING: 'diary-night-waking',
  DIARY_RELIEVER_USED: 'diary-reliever-used',
  DIARY_NUM_RELIEVER_USED: 'diary-num-reliever-used',
  DIARY_ACTIVITY_LIMITATION: 'diary-activity-limitation',
  DIARY_PREVENTER_USED: 'diary-preventer-used',
  DIARY_PREVENTER_USED_MORNING: 'diary-preventer-used-morning',
  DIARY_PREVENTER_USED_NIGHT: 'diary-preventer-used-night',
  DIARY_IS_NORMAL: 'diary-is-normal',
  //
  DIARY_HTML_DAYTIME_SYMPTOM: 'diary-daytime-symptom-html',
  DIARY_HTML_NIGHT_WAKING: 'diary-night-waking-html',
  DIARY_HTML_RELIEVER_USED: 'diary-reliever-used-html',
  DIARY_HTML_ACTIVITY_LIMITATION: 'diary-activity-limitation-html',
  //
  DIARY_SAVE_CANCEL_DESCRIPTION: 'diary-save-cancel-description',
  DIARY_SAVE_TITLE: 'diary-save-title',
  DIARY_SAVE_SUCCESS: 'diary-save-success',
  DIARY_INTERVAL_RELIEVER_USED: 'diary-interval-reliever-used',
  DIARY_LOAD_MORE: 'diary-load-more',
  DIARY_CREATED_AT: 'diary-created-at',
  ENTER_NUMBER: 'enter-number',
  DIARY_FROM_EPOCH: 'diary-from-epoch',
  DIARY_TO_EPOCH: 'diary-to-epoch',
  FILTER: 'filter',
  CHOOSE_DATE: 'choose-date',
  MEDICATION_SAVE: 'medication-save',
  MEDICATION_EDIT: 'medication-edit',
  MEDICATION_SAVE_DESCRIPTION: 'medication-save-description',
  MEDICATION_MARK_TAKEN: 'medication-mark-taken',
  MEDICATION_SAVE_TITLE: 'medication-save-title',
  MEDICATION_SAVE_SUCCESS: 'medication-save-success',
  MEDICATION_MEDICINE_TYPE: 'medication-medicine-type',
  MEDICATION_INGREDIENT: 'medication-ingredient',
  MEDICATION_TOTAL_DOSES: 'medication-total-doses',
  MEDICATION_TOTAL_REMAINING_DOSES: 'medication-total-remaining-doses',
  MEDICATION_DOSE_UNIT: 'medication-dose-unit',
  MEDICATION_DOSES_PER_TIME: 'medication-doses-per-time',
  MEDICATION_DOSES_PER_TIME_PLACEHOLDER: 'medication-doses-per-time-placeholder',
  MEDICATION_LIST: 'medication-list',
  MEDICATION_LIST_DESCRIPTION: 'medication-list-description',
  MEDICINE_RELIEVER: 'medicine-reliever',
  MEDICINE_PREVENTER: 'medicine-preventer',
  MEDICATION_MEDICINE_TYPE_PLACEHOLDER: 'medication-medicine-type-placeholder',
  MEDICATION_INGREDIENT_PLACEHOLDER: 'medication-ingredient-placeholder',
  MEDICATION_INGREDIENT_MEDICINE_TYPE_MISSING: 'medication-ingredient-medicine-type-missing',
  MEDICATION_DOSE_UNIT_PLACEHOLDER: 'medication-dose-unit-placeholder',
  MEDICINE_INHALER: 'medicine-inhaler',
  MEDICINE_TABLET: 'medicine-tablet',
  MEDICATION_MORNING_REMINDER: 'medication-morning-reminder',
  MEDICATION_NIGHT_REMINDER: 'medication-night-reminder',
  DIARY_DAYTIME_SYMPTOM_TITLE: 'diary-daytime-symptom-title',
  DIARY_NIGHT_WAKING_TITLE: 'diary-night-waking-title',
  DIARY_RELIEVER_USED_TITLE: 'diary-reliever-used-title',
  DIARY_ACTIVITY_LIMITATION_TITLE: 'diary-activity-limitation-title',
  DIARY_PREVENTER_USED_TITLE: 'diary-preventer-used-title',
  EMERGENCY_PLEASE_CHECK_CONDITION_AFTER: 'emergency-please-check-condition-after',
  EMERGENCY_HOSPITAL: 'emergency-hospital',
  EMERGENCY_HOSPITAL_FIND: 'emergency-hospital-find',
  EMERGENCY_CALL: 'emergency-call',
  CHOOSE_TIME: 'choose-time',
  RISK: 'risk',
  MEDICATION_MORNING_DOSES: 'medication-morning-doses',
  MEDICATION_NIGHT_DOSES: 'medication-night-doses',
  EMERGENCY_DONE_WAITING: 'emergency-done-waiting',
  EMERGENCY_DONE_WAITING_DESCRIPTION: 'emergency-done-waiting-description',
  EDUCATION_FB: 'education-fb',
  OPEN_FB: 'open-fb',
  EDUCATION_WEBSITE: 'education-website',
  OPEN_WEBSITE: 'open-website',
  EDUCATION_ASTHMA_INFO: 'education-asthma-info',
  OPEN_GINA: 'open-gina',
  SKIP: 'skip',
  MINUTES: 'minutes',
  ACCEPT: 'accept',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  ENABLE_INTERNET: 'enable-internet',
  STH_WRONG_TRY_AGAIN: 'sth-wrong-try-again',
  INFO: 'info',
  ADDRESS: 'address',
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other',
  LOGIN_SESSION_EXPIRED: 'login-session-expired',
  FORGOT_PASSWORD: 'forgot-password',
  OTP_PLACEHOLDER: 'otp-placeholder',
  OTP_REQUEST: 'otp-request',
  RESET_PASSWORD: 'reset-password',
  NEW_PASSWORD: 'new-password',
  TOKEN_PLACEHOLDER: 'token-placeholder',
  SEND_FORGET_PASSWORD_REQUEST: 'send-forget-password-request',
  SEND_FORGET_PASSWORD_REQUEST_SUCCESS: 'send-forget-password-request-success',
  SEND_FORGET_PASSWORD_REQUEST_FAILED: 'send-forget-password-request-failed',
  SEND_RESET_PASSWORD_REQUEST: 'send-reset-password-request',
  SEND_RESET_PASSWORD_REQUEST_SUCCESS: 'send-reset-password-request-success',
  SEND_RESET_PASSWORD_REQUEST_FAILED: 'send-reset-password-request-failed',
  RESET_PASSWORD_DESCRIPTION: 'reset-password-description',
  GINA_SOURCE: 'gina-source',
  REFERENCE: 'reference',
  NEXT: 'next',
  BEGIN: 'begin',
  BACK: 'back',
  CHANGE_PASSWORD: 'change-password',
  CURRENT_PASSWORD: 'current-password',
  RETYPE_NEW_PASSWORD: 'retype-new-password',
  CHANGE_PASSWORD_SUCCESS: 'change-password-success',
  CHANGE_PASSWORD_FAIL: 'change-password-fail',
  TIME_TO_TAKE_MEDICINE: 'time-to-take-medicine',
  READ_INSTRUCTION_DESCRIPTION: 'read-instruction-description',
  PUFF: 'puff',
  TUBE: 'tube',
  PILL: 'pill',
  DELETE_CONFIRM_DESCRIPTION: 'delete-confirm-description',
  NOTIFICATION: 'notification',
  NOTIFICATION_SCHEDULE_TITLE: 'notification-schedule-title',
  NO_DATA: 'no-data',
  EMERGENCY_MDI_GUIDE: 'emergency-mdi-guide',
  EMERGENCY_NEBULIZER_WITH_MASK_GUIDE: 'emergency-nebulizer-with-mask-guide',
  BANNER_FOR_REGISTRATION: 'banner-for-registration',
  BANNER_FOR_REGISTRATION_DESCRIPTION: 'banner-for-registration-description',
  ALERT_AGE_RANGE: 'alert-age-range',
  MEDICATION_END_DATE_DESCRIPTION: 'medication-end-date-description',
  DAYS_LEFT: 'days-left',
  FOR_MORNING: 'for-morning',
  FOR_NIGHT: 'for-night',
  OUT_OF_MEDICINE: 'out-of-medicine',
  OUT_OF_MEDICINE_DESCRIPTION: 'out-of-medicine-description',
  NEAR_OUT_OF_MEDICINE_TITLE: 'near-out-of-medicine-title',
  NEAR_OUT_OF_MEDICINE_DESCRIPTION: 'near-out-of-medicine-description',
  ERROR_TIME_MIN: 'error-time-min',
  ERROR_TIME_MAX: 'error-time-max',
};

export const GENDERS = [undefined, MESSAGES.MALE, MESSAGES.FEMALE, MESSAGES.OTHER];

export const MESSAGE_PATHS = Object.values(MESSAGES);

export const LANGUAGES = {
  [MESSAGES.VIETNAMESE]: 'vi-vn',
  [MESSAGES.ENGLISH]: 'en-us',
};

export const MEDICINE_TYPES = {
  INHALER: MESSAGES.MEDICINE_INHALER,
  TABLET: MESSAGES.MEDICINE_TABLET,
};

export const KAAP = 'kids-asthma-action-plan';

export const LOCAL_STORAGE_KEYS = {
  USER: 'user',
  MESSAGES: '@messages',
  MESSAGES_LOCALE: '@messages-locale',
  MESSAGES_UPDATED_AT: '@messages-updated-at',
  ACTION_PLAN: '@action-plan',
  ACTION_PLAN_LOCALE: '@action-plan-locale',
  ACTION_PLAN_UPDATES_AT: '@action-plan-updated-at',
  SETTINGS: '@settings',
  DELAY: '@delay',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  TERMS_AND_CONDITIONS_AGREEMENT: 'terms-and-conditions-aggreement',
};

export const LOCAL_STORAGE_UPDATE_SECONDS_INTERVALS = {
  MESSAGES_UPDATED_AT: 5 * 60,
  ACTION_PLAN_UPDATES_AT: 1 * 60,
};

export const ENABLE_INTERNET = {
  [LANGUAGES[MESSAGES.VIETNAMESE]]: 'không thể tải các gói tin cần thiết, hãy kiểm tra kết nối internet',
  [LANGUAGES[MESSAGES.ENGLISH]]: 'unable to download neccessary packages, please check internet connection',
};

export const EXPIRE_LOGIN = {
  [LANGUAGES[MESSAGES.VIETNAMESE]]: 'phiên đăng nhập đã hết hiệu lực',
  [LANGUAGES[MESSAGES.ENGLISH]]: 'login session has expired',
};

export const SETTINGS = {
  [LANGUAGES[MESSAGES.VIETNAMESE]]: 'cài đặt',
  [LANGUAGES[MESSAGES.ENGLISH]]: 'settings',
};

export const CONTINUE = {
  [LANGUAGES[MESSAGES.VIETNAMESE]]: 'tiếp tục',
  [LANGUAGES[MESSAGES.ENGLISH]]: 'continue',
};

export const LANGUAGE_TEXT = {
  [LANGUAGES[MESSAGES.VIETNAMESE]]: 'ngôn ngữ',
  [LANGUAGES[MESSAGES.ENGLISH]]: 'language',
};

export const EMERGENCY_PERSISTENCE_NAV_STATE_KEY = 'EMERGENCY_NAVIGATION_STATE';

export default {};

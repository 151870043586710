/* eslint-disable react-native/no-inline-styles */
import { MESSAGES } from '#constants/index';
import kaapIcon from '#assets/kaap-notification-icon.png';
import COLORS from '#constants/colors';
import { stackNavigationScreenOptions } from '#constants/options';
import { MARGIN_HORIZONTAL, PADDING } from '#constants/styles';
import { ActionPlan, Messages } from '#data/extras';
import { Settings } from '#data/settings';
import { User } from '#data/user';
import DiaryPage, { DiaryListPage, DiarySavePage, DiaryViewPage, DiarySettingPage } from '#pages/diary.page';
import EducationPage from '#pages/education.page';
import HomePage from '#pages/home.page';
import MedicationPage, { MedicationListPage, MedicationSavePage } from '#pages/medication.page';
import SettingsPage, { LanguagePage } from '#pages/settings.page';
import WeatherPage from '#pages/weather.page';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { RouteProp, getPathFromState } from '@react-navigation/core';
import { createNativeStackNavigator as createStackNavigator } from '@react-navigation/native-stack';
import { Icon } from '@rneui/themed';
import appUtil from '#utils/app.util';
import { get } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Alert, Image, Platform, TouchableOpacity } from 'react-native';

const Stack = createStackNavigator();

interface HomeNavigationProps {
  user?: User;
  onChangeUser(user?: User): Promise<User | undefined>;
  settings: Settings;
  onChangeSettings(settings?: Settings, reload?: boolean, callback?: () => void): void;
  messages: Messages;
  actionPlan: ActionPlan;
  setShowLogoutDialog: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  navigation: BottomTabNavigationProp<any>;
  route: RouteProp<any>;
}

export default function HomeNavigation(homeProps: HomeNavigationProps) {
  const initialRouteName = 'Home';
  useEffect(() => {
    const unsubscribe = homeProps.navigation.addListener('tabPress', (e: any) => {
      e.preventDefault();
      if (!homeProps.navigation.isFocused()) {
        homeProps.navigation.jumpTo(homeProps.route.name);
      } else {
        const path = getPathFromState(homeProps.navigation.getState());
        let screenName = path.split('/').pop();
        screenName = get(screenName && screenName.split('?'), '[0]');
        if (screenName !== initialRouteName && screenName !== homeProps.route.name) {
          homeProps.navigation.reset({ index: 0, routes: [{ name: initialRouteName }] });
        }
      }
    });
    return unsubscribe;
  }, [homeProps.navigation]);
  return (
    <Stack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{
        ...stackNavigationScreenOptions,
      }}>
      <Stack.Screen
        name={initialRouteName}
        options={{
          title: appUtil.formatSentence(homeProps.messages[MESSAGES.HOME]),
          headerTitle: 'Kid Asthma Action Plan',
          headerLeft: () => (
            <Image
              source={kaapIcon}
              style={{ height: 36, width: 36, paddingHorizontal: PADDING, marginHorizontal: MARGIN_HORIZONTAL }}
            />
          ),
        }}>
        {(props: any) => (
          <HomePage
            {...homeProps}
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='WeatherWarnings'
        options={{
          title: appUtil.formatSentence(homeProps.messages[MESSAGES.WEATHER_WARNINGS]),
        }}>
        {(props: any) => (
          <WeatherPage
            {...homeProps}
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='Settings'
        options={{
          title: appUtil.formatSentence(homeProps.messages[MESSAGES.SETTINGS]),
        }}>
        {(props: any) => (
          <SettingsPage
            {...homeProps}
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='Language'
        options={{ title: appUtil.formatSentence(homeProps.messages[MESSAGES.LANGUAGE]) }}>
        {(props: any) => (
          <LanguagePage
            {...homeProps}
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='Education'
        options={{
          title: appUtil.formatSentence(homeProps.messages[MESSAGES.EDUCATION]),
        }}>
        {(props: any) => (
          <EducationPage
            {...homeProps}
            {...props}
          />
        )}
      </Stack.Screen>
      {Platform.OS != 'web' && (
        <>
          <Stack.Screen
            name='Diary'
            options={{
              title: appUtil.formatSentence(homeProps.messages[MESSAGES.ASTHMA_DIARY]),
            }}>
            {(props: any) => (
              <DiaryPage
                {...homeProps}
                {...props}
              />
            )}
          </Stack.Screen>
          <Stack.Screen
            name='DiarySave'
            options={{
              title: appUtil.formatSentence(homeProps.messages[MESSAGES.DIARY_SAVE]),
              headerRight: () => (
                <TouchableOpacity
                  style={{ marginHorizontal: MARGIN_HORIZONTAL }}
                  onPress={() => {
                    Alert.alert(
                      appUtil.formatSentence(homeProps.messages[MESSAGES.REFERENCE]),
                      appUtil.formatSentence(homeProps.messages[MESSAGES.GINA_SOURCE]),
                    );
                  }}>
                  <Icon
                    name='information'
                    type='material-community'
                    color={COLORS.BLACK}
                    size={24}
                    containerStyle={{
                      borderRadius: 1000,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  />
                </TouchableOpacity>
              ),
            }}>
            {(props: any) => (
              <DiarySavePage
                {...homeProps}
                {...props}
              />
            )}
          </Stack.Screen>
          <Stack.Screen
            name='DiaryView'
            options={{
              title: appUtil.formatSentence(homeProps.messages[MESSAGES.DIARY_GET]),
            }}>
            {(props: any) => (
              <DiaryViewPage
                {...homeProps}
                {...props}
              />
            )}
          </Stack.Screen>
          <Stack.Screen
            name='DiaryList'
            options={{
              title: appUtil.formatSentence(homeProps.messages[MESSAGES.DIARY_GET]),
              headerRight: () => (
                <TouchableOpacity
                  style={{ marginHorizontal: MARGIN_HORIZONTAL }}
                  onPress={() => {
                    Alert.alert(
                      appUtil.formatSentence(homeProps.messages[MESSAGES.REFERENCE]),
                      appUtil.formatSentence(homeProps.messages[MESSAGES.GINA_SOURCE]),
                    );
                  }}>
                  <Icon
                    name='information'
                    type='material-community'
                    color={COLORS.BLACK}
                    size={24}
                    containerStyle={{
                      borderRadius: 1000,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  />
                </TouchableOpacity>
              ),
            }}>
            {(props: any) => (
              <DiaryListPage
                {...homeProps}
                {...props}
              />
            )}
          </Stack.Screen>
          <Stack.Screen
            name='DiarySetting'
            options={{
              title: appUtil.formatSentence(homeProps.messages[MESSAGES.DIARY_SETTING]),
            }}>
            {(props: any) => (
              <DiarySettingPage
                {...homeProps}
                {...props}
              />
            )}
          </Stack.Screen>
          <Stack.Screen
            name='Medication'
            options={{ title: appUtil.formatSentence(homeProps.messages[MESSAGES.MEDICATION]) }}>
            {(props: any) => (
              <MedicationPage
                {...homeProps}
                {...props}
              />
            )}
          </Stack.Screen>
          <Stack.Screen
            name='MedicationSave'
            options={{ title: appUtil.formatSentence(homeProps.messages[MESSAGES.MEDICATION_SAVE]), headerRight: undefined }}>
            {(props: any) => (
              <MedicationSavePage
                {...homeProps}
                {...props}
              />
            )}
          </Stack.Screen>
          <Stack.Screen
            name='MedicationList'
            options={{ title: appUtil.formatSentence(homeProps.messages[MESSAGES.MEDICATION_LIST]) }}>
            {(props: any) => (
              <MedicationListPage
                {...homeProps}
                {...props}
              />
            )}
          </Stack.Screen>
        </>
      )}
    </Stack.Navigator>
  );
}

/* eslint-disable react-native/no-inline-styles,react-native/no-color-literals */
import { MESSAGES } from '#constants/index';
import medicationOpenIcon from '#assets/medication-open.png';
import medicationSaveIcon from '#assets/medication-save.png';
import enMedication from '#assets/medication/en.png';
import viMedication from '#assets/medication/vi-vn.png';
import BaseButton from '#components/buttons';
import Card from '#components/buttons/card';
import OverlayDialog from '#components/dialogs/overlay.dialog';
import Checkbox from '#components/inputs/checkbox.input';
import DatePicker from '#components/inputs/datepicker.input';
import COLORS from '#constants/colors';
import { BORDER_RADIUS, FONT_LABEL_SIZE, FONT_SIZE, FONT_TITLE_SIZE, MARGIN_HORIZONTAL, MARGIN_VERTICAL, PADDING } from '#constants/styles';
import { Messages } from '#data/extras';
import { Settings } from '#data/settings';
import { User } from '#data/user';
import LoadingPage, { Loading as MiniLoading, NoDataPage } from '#pages/loading.page';
import { NavigationProp, useFocusEffect } from '@react-navigation/core';
import { Icon, Input, Text } from '@rneui/themed';
import medicationService from '#services/medication.service';
import notificationService from '#services/notification.service';
import appUtil from '#utils/app.util';
import * as Notifications from 'expo-notifications';
import { filter, get, isNaN, isNil, toLower, toString } from 'lodash';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Alert,
  AlertButton,
  FlatList,
  Image,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styles from './styles/medication.page';

interface MedicationSavePageProps {
  settings: Settings;
  navigation: NavigationProp<any>;
  messages: Messages;
  user: User;
  setError: Dispatch<SetStateAction<string>>;
  route: any;
}

export function MedicationSavePage(props: MedicationSavePageProps) {
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const [id, setId]: [string | undefined, any] = useState(undefined);

  const [ingredient, setIngredient]: [any, any] = useState(undefined);
  const [isIngredientVisible, setIsIngredientVisible] = useState(false);

  const [unit, setUnit]: [any, any] = useState(undefined);

  const [totalDoses, setTotalDoses]: [any, any] = useState(undefined);
  const [totalDosesError, setTotalDosesError]: [string | undefined, any] = useState(undefined);

  const [totalRemainingDoses, setTotalRemainingDoses]: [any, any] = useState(undefined);
  const [totalRemainingDosesError, setTotalRemainingDosesError]: [string | undefined, any] = useState(undefined);

  const [morningDoses, setMorningDoses]: [any, any] = useState(undefined);
  const [morningDosesError, setMorningDosesError]: [string | undefined, any] = useState(undefined);

  const [morningReminder, setMorningReminder]: [any, any] = useState(undefined);
  const [showMorningReminder, setShowMorningReminder]: [any, any] = useState(false);
  const [morningNotificationId, setMorningNotificationId]: [string | undefined, any] = useState(undefined);

  const [nightDoses, setNightDoses]: [any, any] = useState(undefined);
  const [nightDosesError, setNightDosesError]: [string | undefined, any] = useState(undefined);

  const [nightReminder, setNightReminder]: [any, any] = useState(undefined);
  const [showNightReminder, setShowNightReminder]: [any, any] = useState(false);
  const [nightNotificationId, setNightNotificationId]: [string | undefined, any] = useState(undefined);

  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showMessageDialog, setShowMessageDialog] = useState(false);

  const [dbIngredients, setDbIngredients]: [any, any] = useState(undefined);
  const [filteredDbIngredients, setFilteredDbIngredients]: [any, any] = useState(undefined);
  const [isLoadingIngredient, setIsLoadingIngredient] = useState(false);

  const [editable, setEditable] = useState(true);
  const [endDate, setEndDate]: [string | undefined, any] = useState(undefined);
  const [showMedicineRunOutAlert, setShowMedicineRunOutAlert]: [string | undefined, any] = useState(undefined);

  const isError = () => {
    return totalDosesError || morningDosesError || nightDosesError;
  };

  const clear = () => {
    setShowCancelDialog(false);
    setId(undefined);
    setIngredient(undefined);
    setUnit(undefined);
    setTotalDoses(undefined);
    setTotalRemainingDoses(undefined);
    setMorningDoses(undefined);
    setNightDoses(undefined);
    setMorningReminder(undefined);
    setShowMorningReminder(false);
    setMorningNotificationId(undefined);
    setNightReminder(undefined);
    setShowNightReminder(false);
    setNightNotificationId(undefined);
  };

  const onCancel = () => {
    clear();
    props.navigation.goBack();
  };

  const onSave = () => {
    if (isError()) {
      props.setError(appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER]));
      return;
    }
    setLoading(true);
    if (!ingredient || !totalDoses) {
      Alert.alert(
        appUtil.formatSentence(props.messages[MESSAGES.ERROR]),
        appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_SAVE_DESCRIPTION]),
      );
      setLoading(false);
      return;
    }
    const data = {
      ingredient,
      unit,
      totalDoses,
      totalRemainingDoses,
      morningDoses,
      morningReminder,
      morningNotificationId,
      nightDoses,
      nightReminder,
      nightNotificationId,
    };
    if (id) {
      medicationService
        .updateMedication(props.user.id, props.user.token, id, data)
        .then((response: any) => {
          notificationService
            .registerMedicationReminder(
              ingredient,
              props.messages,
              response.id,
              morningReminder,
              response.morningNotificationId,
              nightReminder,
              response.nightNotificationId,
            )
            .then((notificationIds) => {
              medicationService
                .updateMedication(props.user.id, props.user.token, response.id, notificationIds)
                .then(() => setShowMessageDialog(true))
                .catch((e) => props.setError(e.message))
                .finally(() => {
                  clear();
                  setLoading(false);
                  props.navigation.navigate('MedicationList', { refresh: true });
                });
            })
            .catch((e) => {
              props.setError(e.message);
              setLoading(false);
            });
        })
        .catch((e) => {
          props.setError(e.message);
          setLoading(false);
        });
    } else {
      medicationService
        .saveMedication(props.user.id, props.user.token, data)
        .then((response: any) => {
          notificationService
            .registerMedicationReminder(ingredient, props.messages, response.id, morningReminder, undefined, nightReminder, undefined)
            .then((notificationIds) => {
              medicationService
                .updateMedication(props.user.id, props.user.token, response.id, notificationIds)
                .then(() => setShowMessageDialog(true))
                .catch((e) => props.setError(e.message))
                .finally(() => {
                  clear();
                  setLoading(false);
                });
            })
            .catch((e) => {
              props.setError(e.message);
              clear();
              setLoading(false);
            });
        })
        .catch((e) => {
          props.setError(e.message);
          clear();
          setLoading(false);
        });
    }
  };

  const _onMarkTakenAction = (doses: number) => {
    if (id) {
      setLoading(true);
      const data = {
        totalRemainingDoses: totalRemainingDoses > 0 ? Math.max(totalRemainingDoses - doses, 0) : 0,
      };
      medicationService
        .updateMedication(props.user.id, props.user.token, id, data)
        .then((response: any) => {
          if (response.totalRemainingDoses == 0) {
            notificationService.cancelMedicationReminder(response.morningNotificationId, response.nightNotificationId).then(() => {
              props.navigation.setParams({ id, editable: 'false' });
              setLoading(false);
            });
          } else {
            props.navigation.setParams({ id, editable: 'false' });
            setLoading(false);
          }
        })
        .catch((e) => {
          props.setError(e.message);
          setLoading(false);
        })
        .finally(() => {
          const redirect = get(props.route, 'params.redirect');
          if (redirect) {
            props.navigation.reset({ index: 0, routes: [{ name: redirect }] });
          }
        });
    }
  };

  const onMarkTaken = () => {
    const alertButtons: AlertButton[] = [
      {
        text: appUtil.formatSentence(props.messages[MESSAGES.CANCEL]),
        style: 'cancel',
      },
    ];
    if (morningDoses) {
      alertButtons.push({
        text: appUtil.formatSentence(props.messages[MESSAGES.FOR_MORNING]),
        onPress: () => _onMarkTakenAction(morningDoses),
        style: 'destructive',
      });
    }
    if (nightDoses) {
      alertButtons.push({
        text: appUtil.formatSentence(props.messages[MESSAGES.FOR_NIGHT]),
        onPress: () => _onMarkTakenAction(nightDoses),
        style: 'destructive',
      });
    }
    Alert.alert(appUtil.formatSentence(props.messages[MESSAGES.ALERT]), undefined, alertButtons);
  };

  const onShowIngredients = () => {
    setIsIngredientVisible(true);
    setIsLoadingIngredient(true);
    medicationService.getMedicines(props.user.token).then((result: any[]) => {
      setDbIngredients(result);
      setFilteredDbIngredients(result);
      setIsLoadingIngredient(false);
    });
  };

  const onTotalDosesChange = (value: any) => {
    setTotalDoses(value);
    if (isNaN(Number(value))) {
      setTotalDosesError(appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER]));
    } else {
      setTotalDosesError(undefined);
    }
  };

  const onTotalRemainingDosesChange = (value: any) => {
    setTotalRemainingDoses(value);
    if (isNaN(Number(value))) {
      setTotalRemainingDosesError(appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER]));
    } else if (!isNil(totalDoses) && Number(value) > Number(totalDoses)) {
      setTotalRemainingDosesError(appUtil.formatSentence(`${props.messages[MESSAGES.MEDICATION_TOTAL_REMAINING_DOSES]} <= ${totalDoses}`));
    } else {
      setTotalRemainingDosesError(undefined);
    }
  };

  const onMorningDosesChange = (value: any) => {
    setMorningDoses(value);
    if (isNaN(Number(value))) {
      setMorningDosesError(appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER]));
    } else {
      setMorningDosesError(undefined);
    }
  };

  const onNightDosesChange = (value: any) => {
    setNightDoses(value);
    if (isNaN(Number(value))) {
      setNightDosesError(appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER]));
    } else {
      setNightDosesError(undefined);
    }
  };

  const onChangeTime = (type: string) => (event: any, date?: Date) => {
    if (type == 'morning') {
      setMorningReminder(date);
    } else if (type == 'night') {
      setNightReminder(date);
    } else {
      return undefined;
    }
  };

  const getMorningDt = (isMin?: boolean) => {
    const dt = new Date();
    if (isMin) {
      dt.setHours(5, 0, 0);
      return dt;
    }
    dt.setHours(9, 0, 0);
    return dt;
  };

  const getNightDt = (isMin?: boolean) => {
    const dt = new Date();
    if (isMin) {
      dt.setHours(17, 0, 0);
      return dt;
    }
    dt.setHours(23, 59, 59, 9999);
    return dt;
  };

  /* --------------------------------- EFFECTS -------------------------------- */

  useEffect(() => {
    props.navigation.setOptions({
      title: editable
        ? appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_SAVE])
        : appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_EDIT]),
      headerRight: editable
        ? undefined
        : () => (
            <TouchableOpacity
              onPress={() => setEditable(true)}
              style={{ marginHorizontal: MARGIN_HORIZONTAL }}>
              <Icon
                name='edit'
                type='material'
                color={COLORS.BLUE}
                size={24}
                containerStyle={{
                  borderRadius: 1000,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </TouchableOpacity>
          ),
    });
  }, [editable]);

  useFocusEffect(
    useCallback(() => {
      const _id = get(props.route, 'params.id');
      setEditable(String(get(props.route, 'params.editable', true)) === 'true');
      if (_id) {
        setLoading(true);
        medicationService
          .getSingleMedication(props.user.id, props.user.token, _id)
          .then((data: any) => {
            setId(data?.id);
            setIngredient(data?.ingredient);
            setUnit(data?.unit);
            setTotalDoses(toString(data?.totalDoses));
            setTotalRemainingDoses(toString(data?.totalRemainingDoses));
            setMorningDoses(toString(data?.morningDoses));
            setNightDoses(toString(data?.nightDoses));
            setMorningReminder(
              data?.morningReminder
                ? moment()
                    .local()
                    .set({
                      hour: moment(parseInt(String(data.morningReminder * 1000)))
                        .local()
                        .hour(),
                      minute: moment(parseInt(String(data.morningReminder * 1000)))
                        .local()
                        .minute(),
                      second: 0,
                    })
                    .toDate()
                : undefined,
            );
            setMorningNotificationId(data?.morningNotificationId);
            setNightReminder(
              data?.nightReminder
                ? moment()
                    .local()
                    .set({
                      hour: moment(parseInt(String(data.nightReminder * 1000)))
                        .local()
                        .hour(),
                      minute: moment(parseInt(String(data.nightReminder * 1000)))
                        .local()
                        .minute(),
                      second: 0,
                    })
                    .toDate()
                : undefined,
            );
            setNightNotificationId(data?.nightNotificationId);
          })
          .finally(() => setLoading(false));
      } else {
        clear();
      }
      return () => clear();
    }, [props.route?.params]),
  );

  useEffect(() => {
    if (showCancelDialog) {
      Alert.alert(
        appUtil.formatSentence(props.messages[MESSAGES.ALERT]),
        appUtil.formatSentence(props.messages[MESSAGES.DIARY_SAVE_CANCEL_DESCRIPTION]),
        [
          {
            text: appUtil.formatSentence(props.messages[MESSAGES.YES]),
            onPress: onCancel,
            style: 'destructive',
          },
          {
            text: appUtil.formatSentence(props.messages[MESSAGES.NO]),
            onPress: () => setShowCancelDialog(false),
            style: 'cancel',
          },
        ],
      );
    }
  }, [showCancelDialog]);

  useEffect(() => {
    if (showMessageDialog) {
      Alert.alert(appUtil.formatSentence(props.messages[MESSAGES.ALERT]), appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_SAVE_SUCCESS]), [
        {
          onPress: () => setShowMessageDialog(false),
          style: 'default',
        },
      ]);
    }
  }, [showMessageDialog]);

  useEffect(() => {
    if (!isNil(totalRemainingDoses) && (morningDoses || nightDoses)) {
      if (totalRemainingDoses <= 0) {
        setEndDate(appUtil.formatSentence(props.messages[MESSAGES.OUT_OF_MEDICINE]));
        setShowMedicineRunOutAlert('out-of');
      } else {
        const totalDosesPerDay = Number(morningDoses || 0) + Number(nightDoses || 0);
        const totalRemainingDays = Math.ceil(totalRemainingDoses / totalDosesPerDay);
        const ed = moment().local(true).add(totalRemainingDays, 'days').format('DD/MM/YYYY');
        setEndDate(`${ed} (${totalRemainingDays} ${props.messages[MESSAGES.DAYS_LEFT]})`);
        if (totalRemainingDays <= 3) {
          setShowMedicineRunOutAlert('near-out-of');
        }
      }
    } else {
      setEndDate(undefined);
    }
  }, [totalRemainingDoses, morningDoses, nightDoses]);

  useEffect(() => {
    if (showMedicineRunOutAlert === 'out-of' && !editable) {
      Alert.alert(
        appUtil.formatSentence(props.messages[MESSAGES.OUT_OF_MEDICINE]),
        appUtil.formatSentence(props.messages[MESSAGES.OUT_OF_MEDICINE_DESCRIPTION]),
        [
          {
            onPress: () => setShowMedicineRunOutAlert(undefined),
            style: 'default',
          },
        ],
        {
          cancelable: true,
        },
      );
    } else if (showMedicineRunOutAlert === 'near-out-of' && !editable) {
      Alert.alert(
        appUtil.formatSentence(props.messages[MESSAGES.NEAR_OUT_OF_MEDICINE_TITLE]),
        appUtil.formatSentence(props.messages[MESSAGES.NEAR_OUT_OF_MEDICINE_DESCRIPTION]),
        [
          {
            onPress: () => setShowMedicineRunOutAlert(undefined),
            style: 'default',
          },
        ],
        {
          cancelable: true,
        },
      );
    }
  }, [showMedicineRunOutAlert]);

  const onRefresh = () => {
    setRefreshing(true);
    props.navigation.setParams({ id, editable });
    setRefreshing(false);
  };

  return (
    <SafeAreaView style={styles.page}>
      {loading ? (
        <LoadingPage withImage />
      ) : (
        <KeyboardAwareScrollView
          contentContainerStyle={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }>
          {endDate && (
            <View style={{ ...styles.content, paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}>
              <Input
                label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_END_DATE_DESCRIPTION])}
                value={endDate}
                leftIcon={{
                  name: 'calendar-range',
                  type: 'material-community',
                }}
                inputStyle={{ fontSize: FONT_SIZE }}
                labelStyle={{ fontSize: FONT_LABEL_SIZE, color: COLORS.BLACK }}
                renderErrorMessage={false}
                containerStyle={{
                  flexGrow: 0,
                  flexShrink: 1,
                  backgroundColor: COLORS.LIGHT_YELLOW,
                  borderRadius: BORDER_RADIUS,
                  paddingVertical: PADDING,
                  paddingHorizontal: 16,
                }}
                editable={false}
              />
            </View>
          )}
          {!editable && (
            <View style={{ ...styles.gridRow, marginHorizontal: MARGIN_HORIZONTAL, marginBottom: MARGIN_VERTICAL }}>
              <BaseButton
                type='solid'
                color={COLORS.WHITE}
                backgroundColor={COLORS.GREEN}
                onPress={onMarkTaken}
                title={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_MARK_TAKEN])}
                titleStyle={{ fontWeight: 'bold' }}
                disabled={totalRemainingDoses <= 0}
              />
            </View>
          )}
          <View style={styles.content}>
            <Input
              label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_INGREDIENT])}
              placeholder={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_INGREDIENT_PLACEHOLDER])}
              value={ingredient && appUtil.formatSentence(ingredient)}
              inputStyle={{ fontSize: FONT_SIZE, flexWrap: 'wrap' }}
              labelStyle={{ fontSize: FONT_LABEL_SIZE }}
              editable={false}
              rightIcon={
                editable ? (
                  ingredient ? (
                    <TouchableOpacity
                      onPress={() => {
                        setIngredient(undefined);
                        setUnit(undefined);
                      }}>
                      <Icon
                        name='cancel'
                        type='material-community'
                      />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity onPress={onShowIngredients}>
                      <Icon
                        name='square-edit-outline'
                        type='material-community'
                      />
                    </TouchableOpacity>
                  )
                ) : undefined
              }
              multiline
            />
            <OverlayDialog
              isVisible={isIngredientVisible}
              setIsVisible={setIsIngredientVisible}
              style={styles.ingredientOverlay}>
              <Input
                placeholder={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_INGREDIENT])}
                editable={true}
                onChangeText={(value) => {
                  let _dbIngredients = dbIngredients;
                  if (value) {
                    _dbIngredients = filter(dbIngredients, (obj) => {
                      const reg = new RegExp(value, 'i');
                      return reg.test(obj.ingredient);
                    });
                  }
                  setFilteredDbIngredients(_dbIngredients);
                }}
                leftIcon={{
                  name: 'text-box-search',
                  type: 'material-community',
                }}
                inputStyle={{ fontSize: FONT_SIZE, marginBottom: 0 }}
                labelStyle={{ fontSize: FONT_LABEL_SIZE }}
                renderErrorMessage={false}
              />
              {isLoadingIngredient ? (
                <MiniLoading />
              ) : (
                filteredDbIngredients &&
                filteredDbIngredients.length > 0 && (
                  <ScrollView contentContainerStyle={{ paddingHorizontal: PADDING, backgroundColor: 'white' }}>
                    {filteredDbIngredients.map((item: any, index: number) => {
                      return (
                        <Checkbox
                          key={index}
                          title={appUtil.formatSentence(get(item, 'ingredient'))}
                          checked={ingredient === get(item, 'ingredient')}
                          onPress={() => {
                            setIngredient(get(item, 'ingredient'));
                            setUnit(get(item, 'unit'));
                          }}
                          color={COLORS.BLUE}
                          containerStyle={{
                            marginLeft: 0,
                            marginRight: 0,
                            borderWidth: 0,
                            borderColor: COLORS.CHROME_GREY,
                            paddingVertical: 0,
                            paddingHorizontal: 0,
                          }}
                        />
                      );
                    })}
                  </ScrollView>
                )
              )}
              <BaseButton
                type='solid'
                color={COLORS.BLUE}
                backgroundColor='white'
                containerStyle={{
                  borderTopWidth: 1,
                  borderTopColor: COLORS.CHROME_GREY,
                  borderRadius: 0,
                  flexBasis: undefined,
                  flexGrow: undefined,
                  flexShrink: undefined,
                }}
                buttonStyle={{ borderRadius: 0, flex: 0 }}
                onPress={() => setIsIngredientVisible(false)}
                title={appUtil.formatSentence(props.messages[MESSAGES.DONE])}
              />
            </OverlayDialog>
            <View style={styles.horizontalInputs}>
              <Input
                label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_TOTAL_DOSES])}
                placeholder={appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER])}
                onChangeText={onTotalDosesChange}
                value={totalDoses}
                inputStyle={{ fontSize: FONT_SIZE }}
                labelStyle={{ fontSize: FONT_LABEL_SIZE }}
                containerStyle={{ flexGrow: 0, flexShrink: 1 }}
                errorMessage={totalDosesError}
                keyboardType='numeric'
                editable={editable}
              />
              {ingredient ? (
                <Text
                  numberOfLines={1}
                  style={styles.unit}>
                  {appUtil.formatSentence(get(props.messages, toLower(unit), unit))}
                </Text>
              ) : null}
            </View>
            <View style={styles.horizontalInputs}>
              <Input
                label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_TOTAL_REMAINING_DOSES])}
                placeholder={appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER])}
                onChangeText={onTotalRemainingDosesChange}
                value={totalRemainingDoses}
                inputStyle={{ fontSize: FONT_SIZE }}
                labelStyle={{ fontSize: FONT_LABEL_SIZE }}
                containerStyle={{ flexGrow: 0, flexShrink: 1 }}
                errorMessage={totalRemainingDosesError}
                keyboardType='numeric'
                editable={editable}
              />
              {ingredient ? (
                <Text
                  numberOfLines={1}
                  style={styles.unit}>
                  {appUtil.formatSentence(get(props.messages, toLower(unit), unit))}
                </Text>
              ) : null}
            </View>
            <View style={styles.horizontalInputs}>
              <Input
                label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_MORNING_DOSES])}
                placeholder={appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER])}
                value={morningDoses}
                onChangeText={onMorningDosesChange}
                inputStyle={{ fontSize: FONT_SIZE }}
                labelStyle={{ fontSize: FONT_LABEL_SIZE }}
                containerStyle={{ flexGrow: 0, flexShrink: 1 }}
                errorMessage={morningDosesError}
                keyboardType='numeric'
                editable={editable}
              />
              {ingredient ? (
                <Text
                  numberOfLines={1}
                  style={styles.unit}>
                  {appUtil.formatSentence(get(props.messages, toLower(unit), unit))}
                </Text>
              ) : null}
            </View>
            <DatePicker
              messages={props.messages}
              label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_MORNING_REMINDER])}
              placeholder={appUtil.formatSentence(props.messages[MESSAGES.CHOOSE_TIME])}
              value={morningReminder}
              defaultValue={getMorningDt(true)}
              onChangeValue={onChangeTime('morning')}
              showDatePicker={showMorningReminder}
              setShowDatePicker={setShowMorningReminder}
              mode='time'
              minDateTime={getMorningDt(true)}
              maxDateTime={getMorningDt(false)}
              editable={editable}
            />
            <View style={styles.horizontalInputs}>
              <Input
                label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_NIGHT_DOSES])}
                placeholder={appUtil.formatSentence(props.messages[MESSAGES.ENTER_NUMBER])}
                value={nightDoses}
                onChangeText={onNightDosesChange}
                inputStyle={{ fontSize: FONT_SIZE }}
                labelStyle={{ fontSize: FONT_LABEL_SIZE }}
                containerStyle={{ flexGrow: 0, flexShrink: 1 }}
                errorMessage={nightDosesError}
                keyboardType='numeric'
                editable={editable}
              />
              {ingredient ? (
                <Text
                  numberOfLines={1}
                  style={styles.unit}>
                  {appUtil.formatSentence(get(props.messages, toLower(unit), unit))}
                </Text>
              ) : null}
            </View>
            <DatePicker
              messages={props.messages}
              label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_NIGHT_REMINDER])}
              placeholder={appUtil.formatSentence(props.messages[MESSAGES.CHOOSE_TIME])}
              value={nightReminder}
              defaultValue={getNightDt(true)}
              onChangeValue={onChangeTime('night')}
              showDatePicker={showNightReminder}
              setShowDatePicker={setShowNightReminder}
              mode='time'
              minDateTime={getNightDt(true)}
              maxDateTime={getNightDt(false)}
              editable={editable}
            />
          </View>
          {editable && (
            <View style={{ ...styles.gridRow, marginHorizontal: MARGIN_HORIZONTAL, marginBottom: MARGIN_VERTICAL }}>
              <BaseButton
                type='clear'
                backgroundColor='white'
                color={COLORS.BLACK}
                onPress={() => setShowCancelDialog(true)}
                containerStyle={{ marginRight: MARGIN_HORIZONTAL }}
                title={appUtil.formatSentence(props.messages[MESSAGES.CANCEL])}
              />
              <BaseButton
                type='solid'
                color={COLORS.WHITE}
                backgroundColor={COLORS.BLUE}
                onPress={onSave}
                icon={{
                  name: 'content-save-all',
                  type: 'material-community',
                  size: FONT_SIZE,
                  color: COLORS.WHITE,
                }}
                containerStyle={{ flexShrink: 0.5 }}
                title={appUtil.formatSentence(props.messages[MESSAGES.SAVE])}
                titleStyle={{ fontWeight: 'bold' }}
              />
            </View>
          )}
          <View style={styles.content}>
            <Image
              source={props.settings.locale.toLowerCase() === 'vi-vn' ? viMedication : enMedication}
              style={styles.image}
              resizeMode='stretch'
            />
          </View>
        </KeyboardAwareScrollView>
      )}
    </SafeAreaView>
  );
}

interface MedicationListPageProps {
  messages: Messages;
  settings: Settings;
  user: User;
  route: any;
  setError: Dispatch<SetStateAction<string>>;
  navigation: NavigationProp<any>;
}

export function MedicationListPage(props: MedicationListPageProps) {
  const [list, setList]: [any[], any] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [footerLoading, setFooterLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const [deletePayload, setDeletePayload] = useState(undefined);

  const onDelete = async (data: any) => {
    const { id, morningNotificationId, nightNotificationId, index } = data;
    await medicationService.deleteMedication(props.user.id, props.user.token, id);
    if (morningNotificationId) {
      await Notifications.cancelScheduledNotificationAsync(morningNotificationId).catch((e) => console.error(e));
    }
    if (nightNotificationId) {
      await Notifications.cancelScheduledNotificationAsync(nightNotificationId).catch((e) => console.error(e));
    }
    setList(list.filter((elem, i) => i !== index));
    setDeletePayload(undefined);
  };

  useEffect(() => {
    if (deletePayload) {
      Alert.alert(
        appUtil.formatSentence(props.messages[MESSAGES.ALERT]),
        appUtil.formatSentence(props.messages[MESSAGES.DELETE_CONFIRM_DESCRIPTION]),
        [
          {
            text: appUtil.formatSentence(props.messages[MESSAGES.YES]),
            onPress: () => onDelete(deletePayload),
            style: 'destructive',
          },
          {
            text: appUtil.formatSentence(props.messages[MESSAGES.NO]),
            onPress: () => setDeletePayload(undefined),
            style: 'cancel',
          },
        ],
      );
    }
  }, [deletePayload]);

  const _getEndDate = (totalRemainingDoses: number, morningDoses?: number, nightDoses?: number) => {
    if (!isNil(totalRemainingDoses) && (morningDoses || nightDoses)) {
      if (totalRemainingDoses <= 0) {
        return appUtil.formatSentence(props.messages[MESSAGES.OUT_OF_MEDICINE]);
      }
      const totalDosesPerDay = Number(morningDoses || 0) + Number(nightDoses || 0);
      const totalRemainingDays = Math.ceil(totalRemainingDoses / totalDosesPerDay);
      const ed = moment().local(true).add(totalRemainingDays, 'days').format('DD/MM/YYYY');
      return `${ed} (${totalRemainingDays} ${props.messages[MESSAGES.DAYS_LEFT]})`;
    }
    return undefined;
  };

  const renderItem = ({ item, index }: any) => {
    const {
      ingredient,
      unit,
      totalDoses,
      totalRemainingDoses,
      morningDoses,
      morningReminder: mR,
      nightDoses,
      nightReminder: nR,
      createdAt: cAt,
    } = item;
    const morningReminder = parseInt(String(mR * 1000));
    const nightReminder = parseInt(String(nR * 1000));
    const createdAt = parseInt(String(cAt * 1000));
    return (
      <View
        style={styles.listItem}
        key={index}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: MARGIN_VERTICAL,
            borderBottomColor: COLORS.CHROME_GREY,
            borderBottomWidth: 1,
            paddingBottom: MARGIN_VERTICAL,
          }}>
          <View style={{ ...styles.listItemTitle, paddingHorizontal: 0 }}>
            <View style={styles.listItemTitleRow}>
              <Text style={{ fontSize: FONT_SIZE, fontWeight: 'bold', textTransform: 'capitalize', padding: 0, margin: 0 }}>
                {moment(createdAt)
                  .local()
                  .locale(props.settings.locale.includes('vi') ? 'vi' : 'en-gb')
                  .format('ddd, DD MMM, YYYY')}
              </Text>
            </View>
            <View style={styles.listItemTitleRow}>
              <Icon
                name='clock'
                size={16}
                type='material-community'
                containerStyle={{ marginRight: 4 }}
              />
              <Text style={{ fontSize: FONT_SIZE, padding: 0, margin: 0 }}>{moment(createdAt).local().format('HH:MM')}</Text>
            </View>
          </View>
          <TouchableOpacity
            onPress={() =>
              setDeletePayload({
                index,
                ...item,
                morningReminder,
                nightReminder,
                createdAt,
              })
            }>
            <Icon
              name='delete-forever'
              type='material'
              size={24}
              color={COLORS.WHITE}
              containerStyle={{
                borderRadius: 1000,
                backgroundColor: COLORS.RED,
                padding: 10,
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: MARGIN_HORIZONTAL,
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              props.navigation.navigate('MedicationSave', {
                ...item,
                morningReminder,
                nightReminder,
                createdAt,
                editable: false,
              })
            }>
            <Icon
              name='open-in-new'
              type='material-community'
              size={24}
              color={COLORS.WHITE}
              containerStyle={{
                borderRadius: 1000,
                backgroundColor: COLORS.GREEN,
                padding: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          </TouchableOpacity>
        </View>
        <View style={{ ...styles.content, ...styles.noMargin }}>
          <Input
            label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_END_DATE_DESCRIPTION])}
            value={_getEndDate(totalRemainingDoses, morningDoses, nightDoses)}
            leftIcon={{
              name: 'calendar-range',
              type: 'material-community',
            }}
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE, color: COLORS.BLACK }}
            renderErrorMessage={false}
            containerStyle={{
              flexGrow: 0,
              flexShrink: 1,
              backgroundColor: COLORS.LIGHT_YELLOW,
              borderRadius: BORDER_RADIUS,
              paddingVertical: PADDING,
              marginBottom: MARGIN_VERTICAL,
            }}
            editable={false}
          />
          <Input
            label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_INGREDIENT])}
            value={ingredient && appUtil.formatSentence(ingredient)}
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
            editable={false}
            multiline
            numberOfLines={2}
          />
          <View style={styles.horizontalInputs}>
            <Input
              label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_TOTAL_DOSES])}
              value={!isNil(totalDoses) ? String(totalDoses) : undefined}
              inputStyle={{ fontSize: FONT_SIZE }}
              labelStyle={{ fontSize: FONT_LABEL_SIZE }}
              containerStyle={{ flexGrow: 0, flexShrink: 1 }}
              editable={false}
            />
            {unit && (
              <Text
                numberOfLines={1}
                style={styles.unit}>
                {appUtil.formatSentence(get(props.messages, toLower(unit), unit))}
              </Text>
            )}
          </View>
          <View style={styles.horizontalInputs}>
            <Input
              label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_TOTAL_REMAINING_DOSES])}
              value={!isNil(totalRemainingDoses) ? String(totalRemainingDoses) : undefined}
              inputStyle={{ fontSize: FONT_SIZE }}
              labelStyle={{ fontSize: FONT_LABEL_SIZE }}
              containerStyle={{ flexGrow: 0, flexShrink: 1 }}
              editable={false}
            />
            {unit && (
              <Text
                numberOfLines={1}
                style={styles.unit}>
                {appUtil.formatSentence(get(props.messages, toLower(unit), unit))}
              </Text>
            )}
          </View>
          <View style={styles.horizontalInputs}>
            <Input
              label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_MORNING_DOSES])}
              value={!isNil(morningDoses) ? String(morningDoses) : undefined}
              inputStyle={{ fontSize: FONT_SIZE }}
              labelStyle={{ fontSize: FONT_LABEL_SIZE }}
              containerStyle={{ flexGrow: 0, flexShrink: 1 }}
              editable={false}
            />
            {unit && (
              <Text
                numberOfLines={1}
                style={styles.unit}>
                {appUtil.formatSentence(get(props.messages, toLower(unit), unit))}
              </Text>
            )}
          </View>
          <Input
            label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_MORNING_REMINDER])}
            value={morningReminder ? moment(morningReminder).local().format('HH:mm') : ''}
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
            editable={false}
          />
          <View style={styles.horizontalInputs}>
            <Input
              label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_NIGHT_DOSES])}
              value={!isNil(nightDoses) ? String(nightDoses) : undefined}
              inputStyle={{ fontSize: FONT_SIZE }}
              labelStyle={{ fontSize: FONT_LABEL_SIZE }}
              containerStyle={{ flexGrow: 0, flexShrink: 1 }}
              editable={false}
            />
            {unit && (
              <Text
                numberOfLines={1}
                style={styles.unit}>
                {appUtil.formatSentence(get(props.messages, toLower(unit), unit))}
              </Text>
            )}
          </View>
          <Input
            label={appUtil.formatSentence(props.messages[MESSAGES.MEDICATION_NIGHT_REMINDER])}
            value={nightReminder ? moment(nightReminder).local().format('HH:mm') : ''}
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
            renderErrorMessage={false}
            editable={false}
          />
        </View>
      </View>
    );
  };

  const renderFooter = () => {
    return footerLoading ? (
      <View style={styles.listFooter}>
        <ActivityIndicator
          size='small'
          color={COLORS.BLUE}
        />
      </View>
    ) : null;
  };

  const keyExtractor = (item: any, index: number) => {
    return index.toString();
  };

  const getData = async (newPage: number) => {
    if (newPage == 0) setIsLoading(true);
    const res = await medicationService.getMedications(props.user.id, props.user.token, newPage, 3).catch((e) => console.error(e));
    if (newPage === 0) {
      setList(get(res, 'content', []));
      setIsLoading(false);
    } else {
      setList([...list, ...get(res, 'content', [])]);
    }
    setTotalPage(get(res, 'totalPages', 0));
    setPage(newPage);
  };

  const onEndReached = async () => {
    const nextPage = page + 1;
    if (nextPage >= totalPage) return;
    setFooterLoading(true);
    await getData(nextPage);
    setFooterLoading(false);
  };

  useFocusEffect(
    useCallback(() => {
      if (get(props.route, 'params.refresh')) {
        getData(0);
      }
    }, [props.route.params]),
  );

  useEffect(() => {
    getData(0);
  }, []);

  return isLoading ? (
    <LoadingPage withImage />
  ) : (
    <SafeAreaView style={styles.page}>
      <FlatList
        data={list}
        refreshing={refreshing}
        onRefresh={() => getData(0).then(() => setRefreshing(false))}
        initialNumToRender={3}
        keyExtractor={keyExtractor}
        contentContainerStyle={styles.scrollView}
        renderItem={renderItem}
        ListFooterComponent={renderFooter}
        onEndReached={onEndReached}
        onEndReachedThreshold={0}
        ListEmptyComponent={<NoDataPage {...props} />}
      />
    </SafeAreaView>
  );
}

interface MedicationPageProps {
  navigation: NavigationProp<any>;
  messages: Messages;
}

function MedicationPage(props: MedicationPageProps) {
  return (
    <SafeAreaView style={styles.page}>
      <ScrollView style={styles.scrollView}>
        <Card
          image={medicationSaveIcon}
          imageStyle={{ height: 32, width: 32 }}
          name={props.messages[MESSAGES.MEDICATION_SAVE]}
          color={COLORS.BLUE}
          headerStyle={{ flexDirection: 'column' }}
          titleStyle={{ fontSize: FONT_TITLE_SIZE }}
          descriptionStyle={{ color: 'black', textAlign: 'center' }}
          description={props.messages[MESSAGES.MEDICATION_SAVE_DESCRIPTION]}
          onPress={() => props.navigation.navigate('MedicationSave')}
        />
        <Card
          image={medicationOpenIcon}
          imageStyle={{ height: 32, width: 32 }}
          name={props.messages[MESSAGES.MEDICATION_LIST]}
          color={COLORS.BLUE}
          headerStyle={{ flexDirection: 'column' }}
          titleStyle={{ fontSize: FONT_TITLE_SIZE }}
          descriptionStyle={{ color: 'black', textAlign: 'center' }}
          description={props.messages[MESSAGES.MEDICATION_LIST_DESCRIPTION]}
          onPress={() => props.navigation.navigate('MedicationList')}
        />
      </ScrollView>
    </SafeAreaView>
  );
}

export default MedicationPage;

/* eslint-disable react-native/no-color-literals */
import COLORS from '#constants/colors';
import { BORDER_RADIUS, FONT_SIZE, FONT_TITLE_SIZE, GENERAL_STYLES, MARGIN_HORIZONTAL, MARGIN_VERTICAL } from '#constants/styles';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  ...GENERAL_STYLES,
  actionPage: {
    alignItems: 'center',
    backgroundColor: 'white',
    flex: 1,
    justifyContent: 'flex-start',
  },
  bulletPoint: {
    alignItems: 'center',
    borderColor: COLORS.GREY_100,
    borderRadius: BORDER_RADIUS,
    borderWidth: 0,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginVertical: 2,
    paddingVertical: 8,
  },
  countDownText: {
    fontSize: 28,
    fontWeight: 'normal',
  },
  countDownTimer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    marginVertical: MARGIN_VERTICAL,
  },
  progressBar: { margin: MARGIN_HORIZONTAL, marginBottom: MARGIN_VERTICAL },
  section: {
    fontSize: FONT_TITLE_SIZE,
    fontWeight: 'bold',
    marginBottom: MARGIN_VERTICAL,
    marginHorizontal: 32,
    marginVertical: MARGIN_VERTICAL,
    overflow: 'hidden',
    textAlign: 'center',
  },
  text: {
    color: 'black',
    flex: 1,
    flexWrap: 'wrap',
    fontSize: FONT_SIZE,
    paddingHorizontal: 8,
  },
  title: {
    fontSize: FONT_TITLE_SIZE,
    fontWeight: 'bold',
    marginVertical: MARGIN_VERTICAL,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  video: {
    alignSelf: 'center',
    height: '100%',
    width: '100%',
  },
  videoContainer: {
    alignItems: 'stretch',
    backgroundColor: 'white',
    borderRadius: BORDER_RADIUS,
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'center',
    marginBottom: MARGIN_VERTICAL,
    marginHorizontal: MARGIN_HORIZONTAL,
    overflow: 'hidden',
    width: 'auto',
  },
});

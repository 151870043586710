/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  border: {
    borderColor: '#86939e',
    borderWidth: 0.5,
    width: '100%',
  },
  inputContainerStyle: {
    paddingBottom: 20,
    paddingHorizontal: 10,
  },
  inputStyle: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  label: {
    color: '#86939e',
    fontSize: 12,
    fontWeight: 'bold',
  },
});

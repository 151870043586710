/* eslint-disable react-native/no-inline-styles,react-native/no-color-literals */
import React from 'react';
import styles from '#pages/styles/home.page';
import { Platform, SafeAreaView, ScrollView, View } from 'react-native';
import { MESSAGES } from '#constants/index';
import COLORS from '#constants/colors';
import { Messages } from '#data/extras';
import { NavigationProp } from '@react-navigation/core';
import { User } from '#data/user';
import Card from '#components/buttons/card';
import medicalBookIcon from '#assets/medical-book.png';
import weatherIcon from '#assets/weather.png';
import educationIcon from '#assets/education.png';
import sirenIcon from '#assets/siren.png';
import diaryIcon from '#assets/diary.png';
import catHeartsIcon from '#assets/cat-hearts.png';
import settingsIcon from '#assets/settings.png';
import { FONT_SIZE, PADDING } from '#constants/styles';

interface HomePageProps {
  navigation: NavigationProp<any>;
  user?: User;
  messages: Messages;
}

function HomePage(props: HomePageProps) {
  return (
    <SafeAreaView style={styles.page}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        {Platform.OS != 'web' &&
          (props.user ? null : (
            <View style={styles.gridRow}>
              <Card
                image={catHeartsIcon}
                imageStyle={{ height: 36, width: 36 }}
                name={props.messages[MESSAGES.BANNER_FOR_REGISTRATION]}
                color={COLORS.BLACK}
                containerStyle={{
                  ...styles.card,
                  backgroundColor: COLORS.LIGHT_YELLOW,
                }}
                headerStyle={{ ...styles.cardHeader, flexDirection: 'row', marginBottom: 4 }}
                titleStyle={{
                  ...styles.cardTitle,
                  flexShrink: 1,
                  fontSize: FONT_SIZE,
                  textTransform: undefined,
                  textAlign: 'left',
                  marginHorizontal: PADDING,
                }}
                descriptionStyle={{ ...styles.cardDescription, flexShrink: 1, textAlign: 'left' }}
                description={props.messages[MESSAGES.BANNER_FOR_REGISTRATION_DESCRIPTION]}
                onPress={() => props.navigation.navigate('LoginNav')}
                titleFullLines
                descriptionFullLines
              />
            </View>
          ))}
        <View style={styles.gridRow}>
          <Card
            image={sirenIcon}
            imageStyle={{ height: 56, width: 56 }}
            name={props.messages[MESSAGES.EMERGENCY]}
            color={COLORS.ORANGE}
            iconSize={32}
            containerStyle={{ ...styles.card, minHeight: 150 }}
            headerStyle={styles.cardHeader}
            titleStyle={{ ...styles.cardTitle }}
            descriptionStyle={styles.cardDescription}
            description={props.messages[MESSAGES.HOME_EMERGENCY_DESCRIPTION]}
            onPress={() => props.navigation.navigate('Emergency')}
          />
        </View>
        <View style={styles.gridRow}>
          <Card
            image={weatherIcon}
            imageStyle={{ height: 32, width: 32 }}
            name={props.messages[MESSAGES.WEATHER_WARNINGS]}
            color={COLORS.BLUE}
            containerStyle={{ ...styles.card, flexShrink: 0.75 }}
            titleStyle={styles.cardTitle}
            headerStyle={styles.cardHeader}
            descriptionStyle={styles.cardDescription}
            description={props.messages[MESSAGES.HOME_WEATHER_WARNINGS_DESCRIPTION]}
            onPress={() => props.navigation.navigate('WeatherWarnings')}
          />
          <Card
            image={educationIcon}
            imageStyle={{ height: 32, width: 32 }}
            name={props.messages[MESSAGES.EDUCATION]}
            color={COLORS.BLUE}
            containerStyle={{ ...styles.card }}
            headerStyle={styles.cardHeader}
            titleStyle={styles.cardTitle}
            descriptionStyle={styles.cardDescription}
            description={props.messages[MESSAGES.HOME_EDUCATION_DESCRIPTION]}
            onPress={() => props.navigation.navigate('Education')}
          />
        </View>
        {props.user && (
          <View style={styles.gridRow}>
            <Card
              image={medicalBookIcon}
              imageStyle={{ height: 32, width: 32 }}
              name={props.messages[MESSAGES.MEDICATION]}
              color={COLORS.BLUE}
              containerStyle={styles.card}
              headerStyle={styles.cardHeader}
              titleStyle={styles.cardTitle}
              descriptionStyle={styles.cardDescription}
              description={props.messages[MESSAGES.MEDICATION_LIST_DESCRIPTION]}
              onPress={() => props.navigation.navigate('Medication')}
            />
            <Card
              image={diaryIcon}
              imageStyle={{ height: 32, width: 32 }}
              name={props.messages[MESSAGES.ASTHMA_DIARY]}
              color={COLORS.BLUE}
              containerStyle={{ ...styles.card }}
              headerStyle={styles.cardHeader}
              titleStyle={styles.cardTitle}
              descriptionStyle={styles.cardDescription}
              description={props.messages[MESSAGES.HOME_ASTHMA_DIARY_DESCRIPTION]}
              onPress={() => props.navigation.navigate('Diary')}
            />
          </View>
        )}
        <View style={styles.gridRow}>
          <Card
            image={settingsIcon}
            imageStyle={{ height: 32, width: 32 }}
            name={props.messages[MESSAGES.SETTINGS]}
            color={COLORS.GRAY}
            containerStyle={styles.card}
            headerStyle={styles.cardHeader}
            titleStyle={{ ...styles.cardTitle }}
            descriptionStyle={styles.cardDescription}
            description={props.messages[MESSAGES.HOME_SETTINGS_DESCRIPTION]}
            onPress={() => props.navigation.navigate('Settings')}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export default HomePage;

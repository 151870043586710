export interface Profile {
  name?: string;
  email?: string;
  dob?: Date;
  gender?: string;
  activated?: boolean;
  subscribing?: boolean;
  role?: string;
}

export interface ProfileWithPassword extends Profile {
  password?: string;
}

export const DEFAULT_PROFILE: Profile = {
  name: '',
  email: '',
  dob: undefined,
  gender: '',
};

export interface User {
  id?: string;
  token?: string;
  profile?: Profile;
}

export const DEFAULT_USER: User | undefined = undefined;

export default {};

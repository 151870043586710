/* eslint-disable react-native/no-inline-styles */
import React, { Dispatch, SetStateAction } from 'react';
import { Overlay } from '@rneui/themed';
import styles from './styles/confirm.dialog';

interface OverlayDialogProps {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  style?: any;
  children: any;
}

function OverlayDialog(props: OverlayDialogProps) {
  return (
    <Overlay
      isVisible={props.isVisible}
      onBackdropPress={() => props.setIsVisible(false)}
      fullScreen={false}
      overlayStyle={{ ...styles.container, ...props.style }}>
      {props.children}
    </Overlay>
  );
}

export default OverlayDialog;

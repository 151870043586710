/* eslint-disable react-native/no-inline-styles */
import { MESSAGES } from '#constants/index';
import kaapIcon from '#assets/kaap-notification-icon.png';
import COLORS from '#constants/colors';
import { stackNavigationScreenOptions } from '#constants/options';
import { MARGIN_HORIZONTAL, PADDING } from '#constants/styles';
import { ActionPlan, Messages } from '#data/extras';
import { Settings } from '#data/settings';
import { User } from '#data/user';
import ChangePasswordPage from '#pages/change-password.page';
import ProfilePage from '#pages/profile.page';
import { createNativeStackNavigator as createStackNavigator } from '@react-navigation/native-stack';
import { Icon } from '@rneui/themed';
import appUtil from '#utils/app.util';
import React, { Dispatch, SetStateAction } from 'react';
import { Image, TouchableOpacity } from 'react-native';

const Stack = createStackNavigator();

interface ProfileNavigationProps {
  user?: User;
  onChangeUser(user?: User): Promise<User | undefined>;
  settings: Settings;
  onChangeSettings(settings?: Settings, reload?: boolean, callback?: () => void): void;
  messages: Messages;
  actionPlan: ActionPlan;
  setShowLogoutDialog: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function ProfileNavigation(profileProps: ProfileNavigationProps) {
  return (
    <Stack.Navigator
      initialRouteName='Profile'
      screenOptions={{
        ...stackNavigationScreenOptions,
      }}>
      <Stack.Screen
        name='Profile'
        options={{
          title: appUtil.formatSentence(profileProps.messages[MESSAGES.PROFILE]),
          headerLeft: () => (
            <Image
              source={kaapIcon}
              style={{ height: 36, width: 36, paddingHorizontal: PADDING, marginHorizontal: MARGIN_HORIZONTAL }}
            />
          ),
          headerRight: () => (
            <TouchableOpacity
              style={{ marginHorizontal: MARGIN_HORIZONTAL }}
              onPress={() => profileProps.setShowLogoutDialog(true)}>
              <Icon
                name='logout'
                type='material'
                color={COLORS.BLACK}
                size={24}
                containerStyle={{
                  borderRadius: 1000,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </TouchableOpacity>
          ),
        }}>
        {(props: any) => (
          <ProfilePage
            {...profileProps}
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name='ChangePassword'
        options={{ title: appUtil.formatSentence(profileProps.messages[MESSAGES.CHANGE_PASSWORD]) }}>
        {(props: any) => (
          <ChangePasswordPage
            {...profileProps}
            {...props}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
}

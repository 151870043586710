/* eslint-disable react-native/no-inline-styles */
import { Messages } from '#data/extras';
import React from 'react';
import { Platform, SafeAreaView, ScrollView, View } from 'react-native';
import appUtil from '#utils/app.util';
import { Text } from '@rneui/themed';
import styles from './styles/education.page';
import { MESSAGES } from '#constants/index';
import COLORS from '#constants/colors';
import BaseButton from '#components/buttons';
import * as Linking from 'expo-linking';
import { PADDING } from '#constants/styles';
import { NavigationProp } from '@react-navigation/core';

const PAGE_ID = '105745628343313';
const FB_APP_URL = Platform.select({ ios: `fb://profile/${PAGE_ID}`, android: `fb://page/${PAGE_ID}` }) || '';
const FB_URL = `https://www.fb.com/${PAGE_ID}`;
const WEBSITE_URL = 'https://www.kidasthmaactionplan.com';
const GINA_URL = 'https://ginasthma.org/wp-content/uploads/2022/07/GINA-Main-Report-2022-FINAL-22-07-01-WMS.pdf';

interface EducationPageProps {
  messages: Messages;
  navigation: NavigationProp<any>;
}

function EducationPage(props: EducationPageProps) {
  const openFb = async () => {
    try {
      if (Platform.OS == 'web') {
        window.open(FB_URL, '_blank');
      } else {
        try {
          await Linking.openURL(FB_APP_URL);
        } catch (e) {
          await Linking.openURL(FB_URL);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const openWebsite = async () => {
    try {
      if (Platform.OS == 'web') {
        window.open(WEBSITE_URL, '_blank');
      } else {
        await Linking.openURL(WEBSITE_URL);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const openGina = async () => {
    try {
      if (Platform.OS == 'web') {
        window.open(GINA_URL, '_blank');
      } else {
        await Linking.openURL(GINA_URL);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <SafeAreaView style={styles.page}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.card}>
          <Text style={styles.description}>{appUtil.formatSentence(props.messages[MESSAGES.EDUCATION_ASTHMA_INFO])}</Text>
          <BaseButton
            type='clear'
            color={COLORS.BLUE}
            icon={{
              name: 'file-pdf-box',
              color: COLORS.BLUE,
              size: 24,
              type: 'material-community',
            }}
            containerStyle={{ flex: 1, marginBottom: 0, marginTop: PADDING, marginHorizontal: 0 }}
            backgroundColor='white'
            buttonStyle={{ padding: 0 }}
            title={props.messages[MESSAGES.OPEN_GINA]}
            titleStyle={{ textAlign: 'left', flex: 1 }}
            onPress={openGina}
          />
        </View>
        <View style={styles.card}>
          <Text style={styles.description}>{appUtil.formatSentence(props.messages[MESSAGES.EDUCATION_FB])}</Text>
          <BaseButton
            type='clear'
            color={COLORS.BLUE}
            icon={{
              name: 'facebook',
              color: COLORS.BLUE,
              size: 24,
              type: 'material-community',
            }}
            containerStyle={{ flex: 1, marginBottom: 0, marginTop: PADDING, marginHorizontal: 0 }}
            backgroundColor='white'
            buttonStyle={{ padding: 0 }}
            title={props.messages[MESSAGES.OPEN_FB]}
            titleStyle={{ textAlign: 'left', flex: 1 }}
            onPress={openFb}
          />
        </View>
        <View style={styles.card}>
          <Text style={styles.description}>{appUtil.formatSentence(props.messages[MESSAGES.EDUCATION_WEBSITE])}</Text>
          <BaseButton
            type='solid'
            color={COLORS.BLUE}
            icon={{
              name: 'open-in-browser',
              color: COLORS.BLUE,
              size: 24,
              type: 'material',
            }}
            containerStyle={{ flex: 1, marginBottom: 0, marginTop: PADDING, marginHorizontal: 0 }}
            backgroundColor='white'
            buttonStyle={{ padding: 0 }}
            title={props.messages[MESSAGES.OPEN_WEBSITE]}
            titleStyle={{ textAlign: 'left', flex: 1 }}
            onPress={openWebsite}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export default EducationPage;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getPathFromState, getStateFromPath } from '@react-navigation/core';
import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator as createStackNavigator } from '@react-navigation/native-stack';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import { StatusBar } from 'expo-status-bar';
import { reduce, reduceRight } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { EMERGENCY_PERSISTENCE_NAV_STATE_KEY } from '#app/constants/index';
import Main from '#app/index';
import LoadingPage, { OverlayLoadingPage } from '#app/pages/loading.page';
import NotFoundPage from '#app/pages/notfound.page';
import notificationUtil from '#app/utils/notification.util';
import Constants from 'expo-constants';

const CUSTOM_PATHS = ['Main/LoginNav/ResetPassword', 'Main/Emergency', 'Main/HomeNav/MedicationSave', 'Main/HomeNav/DiarySave'];

if (Platform.OS != 'web') {
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    }),
  });
}

const isCustomPath = (path: string) => CUSTOM_PATHS.some((p: string) => path.includes(p));

const Stack = createStackNavigator();

const prefix = Linking.createURL('/');

const customGetStateFromPath = (path: string, options?: any) => {
  if (isCustomPath(path)) {
    let normPath = path;
    if (normPath.includes('/--/')) normPath = normPath.split('--')[1];
    if (!normPath.startsWith('/')) normPath = `/${normPath}`;
    const listPath = normPath.split('/');
    const [actionScreenName, actionScreenParams] = listPath[listPath.length - 1].split('?');
    let actionScreenParsedParams;
    if (actionScreenParams) {
      actionScreenParsedParams = reduce(
        actionScreenParams.split('&'),
        (total: any, param: string) => {
          const [paramName, paramValue] = param.split('=');
          return { ...total, [paramName]: paramValue };
        },
        {},
      );
    }
    listPath.pop();
    const result = reduceRight(
      listPath,
      (total: any, name: string) => {
        if (!name) {
          return { stale: true, routes: [total] };
        }
        return {
          name: name,
          stale: true,
          state: { routes: [total] },
        };
      },
      { name: actionScreenName, params: actionScreenParsedParams },
    );
    return result;
  }
  return getStateFromPath(path, options);
};

// const isDeepLinking = (url: string | null | undefined) => !(!url || url === Linking.createURL('') || url === prefix);

const linking: LinkingOptions<any> = {
  enabled: true,
  prefixes: [prefix],
  getStateFromPath: customGetStateFromPath,
  async getInitialURL() {
    const url = await Linking.getInitialURL();
    if (url) return url;
    // Handle URL from expo push notifications
    const response = await Notifications.getLastNotificationResponseAsync();
    return response?.notification?.request?.content?.data?.url as string;
  },
  subscribe(listener) {
    // Listen to incoming links from deep linking
    const linkingSubscription = Linking.addEventListener('url', ({ url }: { url: string }) => listener(url));
    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener((response) => {
      const url: any = response?.notification?.request?.content?.data?.url;
      listener(url);
    });
    return () => {
      linkingSubscription.remove();
      subscription.remove();
    };
  },
};

export default function App() {
  // const [initialUrl, setInitialUrl]: [string | null | undefined, any] = useState();
  // const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onStateChange = async (state: any) => {
    try {
      const path = getPathFromState(state);
      if (path.includes('Main/Emergency') && !path.includes('Main/Emergency/EmerInit')) {
        // store only for emergency
        const stringifiedState = JSON.stringify(state);
        await AsyncStorage.setItem(EMERGENCY_PERSISTENCE_NAV_STATE_KEY, stringifiedState);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onReady = () => {
    // only restore state if no deep linking
    AsyncStorage.getItem(EMERGENCY_PERSISTENCE_NAV_STATE_KEY)
      .then((stringifiedState) => {
        const state = stringifiedState ? JSON.parse(stringifiedState) : undefined;
        if (state) Linking.openURL(Linking.createURL(getPathFromState(state)));
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    console.log(Constants);
    if (Platform.OS !== 'web') {
      notificationUtil.registerForNotificationsAsync().catch((error) => console.error(error));
    }
  }, []);

  return (
    <SafeAreaProvider>
      {Platform.OS !== 'web' && ( // only enable for mobiles
        <OverlayLoadingPage
          isLoading={isLoading}
          withImage
        />
      )}
      <NavigationContainer
        linking={linking}
        onReady={onReady}
        onStateChange={onStateChange}
        fallback={<LoadingPage withImage />}>
        <StatusBar style='auto' />
        <Stack.Navigator
          screenOptions={{ headerShown: false }}
          initialRouteName='Main'>
          <Stack.Screen name='Main'>{(props: any) => <Main {...props} />}</Stack.Screen>
          <Stack.Screen name='NotFound'>{(props: any) => <NotFoundPage {...props} />}</Stack.Screen>
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaProvider>
  );
}

/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';
import { FONT_SIZE, GENERAL_STYLES } from '#constants/styles';

export default StyleSheet.create({
  ...GENERAL_STYLES,
  description: {
    color: 'black',
    fontSize: FONT_SIZE,
  },
});

import { User } from '#data/user';
import { DEFAULT_SETTINGS, Settings } from '#data/settings';

import * as Notifications from 'expo-notifications';
import * as Linking from 'expo-linking';
import { Messages } from '#data/extras';
import appUtil from '#utils/app.util';
import { MESSAGES } from '#constants/index';
import { find, isNil, set } from 'lodash';
import storageService from './storage.service';
import medicationService from './medication.service';
import moment from 'moment';

const cancelAllScheduled = async () => {
  const delay = await storageService.getDelay();
  const notifications = await Notifications.getAllScheduledNotificationsAsync();
  await Promise.all(
    notifications.map((noti: Notifications.NotificationRequest) => {
      if (noti.identifier === delay?.notiId) return; // dont cancel emergency notifications
      return Notifications.cancelScheduledNotificationAsync(noti.identifier);
    }),
  );
};

/* ----------------------------- DIARY REMINDER ----------------------------- */

const removeDiaryReminder = async (settings: Settings = DEFAULT_SETTINGS) => {
  if (settings.diaryReminder.notiId) {
    await Notifications.cancelScheduledNotificationAsync(settings.diaryReminder.notiId).catch((error) => console.error(error));
    return set(settings, 'diaryReminder.notiId', undefined);
  }
};

const registerDiaryReminder = async (messages: Messages, user?: User, settings: Settings = DEFAULT_SETTINGS) => {
  if (!user || !settings.diaryReminder.enabled) {
    return removeDiaryReminder(settings);
  }

  let alreadyRegistered = false;
  if (settings.diaryReminder.notiId) {
    const notifications = await Notifications.getAllScheduledNotificationsAsync();
    const registeredNoti: any = find(notifications, (value: Notifications.NotificationRequest) => value.identifier === settings.diaryReminder.notiId);
    alreadyRegistered = !isNil(registeredNoti);
    if (alreadyRegistered) {
      const registerHour = registeredNoti?.trigger?.dateComponents?.hour || registeredNoti?.trigger?.hour || 0;
      const registerMinute = registeredNoti?.trigger?.dateComponents?.minute || registeredNoti?.trigger?.minute || 0;
      if (registerHour !== settings.diaryReminder.hour || registerMinute !== settings.diaryReminder.minute) {
        await removeDiaryReminder(settings);
        alreadyRegistered = false;
      }
    }
  }
  if (alreadyRegistered) return settings;

  const notiId = await Notifications.scheduleNotificationAsync({
    content: {
      sound: true,
      vibrate: [10],
      autoDismiss: true,
      title: appUtil.formatSentence(messages[MESSAGES.DIARY_REMINDER_TITLE]),
      body: appUtil.formatSentence(messages[MESSAGES.DIARY_REMINDER_TITLE_DESCRIPTION]),
      data: {
        url: Linking.createURL('/Main/HomeNav/DiarySave', { queryParams: { redirect: 'Home' } }),
      },
    },
    trigger: {
      hour: settings.diaryReminder.hour,
      minute: settings.diaryReminder.minute,
      repeats: true,
    },
  });
  return set(settings, 'diaryReminder.notiId', notiId);
};

/* ------------------------------- MEDICATION ------------------------------- */

const registerMedicationReminder = async (
  ingredient: string,
  messages: Messages,
  id: string,
  morningReminder?: Date,
  morningNotificationId?: string,
  nightReminder?: Date,
  nightNotificationId?: string,
) => {
  const notificationIds: any = {};
  const content: Notifications.NotificationContentInput = {
    sound: true,
    vibrate: [10],
    autoDismiss: true,
    title: appUtil.formatSentence(messages[MESSAGES.TIME_TO_TAKE_MEDICINE]),
    body: `${appUtil.formatUpper(ingredient)}\n${appUtil.formatSentence(messages[MESSAGES.READ_INSTRUCTION_DESCRIPTION])}`,
    data: { url: Linking.createURL('/Main/HomeNav/MedicationSave', { queryParams: { id, editable: 'false', redirect: 'Home' } }) },
  };
  if (morningNotificationId) {
    await Notifications.cancelScheduledNotificationAsync(morningNotificationId).catch((e) => console.error(e));
  }
  if (morningReminder) {
    const nid = await Notifications.scheduleNotificationAsync({
      identifier: morningNotificationId,
      content,
      trigger: {
        hour: morningReminder.getHours(),
        minute: morningReminder.getMinutes(),
        repeats: true,
      },
    });
    notificationIds.morningNotificationId = nid;
  }
  if (nightNotificationId) {
    await Notifications.cancelScheduledNotificationAsync(nightNotificationId).catch((e) => console.error(e));
  }
  if (nightReminder) {
    const nid = await Notifications.scheduleNotificationAsync({
      identifier: nightNotificationId,
      content,
      trigger: {
        hour: nightReminder.getHours(),
        minute: nightReminder.getMinutes(),
        repeats: true,
      },
    });
    notificationIds.nightNotificationId = nid;
  }
  return notificationIds;
};

const registerAllMedicationReminders = async (messages: Messages, user?: User) => {
  if (!user) return;
  const medications = await medicationService.getAllMedications(user.id, user.token);
  await Promise.all(
    medications.map((item) => {
      const { id, ingredient, morningReminder: mR, nightReminder: nR, morningNotificationId, nightNotificationId } = item;
      const morningReminder = moment(parseInt(String(mR * 1000))).local();
      const nightReminder = moment(parseInt(String(nR * 1000))).local();
      return registerMedicationReminder(
        ingredient,
        messages,
        id,
        morningReminder.toDate(),
        morningNotificationId,
        nightReminder.toDate(),
        nightNotificationId,
      );
    }),
  );
};

const cancelMedicationReminder = async (morningNotificationId?: string, nightNotificationId?: string) => {
  if (morningNotificationId) {
    await Notifications.cancelScheduledNotificationAsync(morningNotificationId).catch((e) => console.error(e));
  }
  if (nightNotificationId) {
    await Notifications.cancelScheduledNotificationAsync(nightNotificationId).catch((e) => console.error(e));
  }
};

export default {
  cancelAllScheduled,
  removeDiaryReminder,
  registerDiaryReminder,
  registerMedicationReminder,
  registerAllMedicationReminders,
  cancelMedicationReminder,
};

/* eslint-disable react-native/no-inline-styles,react/display-name */
import React, { useEffect, useState } from 'react';
import messageService from '#services/message.service';
import emergencyService from '#services/emergency.service';
import termsConditionsService from '#services/terms-conditions.service';
import { KAAP, MESSAGE_PATHS, MESSAGES, ENABLE_INTERNET, LANGUAGES, EXPIRE_LOGIN } from '#constants/index';
import LoadingPage from '#pages/loading.page';
import appUtil from '#utils/app.util';
import storageService from '#services/storage.service';
import { DEFAULT_USER, User } from '#data/user';
import { DEFAULT_ACTION_PLAN, DEFAULT_MESSAGES } from '#data/extras';
import { DEFAULT_SETTINGS, Settings } from '#data/settings';
import TermsAndConditionsPage from '#pages/terms-conditions.page';
import SetupPage from '#pages/setup.page';
import { NavigationProp } from '@react-navigation/core';
import { LocaleConfig } from 'react-native-calendars';
import authorizationService from '#services/authorization.service';
import { Alert, Platform } from 'react-native';
import BottomTabsNavigation from '#navigations/bottomtabs.navigation';
import notificationService from '#services/notification.service';

LocaleConfig.locales[LANGUAGES[MESSAGES.VIETNAMESE]] = {
  monthNames: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 1', 'Tháng 12'],
  monthNamesShort: ['Thg 1', 'Thg 2', 'Thg 3', 'Thg 4', 'Thg 5', 'Thg 6', 'Thg 7', 'Thg 8', 'Thg 9', 'Thg 10', 'Thg 11', 'Thg 12'],
  dayNames: ['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7', 'Chủ Nhật'],
  dayNamesShort: ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'],
  today: 'Hôm nay',
};

LocaleConfig.locales[LANGUAGES[MESSAGES.ENGLISH]] = {
  monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  dayNames: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  dayNamesShort: ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'],
  today: 'Today',
};

LocaleConfig.defaultLocale = LANGUAGES[MESSAGES.VIETNAMESE];

interface MainProps {
  navigation: NavigationProp<any>;
}

export default function Main(props: MainProps) {
  // terms and conditions
  const [tacIsAccepted, setTacIsAccepted] = useState(false);
  const [tacVersion, setTacVersion] = useState(-1);
  const [tac, setTac]: [string | undefined, any] = useState();
  // data
  const [user, setUser] = useState(DEFAULT_USER);
  const [settings, setSettings]: [Settings | undefined, any] = useState();
  const [messages, setMessages] = useState(DEFAULT_MESSAGES);
  const [actionPlan, setActionPlan] = useState(DEFAULT_ACTION_PLAN);
  // dialogs and loadings
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // errors
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  const checkLoginSession = async (savedSettings: Settings, savedUser?: User) => {
    if (!savedUser || !savedUser.id || !savedUser.token) {
      return false;
    }
    try {
      await authorizationService.fetchProfile(savedUser.id, savedUser.token);
      return true;
    } catch (e) {
      console.error(e);
      onLogout();
      alert(appUtil.formatSentence(EXPIRE_LOGIN[savedSettings.locale]));
      return false;
    }
  };

  const getData = async () => {
    try {
      // settings
      let savedSettings = await storageService.getSettings();
      savedSettings = savedSettings || settings || DEFAULT_SETTINGS;
      // user
      let savedUser;
      if (Platform.OS != 'web') {
        savedUser = await storageService.getUser();
        savedUser = savedUser || user;
      }
      // messages
      let savedMessages = await storageService.getMessages(savedSettings.locale);
      try {
        if (!savedMessages) {
          savedMessages = await messageService.getMessage(MESSAGE_PATHS, savedSettings.locale);
        }
        await storageService.storeMessages(savedMessages, savedSettings.locale);
      } catch (e) {
        console.error('messages', e);
      }
      // action plans
      let savedActionPlan = await storageService.getActionPlan(savedSettings.locale);
      try {
        if (!savedActionPlan) {
          savedActionPlan = await emergencyService.getActionPlan(KAAP, savedSettings.locale);
        }
        await storageService.storeActionPlan(savedActionPlan, savedSettings.locale);
      } catch (e) {
        console.error(e);
      }
      // returns
      if (!savedMessages && !savedActionPlan) {
        return undefined;
      }
      return {
        savedUser,
        savedSettings,
        savedActionPlan,
        savedMessages,
      };
    } catch (e) {
      console.error(e);
    }
    return undefined;
  };

  const getTermsAndConditions = async (savedSettings?: Settings) => {
    if (!savedSettings) {
      return undefined;
    }
    // get saved tac
    const savedTacAgreement = await storageService.getTermsAndConditionsAgreement();
    const savedTac = await storageService.getTermsAndConditions();
    // getting newer tac
    try {
      const newTacVersions = await termsConditionsService.getTermsAndConditionsLatestVersion(savedSettings.locale);
      if (!savedTacAgreement || (savedTacAgreement && newTacVersions && newTacVersions.version > savedTacAgreement.version)) {
        const newTac = await termsConditionsService.getTermsAndConditions(newTacVersions.version, savedSettings.locale);
        await storageService.storeTermsAndConditions(newTac.data, newTacVersions.version, false, savedSettings.locale);
        return {
          savedTac: newTac.data,
          savedTacVersion: newTacVersions.version,
          savedTacIsAccepted: false,
        };
      }
    } catch (e) {
      console.error(e);
    }
    if (!savedTacAgreement && !savedTac) {
      return undefined;
    }
    return {
      savedTac,
      savedTacVersion: savedTacAgreement.version,
      savedTacIsAccepted: savedTacAgreement.isAccepted,
    };
  };

  const init = async () => {
    const savedSettings = await storageService.getSettings();
    setSettings(savedSettings);
    if (!savedSettings) {
      return;
    }
    const savedData = await getData();
    const savedTermsAndPermissions = await getTermsAndConditions(savedData && savedData.savedSettings);
    if (savedData && savedTermsAndPermissions) {
      if (savedData.savedMessages && savedData.savedActionPlan) {
        if (savedTermsAndPermissions.savedTacVersion >= 0 && savedTermsAndPermissions.savedTac) {
          setUser(savedData.savedUser);
          setSettings(savedData.savedSettings);
          setMessages(savedData.savedMessages);
          setActionPlan(savedData.savedActionPlan);
          setTac(savedTermsAndPermissions.savedTac);
          setTacVersion(savedTermsAndPermissions.savedTacVersion);
          setTacIsAccepted(savedTermsAndPermissions.savedTacIsAccepted);
          const isLoggedIn = await checkLoginSession(savedData.savedSettings, savedData.savedUser);
          if (isLoggedIn) {
            const newSettings = await notificationService.registerDiaryReminder(savedData.savedMessages, savedData.savedUser, savedSettings);
            onChangeSettings(newSettings, false);
            await notificationService.registerAllMedicationReminders(savedData.savedMessages, savedData.savedUser);
          }
          return;
        }
      }
    }
    throw new Error(appUtil.formatSentence(ENABLE_INTERNET[settings ? settings.locale : DEFAULT_SETTINGS.locale]));
  };

  const reload = () => {
    setIsLoading(true);
    init()
      .catch((e) => {
        alert(e.message);
        props.navigation.reset({ index: 0, stale: true, routes: [{ name: 'NotFound' }] });
      })
      .finally(() => setIsLoading(false));
  };

  /* ---------------------------------- USER ---------------------------------- */

  const onChangeUser = async (newUser?: User) => {
    if (newUser) {
      return storageService.storeUser(newUser).then(() => {
        setUser(newUser);
        return newUser;
      });
    }
  };

  const onLogout = () => {
    setIsLoading(true);
    Promise.all([storageService.deleteUser(), notificationService.cancelAllScheduled()])
      .then(() => setUser(DEFAULT_USER))
      .finally(() => {
        setShowLogoutDialog(false);
        setIsLoading(false);
      });
  };

  /* -------------------------------- SETTINGS -------------------------------- */

  const resetSettings = async () => {
    const savedSettings = await storageService.getSettings();
    if (savedSettings) {
      setSettings(savedSettings);
    } else {
      setSettings(DEFAULT_SETTINGS);
    }
  };

  const onChangeSettings = (newSettings?: Settings, reload = true, callback?: () => void) => {
    if (newSettings) {
      storageService
        .storeSettings(newSettings)
        .then(() => {
          if (reload) {
            init().finally(() => callback && callback());
          } else {
            setSettings(newSettings);
            callback && callback();
          }
        })
        .catch((e) => {
          console.error(e);
          resetSettings()
            .then(() => alert(appUtil.formatSentence(ENABLE_INTERNET[settings ? settings.locale : DEFAULT_SETTINGS.locale])))
            .finally(() => callback && callback());
        });
    }
  };

  /* ----------------------------------- TAC ---------------------------------- */

  const onTacAccept = () => {
    setIsLoading(true);
    storageService
      .storeTermsAndConditions(tac, tacVersion, true, (settings || DEFAULT_SETTINGS).locale)
      .then(() => setTacIsAccepted(true))
      .finally(() => setIsLoading(false));
  };

  /* --------------------------------- EFFECTS -------------------------------- */

  useEffect(() => {
    error && setShowError(true);
  }, [error]);

  useEffect(() => {
    if (showError) {
      Alert.alert(appUtil.formatSentence(messages ? messages[MESSAGES.ERROR] : 'error'), appUtil.formatSentence(error), [
        {
          onPress: () => setShowError(false),
          style: 'default',
        },
      ]);
    } else {
      setError('');
    }
  }, [showError]);

  useEffect(() => {
    if (settings) {
      LocaleConfig.defaultLocale = settings.locale;
    }
  }, [settings]);

  useEffect(reload, []);

  useEffect(() => {
    if (showLogoutDialog) {
      Alert.alert(
        appUtil.formatSentence(messages ? messages[MESSAGES.ALERT] : 'Alert'),
        appUtil.formatSentence(messages ? messages[MESSAGES.PROFILE_LOGOUT_DESCRIPTION] : 'are you sure to logout?'),
        [
          {
            text: appUtil.formatSentence(messages ? messages[MESSAGES.YES] : 'yes'),
            onPress: onLogout,
            style: 'destructive',
          },
          {
            text: appUtil.formatSentence(messages ? messages[MESSAGES.NO] : 'no'),
            onPress: () => setShowLogoutDialog(false),
            style: 'cancel',
          },
        ],
      );
    }
  }, [showLogoutDialog]);

  if (isLoading) return <LoadingPage withImage />;

  if (!settings)
    return (
      <SetupPage
        settings={settings}
        onChangeSettings={onChangeSettings}
      />
    );

  if (!messages) return <LoadingPage withImage />;

  if (!tacIsAccepted)
    return (
      <TermsAndConditionsPage
        messages={messages}
        tac={tac}
        onAccept={onTacAccept}
      />
    );

  if (actionPlan && tac) {
    return (
      <BottomTabsNavigation
        user={user}
        settings={settings}
        messages={messages}
        actionPlan={actionPlan}
        setError={setError}
        setIsLoading={setIsLoading}
        setShowLogoutDialog={setShowLogoutDialog}
        onChangeUser={onChangeUser}
        onChangeSettings={onChangeSettings}
      />
    );
  }

  return <LoadingPage withImage />;
}

/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';
import { FONT_SIZE, FONT_TITLE_SIZE, GENERAL_STYLES } from '#constants/styles';

export default StyleSheet.create({
  ...GENERAL_STYLES,
  cardRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
    marginHorizontal: 2,
  },
  cardRowItem: {
    alignItems: 'stretch',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  cardRowItemContent: {
    color: 'black',
    flex: 1,
    flexWrap: 'wrap',
    fontSize: FONT_SIZE,
    fontWeight: 'normal',
  },
  cardRowItemTitle: {
    fontSize: FONT_SIZE,
    textTransform: 'uppercase',
  },
  title: {
    fontSize: FONT_TITLE_SIZE,
    fontWeight: 'bold',
    marginBottom: 5,
    marginTop: 0,
    textTransform: 'capitalize',
  },
});

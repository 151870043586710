import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { BACKGROUND_COLORS } from './colors';

export const stackNavigationScreenOptions: NativeStackNavigationOptions = {
  headerShown: true,
  headerShadowVisible: false,
  headerStyle: {
    backgroundColor: BACKGROUND_COLORS.HEADER,
  },
  animation: 'simple_push',
  presentation: 'card',
};

export default {};

/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';
import { BORDER_RADIUS, FONT_SIZE } from '#constants/styles';

export default StyleSheet.create({
  checkBox: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: BORDER_RADIUS,
    borderWidth: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 8,
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  checkBoxText: {
    flex: 1,
    flexWrap: 'wrap',
    fontSize: FONT_SIZE,
    fontWeight: 'normal',
    margin: 0,
    padding: 0,
  },
  noMargin: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
  },
});

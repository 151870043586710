/* eslint-disable react-native/no-inline-styles */
import kaapLogo from '#assets/kaap.png';
import BaseButton from '#components/buttons';
import COLORS from '#constants/colors';
import { NavigationProp } from '@react-navigation/native';
import React from 'react';
import { Image, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styles from './styles/notfound.page';

interface NotFoundPageProps {
  style?: any;
  navigation?: NavigationProp<any>;
  reload?(): void | Promise<void>;
}

function NotFoundPage(props: NotFoundPageProps) {
  return (
    <SafeAreaView style={{ ...styles.page, ...props.style }}>
      <View style={styles.content}>
        <View style={styles.logoContainer}>
          <Image source={kaapLogo} style={styles.logo} />
        </View>
        <BaseButton
          type='solid'
          color={COLORS.BLUE}
          backgroundColor='white'
          containerStyle={{ flexBasis: undefined }}
          icon={{
            name: 'home',
            type: 'material-community',
            color: COLORS.BLUE,
          }}
          onPress={() => {
            if (props.navigation) props.navigation.reset({ index: 0, routes: [{ name: 'Main' }] });
            else props.reload && props.reload();
          }}
        />
      </View>
    </SafeAreaView>
  );
}

export default NotFoundPage;

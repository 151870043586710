/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import { LANGUAGES, LANGUAGE_TEXT, MESSAGES } from '#constants/index';
import COLORS from '#constants/colors';
import { stackNavigationScreenOptions } from '#constants/options';
import { DEFAULT_SETTINGS, Settings } from '#data/settings';
import { createNativeStackNavigator as createStackNavigator } from '@react-navigation/native-stack';
import { CheckBox, Icon, Text } from '@rneui/themed';
import React, { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView, TouchableOpacity, View } from 'react-native';
import styles from './styles/setup.page';

const Stack = createStackNavigator();

interface SettingSetupPageProps {
  settings: Settings;
  setSettings(settings?: Settings): void | Promise<void>;
}

function SettingSetupPage(props: SettingSetupPageProps) {
  return (
    <SafeAreaView style={styles.page}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>{LANGUAGE_TEXT[props.settings.locale]}</Text>
          <View style={styles.subSection}>
            <CheckBox
              iconType='material-community'
              checkedIcon='checkbox-marked-circle'
              uncheckedIcon='checkbox-blank-circle-outline'
              title='Tiếng Việt (vi-vn)'
              checked={props.settings.locale === LANGUAGES[MESSAGES.VIETNAMESE]}
              onPress={() => props.setSettings({ ...props.settings, locale: LANGUAGES[MESSAGES.VIETNAMESE] })}
              containerStyle={{ ...styles.checkBox, borderBottomColor: COLORS.CHROME_GREY, borderBottomWidth: 1 }}
              textStyle={styles.checkBoxText}
              checkedColor={COLORS.BLUE}
            />
            <CheckBox
              iconType='material-community'
              checkedIcon='checkbox-marked-circle'
              uncheckedIcon='checkbox-blank-circle-outline'
              title='English (en-us)'
              checked={props.settings.locale === LANGUAGES[MESSAGES.ENGLISH]}
              onPress={() => props.setSettings({ ...props.settings, locale: LANGUAGES[MESSAGES.ENGLISH] })}
              containerStyle={styles.checkBox}
              textStyle={styles.checkBoxText}
              checkedColor={COLORS.BLUE}
            />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

interface SetupPageProps {
  settings?: Settings;
  onChangeSettings(settings?: Settings, reload?: boolean, callback?: () => void): void;
}

export default function SetupPage(setupProps: SetupPageProps) {
  const [settings, setSettings]: [Settings | undefined, any] = useState(DEFAULT_SETTINGS);

  useEffect(() => {
    if (setupProps.settings) {
      setSettings(setupProps.settings);
    }
  }, []);

  const onChangeSetup = () => {
    setupProps.onChangeSettings(settings, true);
  };

  return (
    <Stack.Navigator
      initialRouteName='SettingSetup'
      screenOptions={{
        ...stackNavigationScreenOptions,
      }}>
      <Stack.Screen
        name='SettingSetup'
        options={{
          title: 'Kid Asthma Action Plan',
          headerLeft: () => (
            <Icon
              name='cog'
              type='material-community'
              iconStyle={{ paddingHorizontal: 12 }}
            />
          ),
          headerRight: () => (
            <TouchableOpacity
              style={{ paddingHorizontal: 12 }}
              onPress={onChangeSetup}>
              <Icon
                name='arrow-right'
                color={COLORS.BLUE}
                type='material-community'
              />
            </TouchableOpacity>
          ),
        }}>
        {(props) => (
          <SettingSetupPage
            {...props}
            settings={settings}
            setSettings={setSettings}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
}

/* eslint-disable react-native/no-color-literals */
import { GENERAL_STYLES } from '#constants/styles';
import COLORS from '#constants/colors';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  ...GENERAL_STYLES,
  hyperlink: {
    color: COLORS.BLUE,
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
});

/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';
import COLORS from '#constants/colors';
import { BORDER_RADIUS, FONT_SIZE, FONT_TITLE_SIZE, GENERAL_STYLES, MARGIN_HORIZONTAL, MARGIN_VERTICAL, PADDING } from '#constants/styles';

export const checkBoxTextHtmlStyle: any = {
  color: 'black',
  flex: 1,
  flexWrap: 'wrap',
  fontSize: FONT_SIZE,
  fontWeight: 'normal',
  margin: 0,
  marginLeft: PADDING,
  padding: 0,
  textAlign: 'left',
};

export default StyleSheet.create({
  ...GENERAL_STYLES,
  card: {
    ...GENERAL_STYLES.card,
    overflow: 'hidden',
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  checkBox: {
    alignItems: 'center',
    backgroundColor: 'white',
    // borderBottomColor: COLORS.GREY_100,
    // borderBottomWidth: 1,
    borderRadius: 0,
    borderWidth: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    padding: PADDING,
  },
  checkBoxText: {
    color: 'black',
    flex: 1,
    flexWrap: 'wrap',
    fontSize: FONT_SIZE,
    fontWeight: 'normal',
    margin: 0,
    marginLeft: PADDING,
    padding: 0,
    textAlign: 'left',
  },
  filterTitle: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 10,
  },
  input: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 0,
    borderWidth: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 2,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    padding: 0,
  },
  line: {
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    marginHorizontal: PADDING,
  },
  listFooter: {
    marginBottom: 10,
    marginHorizontal: 10,
  },
  listItem: {
    alignItems: 'stretch',
    backgroundColor: 'white',
    borderColor: 'transparent',
    borderRadius: BORDER_RADIUS,
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: MARGIN_VERTICAL,
    marginHorizontal: MARGIN_HORIZONTAL,
    overflow: 'hidden',
    paddingVertical: PADDING,
    shadowColor: 'transparent',
    shadowOpacity: 0,
  },
  listItemTitle: {
    alignItems: 'stretch',
    borderBottomColor: COLORS.TRANSPARENT_RED,
    borderBottomWidth: 0,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingHorizontal: MARGIN_VERTICAL,
  },
  listItemTitleRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  noMargin: {
    borderRadius: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    paddingBottom: 8,
    paddingTop: 8,
    paddingVertical: 8,
  },
  overlay: {
    alignItems: 'stretch',
    borderRadius: BORDER_RADIUS,
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '85%',
    minWidth: '85%',
    padding: 24,
  },
  page: {
    ...GENERAL_STYLES.page,
  },
  title: {
    backgroundColor: 'white',
    color: COLORS.RED,
    fontSize: FONT_TITLE_SIZE,
    fontWeight: 'normal',
    padding: PADDING,
    textAlign: 'left',
  },
});

/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import { MESSAGES } from '#constants/index';
import COLORS, { BACKGROUND_COLORS } from '#constants/colors';
import { FONT_SIZE, FONT_TITLE_SIZE, MARGIN_HORIZONTAL, MARGIN_VERTICAL } from '#constants/styles';
import { Messages } from '#data/extras';
import { Settings } from '#data/settings';
import { User } from '#data/user';
import styles from '#pages/styles/notification.page';
import { NavigationProp, useFocusEffect } from '@react-navigation/core';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Icon, Text } from '@rneui/themed';
import appUtil from '#utils/app.util';
import * as Notifications from 'expo-notifications';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, SafeAreaView, View } from 'react-native';
import LoadingPage, { NoDataPage } from './loading.page';

const NotificationTab = createMaterialTopTabNavigator();

// interface NotificationSingleProps {
//   settings: Settings;
//   navigation: NavigationProp<any>;
//   messages: Messages;
//   user: User;
//   route: any;
// }

// export function NotificationSinglePage(props: NotificationSingleProps) {
//   const [loading, setLoading] = useState(false);
//   const [title, setTitle] = useState(undefined);
//   const [subtitle, setSubtitle] = useState(undefined);
//   const [body, setBody] = useState(undefined);

//   useFocusEffect(useCallback(() => {}, [props.route]));

//   return <SafeAreaView style={styles.page}>{loading ? <LoadingPage withImage /> : title && body && undefined}</SafeAreaView>;
// }

interface ScheduledNotificationProps {
  settings: Settings;
  navigation: NavigationProp<any>;
  messages: Messages;
  user: User;
  route: any;
}

export function ScheduledNotificationPage(props: ScheduledNotificationProps) {
  const [loading, setLoading] = useState(false);

  const [refreshing, setRefreshing] = useState(false);
  const [data, setData]: [any, any] = useState([]);

  const getData = async () => {
    const notifications = await Notifications.getAllScheduledNotificationsAsync();
    setData(notifications);
  };

  const reload = () => {
    setLoading(true);
    getData().finally(() => setLoading(false));
  };

  const renderItem = ({ item, index }: any) => {
    return (
      <View
        style={styles.listItem}
        key={index}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: MARGIN_VERTICAL,
            borderBottomColor: COLORS.CHROME_GREY,
            borderBottomWidth: 1,
            paddingBottom: MARGIN_VERTICAL,
          }}>
          <View style={{ ...styles.listItemTitle, paddingHorizontal: 0 }}>
            <View style={styles.listItemTitleRow}>
              <Text style={{ fontSize: FONT_TITLE_SIZE, fontWeight: 'bold', textTransform: 'capitalize', padding: 0, margin: 0 }}>
                {appUtil.formatSentence(item?.content?.title)}
              </Text>
            </View>
            <View style={styles.listItemTitleRow}>
              <Icon
                name='clock'
                size={16}
                type='material-community'
                containerStyle={{ marginRight: 4 }}
              />
              <Text style={{ fontSize: FONT_SIZE, padding: 0, margin: 0 }}>
                {item?.trigger?.type === 'timeInterval'
                  ? moment(item?.content?.data?.nextTrigger || Date.now())
                      .local(true)
                      .format('HH:mm:ss')
                  : moment()
                      .local(true)
                      .set({
                        hour: item?.trigger?.dateComponents?.hour || item?.trigger?.hour || 0,
                        minute: item?.trigger?.dateComponents?.minute || item?.trigger?.minute || 0,
                        second: item?.trigger?.dateComponents?.second || item?.trigger?.second || 0,
                      })
                      .format('HH:mm:ss')}
              </Text>
              {(item?.trigger?.repeats || item?.trigger?.type === 'daily') && (
                <Icon
                  name='repeat-on'
                  size={16}
                  type='material'
                  containerStyle={{ marginLeft: 8, marginRight: 4 }}
                />
              )}
            </View>
          </View>
          {/* <TouchableOpacity
            onPress={() => {
              Alert.alert(
                appUtil.formatSentence(props.messages[MESSAGES.ALERT]),
                appUtil.formatSentence(props.messages[MESSAGES.DELETE_CONFIRM_DESCRIPTION]),
                [
                  {
                    text: appUtil.formatSentence(props.messages[MESSAGES.YES]),
                    onPress: () => Notifications.cancelScheduledNotificationAsync(item.identifier).finally(reload),
                    style: 'destructive',
                  },
                  {
                    text: appUtil.formatSentence(props.messages[MESSAGES.NO]),
                    style: 'cancel',
                  },
                ],
              );
            }}>
            <Icon
              name='delete-forever'
              type='material'
              size={24}
              color={COLORS.WHITE}
              containerStyle={{
                borderRadius: 1000,
                backgroundColor: COLORS.RED,
                padding: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          </TouchableOpacity> */}
        </View>
        <View style={{ ...styles.content, ...styles.noMargin, paddingTop: MARGIN_VERTICAL }}>
          <Text style={{ fontSize: FONT_SIZE, padding: 0, margin: 0 }}>{item?.content?.body}</Text>
        </View>
      </View>
    );
  };

  const keyExtractor = (item: any, index: number) => {
    return index.toString();
  };

  /* --------------------------------- EFFECTS -------------------------------- */

  useFocusEffect(useCallback(reload, []));

  useEffect(() => {
    if (data) {
      props.navigation.setOptions({
        tabBarBadge:
          data.length > 0
            ? () => (
                <View
                  style={{
                    backgroundColor: COLORS.ORANGE,
                    borderRadius: 10000,
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 4,
                    paddingHorizontal: 8,
                  }}>
                  <Text
                    style={{
                      fontSize: 12,
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                    numberOfLines={1}>
                    {data.length}
                  </Text>
                </View>
              )
            : null,
      });
    }
  }, [data]);

  return loading ? (
    <LoadingPage withImage />
  ) : (
    <SafeAreaView style={styles.page}>
      <FlatList
        data={data}
        refreshing={refreshing}
        onRefresh={() => {
          setRefreshing(true);
          getData().finally(() => setRefreshing(false));
        }}
        initialNumToRender={3}
        keyExtractor={keyExtractor}
        contentContainerStyle={{ ...styles.scrollView, paddingTop: 4 }}
        renderItem={renderItem}
        ListEmptyComponent={<NoDataPage {...props} />}
      />
    </SafeAreaView>
  );
}

interface NotificationProps {
  settings: Settings;
  navigation: NavigationProp<any>;
  messages: Messages;
  user: User;
  route: any;
}

export default function NotificationPage(notificationProps: NotificationProps) {
  return (
    <NotificationTab.Navigator
      initialRouteName='Scheduled-Notifications'
      screenOptions={{
        swipeEnabled: false,
        tabBarScrollEnabled: false,
        tabBarAllowFontScaling: true,
        tabBarStyle: {
          backgroundColor: 'transparent',
          borderTopWidth: 0,
          borderBottomWidth: 0,
          marginHorizontal: MARGIN_HORIZONTAL,
          elevation: 0,
          shadowColor: 'transparent',
          // height: 36,
          width: '25%',
          // width: 100,
        },
        tabBarLabel: ({ children, color, focused }) => (
          <View style={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Text
              style={{ color: focused ? COLORS.WHITE : color, fontSize: 14, fontWeight: 'bold' }}
              numberOfLines={1}>
              {children}
            </Text>
          </View>
        ),
        tabBarItemStyle: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          elevation: 0,
          paddingVertical: 0,
          paddingHorizontal: 0,
          marginVertical: 0,
          marginHorizontal: 0,
          shadowColor: 'transparent',
          overflow: 'hidden',
          height: '100%',
        },
        tabBarContentContainerStyle: {
          paddingVertical: 0,
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        },
        tabBarIndicatorContainerStyle: {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          backgroundColor: BACKGROUND_COLORS.CONTENT,
          borderRadius: 10000,
          marginVertical: 8,
        },
        tabBarIndicatorStyle: {
          backgroundColor: COLORS.BLUE,
          borderRadius: 10000,
          height: '100%',
        },
      }}>
      <NotificationTab.Screen
        name='Scheduled-Notifications'
        options={{ title: appUtil.formatSentence(notificationProps.messages[MESSAGES.NOTIFICATION_SCHEDULE_TITLE]) }}>
        {(props) => (
          <ScheduledNotificationPage
            {...notificationProps}
            {...props}
          />
        )}
      </NotificationTab.Screen>
      {/* <NotificationTab.Screen
        name='Scheduled-Notifications-2'
        options={{ title: appUtil.formatSentence(notificationProps.messages[MESSAGES.NOTIFICATION_SCHEDULE_TITLE]) }}>
        {(props) => <ScheduledNotificationPage {...notificationProps} {...props} />}
      </NotificationTab.Screen> */}
    </NotificationTab.Navigator>
  );
}

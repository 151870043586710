const COLORS = {
  BLUE: 'rgba(82, 113, 255, 1)',
  TRANSPARENT_BLUE: 'rgba(82, 113, 255, 0.2)',
  RED: 'rgba(255, 102, 196, 1)',
  RED_HEX: '#ff66c4',
  TRANSPARENT_RED: 'rgba(255, 102, 196, 0.2)',
  GREEN: 'rgba(0, 194, 203, 1)',
  TRANSPARENT_GREEN: 'rgba(0, 194, 203, 0.2)',
  ORANGE: 'rgba(252, 113, 56, 1)',
  ORANGE_HEX: '#fc7138',
  TRANSPARENT_ORANGE: 'rgba(252, 113, 56, 0.2)',
  YELLOW: 'rgba(251, 192, 45, 1.0)',
  TRANSPARENT_YELLOW: 'rgba(251, 192, 45, 0.2)',
  GRAY: 'rgba(97, 97, 97, 1.0)',
  TRANSPARENT_GRAY: 'rgba(97, 97, 97, 0.2)',
  GREY_100: 'rgba(245, 245, 245, 1.0)',
  GREY_50: 'rgba(250, 250, 250, 1.0)',
  GREY_700: 'rgba(97, 97, 97, 1.0)',
  CHROME_GREY: 'rgba(241, 243, 244, 1.0)',
  TRANSPARENT_BLACK: 'rgba(0, 0, 0, 0.3)',
  BLACK: 'rgba(0, 0, 0, 1.0)',
  A200: 'rgba(100, 255, 218, 1)',
  TRANSPARENT_WHITE: 'rgba(255,255,255, 0.7)',
  LIGHT_GREEN: 'rgba(29, 233, 182, 1)',
  LIGHT_YELLOW: 'rgba(255, 235, 59, 1)',
  LIGHT_BLUE: 'rgba(79, 195, 247, 1)',
  WHITE: 'rgba(255, 255, 255, 1)',
  AMBER: 'rgba(255, 193, 7, 1)',
};

export const BACKGROUND_COLORS = {
  MAIN: COLORS.CHROME_GREY,
  HEADER: COLORS.CHROME_GREY,
  TABBAR: 'transparent',
  CARD: 'white',
  CONTENT: 'white',
};

export const FOREGROUND_COLORS = {
  MAIN: 'black',
  HEADER: 'black',
};

export default COLORS;

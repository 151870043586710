/* eslint-disable react-native/no-inline-styles,react/display-name,react-native/no-color-literals */
import { MESSAGES } from '#constants/index';
import kaapIcon from '#assets/kaap-notification-icon.png';
import COLORS, { BACKGROUND_COLORS } from '#constants/colors';
import { MARGIN_HORIZONTAL, PADDING } from '#constants/styles';
import { ActionPlan, Messages } from '#data/extras';
import { Settings } from '#data/settings';
import { User } from '#data/user';
import EmergencyPage from '#pages/emergency.page';
import NotificationPage from '#pages/notification.page';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Icon } from '@rneui/themed';
import appUtil from '#utils/app.util';
import React, { Dispatch, SetStateAction } from 'react';
import { Alert, Image, Platform, TouchableOpacity } from 'react-native';
import HomeNavigation from './home.navigation';
import LoginNavigation from './login.navigation';
import ProfileNavigation from './profile.navigation';

const BottomTabs = createBottomTabNavigator();

interface BottomTabsNavigationProps {
  user?: User;
  onChangeUser(user?: User): Promise<User | undefined>;
  settings: Settings;
  onChangeSettings(settings?: Settings, reload?: boolean, callback?: () => void): void;
  messages: Messages;
  actionPlan: ActionPlan;
  setShowLogoutDialog: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

function BottomTabsNavigation(drawerProps: BottomTabsNavigationProps) {
  return (
    <BottomTabs.Navigator
      {...drawerProps}
      initialRouteName='HomeNav'
      screenOptions={{
        headerShown: true,
        headerStyle: {
          backgroundColor: BACKGROUND_COLORS.HEADER,
          elevation: 0,
          shadowColor: 'transparent',
          borderBottomColor: COLORS.CHROME_GREY,
          borderBottomWidth: 1,
        },
        tabBarAllowFontScaling: true,
        tabBarStyle: {
          backgroundColor: BACKGROUND_COLORS.TABBAR,
          borderTopColor: COLORS.CHROME_GREY,
          borderTopWidth: 1,
          elevation: 0,
          justifyContent: 'space-between',
          alignItems: 'center',
          // flexGrow: 0.005,
          // paddingHorizontal: MARGIN_HORIZONTAL,
          // marginVertical: 1,
        },
        tabBarItemStyle: {
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 0,
          paddingVertical: 5,
          ...(Platform.OS == 'web' ? { minWidth: 120 } : {}),
        },
        tabBarLabelPosition: 'below-icon',
        // tabBarActiveBackgroundColor: 'white',
        tabBarShowLabel: true,
        tabBarActiveTintColor: COLORS.BLUE,
        headerLeft: () => (
          <Image
            source={kaapIcon}
            style={{ height: 36, width: 36, paddingHorizontal: PADDING, marginHorizontal: MARGIN_HORIZONTAL }}
          />
        ),
      }}>
      <BottomTabs.Screen
        name='HomeNav'
        options={{
          title: appUtil.formatSentence(drawerProps.messages[MESSAGES.HOME]),
          tabBarIcon: ({ color, size }: any) => (
            <Icon
              name='apps'
              type='material-community'
              color={color}
              size={size}
            />
          ),
          headerShown: false,
        }}>
        {(props: any) => (
          <HomeNavigation
            {...drawerProps}
            {...props}
          />
        )}
      </BottomTabs.Screen>
      <BottomTabs.Screen
        name='Emergency'
        options={{
          title: appUtil.formatSentence(drawerProps.messages[MESSAGES.EMERGENCY]),
          tabBarActiveTintColor: COLORS.ORANGE,
          tabBarIcon: ({ focused, color, size }: any) => (
            <Icon
              name='alarm-light'
              type='material-community'
              color={focused ? COLORS.ORANGE : color}
              size={size}
            />
          ),
          tabBarLabelStyle: { fontWeight: 'bold' },
          headerRight: () => (
            <TouchableOpacity
              style={{ marginHorizontal: MARGIN_HORIZONTAL }}
              onPress={() => {
                if (Platform.OS == 'web') {
                  alert(appUtil.formatSentence(drawerProps.messages[MESSAGES.GINA_SOURCE]));
                } else {
                  Alert.alert(
                    appUtil.formatSentence(drawerProps.messages[MESSAGES.REFERENCE]),
                    appUtil.formatSentence(drawerProps.messages[MESSAGES.GINA_SOURCE]),
                  );
                }
              }}>
              <Icon
                name='information'
                type='material-community'
                color={COLORS.BLACK}
                size={24}
                containerStyle={{
                  borderRadius: 1000,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </TouchableOpacity>
          ),
        }}>
        {(props: any) => (
          <EmergencyPage
            {...drawerProps}
            {...props}
          />
        )}
      </BottomTabs.Screen>
      {Platform.OS == 'web' ? null : !drawerProps.user ? (
        <BottomTabs.Screen
          name='LoginNav'
          options={{
            title: appUtil.formatSentence(drawerProps.messages[MESSAGES.LOGIN]),
            tabBarIcon: ({ color, size }: any) => (
              <Icon
                name='login'
                type='material'
                color={color}
                size={size}
              />
            ),
            headerShown: false,
          }}>
          {(props) => (
            <LoginNavigation
              {...drawerProps}
              {...props}
            />
          )}
        </BottomTabs.Screen>
      ) : (
        <BottomTabs.Screen
          name='ProfileNav'
          options={{
            title: appUtil.formatSentence(drawerProps.messages[MESSAGES.PROFILE]),
            tabBarIcon: ({ color, size }: any) => (
              <Icon
                name='account-circle'
                type='material-community'
                color={color}
                size={size}
              />
            ),
            headerShown: false,
          }}>
          {(props: any) => (
            <ProfileNavigation
              {...drawerProps}
              {...props}
            />
          )}
        </BottomTabs.Screen>
      )}
      {Platform.OS == 'web' ? null : (
        <BottomTabs.Screen
          name='Notifications'
          options={{
            title: appUtil.formatSentence(drawerProps.messages[MESSAGES.NOTIFICATION]),
            tabBarIcon: ({ color, size }: any) => (
              <Icon
                name='notifications'
                type='material'
                color={color}
                size={size}
              />
            ),
            headerTitle: appUtil.formatSentence(drawerProps.messages[MESSAGES.NOTIFICATION]),
          }}>
          {(props: any) => (
            <NotificationPage
              {...drawerProps}
              {...props}
            />
          )}
        </BottomTabs.Screen>
      )}
    </BottomTabs.Navigator>
  );
}

export default BottomTabsNavigation;

/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import { GENERAL_STYLES } from '#constants/styles';
import appUtil from '#utils/app.util';
import React, { useState } from 'react';
import { TouchableOpacity, View, Image } from 'react-native';
import { Icon, Text } from '@rneui/themed';

interface CardProps {
  icon?: string;
  iconType?: string;
  image?: any;
  name?: string;
  color: string;
  iconSize?: number;
  description?: string;
  onPress(): void | Promise<void>;
  containerStyle?: any;
  iconStyle?: any;
  imageStyle?: any;
  headerStyle?: any;
  titleStyle?: any;
  titleFullLines?: boolean;
  descriptionStyle?: any;
  descriptionFullLines?: boolean;
}

function Card(props: CardProps) {
  const [showFullDesc, setShowFullDesc] = useState(false);
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={{ ...GENERAL_STYLES.card, ...(props.containerStyle || {}) }}
      onLongPress={() => setShowFullDesc(!showFullDesc)}>
      <View style={{ ...GENERAL_STYLES.cardHeader, ...(props.headerStyle || {}) }}>
        {props.icon ? (
          <Icon
            name={props.icon}
            color={props.color}
            size={props.iconSize || GENERAL_STYLES.cardTitle.fontSize}
            type={props.iconType || 'material-community'}
            iconStyle={{ ...GENERAL_STYLES.cardIcon, ...(props.iconStyle || {}) }}
          />
        ) : (
          props.image && <Image source={props.image} style={props.imageStyle || {}} />
        )}
        {props.name && (
          <Text
            ellipsizeMode='tail'
            numberOfLines={props.titleFullLines ? 0 : 1}
            style={{ ...GENERAL_STYLES.cardTitle, color: props.color, ...(props.titleStyle || {}) }}>
            {appUtil.formatSentence(props.name)}
          </Text>
        )}
      </View>
      {props.description && (
        <Text
          ellipsizeMode='tail'
          numberOfLines={props.descriptionFullLines ? 0 : showFullDesc ? 0 : 2}
          style={{ ...GENERAL_STYLES.cardDescription, color: props.color, ...(props.descriptionStyle || {}) }}>
          {appUtil.formatSentence(props.description)}
        </Text>
      )}
    </TouchableOpacity>
  );
}

export default Card;

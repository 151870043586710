/* eslint-disable react-native/no-raw-text */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import { GENDERS, MESSAGES } from '#constants/index';
import BaseButton from '#components/buttons';
import DatePicker from '#components/inputs/datepicker.input';
import Picker from '#components/inputs/picker.input';
import COLORS from '#constants/colors';
import { Messages } from '#data/extras';
import { DEFAULT_PROFILE, Profile, User } from '#data/user';
import styles from '#pages/styles/profile.page';
import { NavigationProp, useFocusEffect } from '@react-navigation/core';
import authorizationService from '#services/authorization.service';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Alert, RefreshControl, SafeAreaView, TouchableOpacity, View } from 'react-native';
import { Icon, Input } from '@rneui/themed';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import LoadingPage from './loading.page';
import moment from 'moment';
import appUtil from '#utils/app.util';
import { isEqual } from 'lodash';
import { BORDER_RADIUS, FONT_LABEL_SIZE, FONT_SIZE, MARGIN_HORIZONTAL, MARGIN_VERTICAL } from '#constants/styles';

interface ProfilePageProps {
  user?: User;
  onChangeUser(user?: User): Promise<User | undefined>;
  messages: Messages;
  navigation: NavigationProp<any>;
  isRegister?: boolean;
  setError: Dispatch<SetStateAction<string>>;
}

export default function ProfilePage(props: ProfilePageProps) {
  const [profile, setProfile]: [Profile, (val: Profile) => void] = useState(DEFAULT_PROFILE);
  const [password, setPassword]: [string | undefined, (text: string | undefined) => void] = useState();
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [cancel, setCancel] = useState(false);
  const [showDatePicker, setShowDatePicker]: [boolean, any] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const reload = () => {
    setIsLoading(true);
    if (!props.isRegister && props.user && props.user.id && props.user.token) {
      authorizationService
        .fetchProfile(props.user?.id, props.user?.token)
        .then((newProfile) => {
          props.onChangeUser({ ...props.user, profile: newProfile }).then(() => {
            if (props.user?.profile) {
              setProfile(newProfile);
            }
            setIsLoading(false);
          });
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const onRefresh = () => {
    setRefreshing(true);
    reload();
    setRefreshing(false);
  };

  useFocusEffect(
    useCallback(() => {
      reload();
    }, []),
  );

  const onClear = () => {
    setIsLoading(true);
    if (props.isRegister) {
      setProfile(DEFAULT_PROFILE);
      setPassword(undefined);
    } else {
      if (props.user && props.user.profile) {
        setProfile(props.user.profile);
      }
    }
    dismissPickers();
    setIsLoading(false);
  };

  const dismissPickers = () => {
    setCancel(false);
    setShowDatePicker(false);
  };

  const onChangeDob = (event: any, date?: Date) => {
    setProfile({ ...profile, dob: date });
    if (date) {
      if (moment().diff(moment(date), 'years', true) >= 16) {
        Alert.alert(appUtil.formatSentence(props.messages[MESSAGES.ALERT]), appUtil.formatSentence(props.messages[MESSAGES.ALERT_AGE_RANGE]));
      }
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      if (props.isRegister) {
        await authorizationService.register({ ...profile, password }).then(() => {
          setProfile(DEFAULT_PROFILE);
          setPassword(undefined);
        });
        props.navigation.navigate('Login');
      } else {
        if (props.user && props.user.id && props.user.token) {
          await authorizationService.update(props.user.id, props.user.token, profile);
          await props.onChangeUser({ ...props.user, profile });
        }
      }
    } catch (e) {
      console.error(e);
      Alert.alert(appUtil.formatSentence(props.messages[MESSAGES.ERROR]), appUtil.formatSentence(props.messages[MESSAGES.STH_WRONG_TRY_AGAIN]));
    } finally {
      setIsLoading(false);
    }
  };

  const submitDisabled = () => {
    if (props.isRegister) return false;
    return isEqual(props.user?.profile, profile);
  };

  useEffect(() => {
    if (cancel) {
      Alert.alert(
        appUtil.formatSentence(props.messages[MESSAGES.ALERT]),
        appUtil.formatSentence(props.messages[MESSAGES.PROFILE_CLEAR_DESCRIPTION]),
        [
          {
            text: appUtil.formatSentence(props.messages[MESSAGES.YES]),
            onPress: onClear,
            style: 'destructive',
          },
          {
            text: appUtil.formatSentence(props.messages[MESSAGES.NO]),
            onPress: () => setCancel(false),
            style: 'cancel',
          },
        ],
      );
    }
  }, [cancel]);

  return isLoading ? (
    <LoadingPage withImage />
  ) : (
    <SafeAreaView style={styles.page}>
      <KeyboardAwareScrollView
        style={styles.scrollView}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }>
        <View style={styles.content}>
          <Input
            label={`${appUtil.formatSentence(props.messages[MESSAGES.NAME])}*`}
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.PROFILE_NAME_PLACEHOLDER])}
            editable={true}
            value={profile.name}
            onChangeText={(name: string) => setProfile({ ...profile, name })}
            onTouchEnd={dismissPickers}
            leftIcon={{
              name: 'account-circle',
              type: 'material-community',
            }}
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
          />
          <Input
            label='Email*'
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.PROFILE_EMAIL_PLACEHOLDER])}
            editable={props.isRegister || false}
            disabled={!props.isRegister}
            value={profile.email}
            onChangeText={(email: string) => setProfile({ ...profile, email })}
            onTouchEnd={dismissPickers}
            leftIcon={{
              name: 'email',
              type: 'material-community',
            }}
            inputStyle={{ fontSize: FONT_SIZE }}
            labelStyle={{ fontSize: FONT_LABEL_SIZE }}
            keyboardType='email-address'
          />
          <DatePicker
            messages={props.messages}
            value={profile.dob}
            onChangeValue={onChangeDob}
            label={appUtil.formatSentence(props.messages[MESSAGES.DOB])}
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.PROFILE_DOB_PLACEHOLDER])}
            showDatePicker={showDatePicker}
            setShowDatePicker={setShowDatePicker}
            mode='date'
          />
          <Picker
            messages={props.messages}
            label={appUtil.formatSentence(props.messages[MESSAGES.GENDER])}
            placeholder={appUtil.formatSentence(props.messages[MESSAGES.PROFILE_GENDER_PLACEHOLDER])}
            value={profile?.gender}
            data={GENDERS}
            dataLabels={GENDERS.map((d?: string) => d && appUtil.formatSentence(props.messages[d.toLowerCase()]))}
            onChange={(gender: string) => setProfile({ ...profile, gender })}
            icon={{
              name: 'gender-transgender',
              type: 'material-community',
            }}
          />
          {props.isRegister && (
            <Input
              placeholder={appUtil.formatSentence(props.messages[MESSAGES.PROFILE_PASSWORD_PLACEHOLDER])}
              label={appUtil.formatSentence(props.messages[MESSAGES.PASSWORD])}
              secureTextEntry={secureTextEntry}
              value={password}
              onChangeText={setPassword}
              leftIcon={{
                type: 'material-community',
                name: 'lock',
              }}
              rightIcon={
                <TouchableOpacity onPress={() => setSecureTextEntry(!secureTextEntry)}>
                  <Icon
                    name={secureTextEntry ? 'eye-off' : 'eye'}
                    type='material-community'
                  />
                </TouchableOpacity>
              }
              inputStyle={{ fontSize: FONT_SIZE }}
              labelStyle={{ fontSize: FONT_LABEL_SIZE }}
            />
          )}
        </View>
        {props.isRegister ? null : (
          <View style={{ ...styles.gridRow, marginHorizontal: MARGIN_HORIZONTAL, marginBottom: MARGIN_VERTICAL }}>
            <BaseButton
              containerStyle={{ borderRadius: BORDER_RADIUS }}
              buttonStyle={{ justifyContent: 'flex-start', borderRadius: BORDER_RADIUS }}
              backgroundColor='white'
              color={COLORS.BLACK}
              type='clear'
              icon={{
                name: 'lock-reset',
                type: 'material-community',
                color: COLORS.BLACK,
              }}
              title={appUtil.formatSentence(props.messages[MESSAGES.CHANGE_PASSWORD])}
              onPress={() => props.navigation.navigate('ChangePassword')}
            />
          </View>
        )}
        <View style={{ ...styles.gridRow, marginHorizontal: MARGIN_HORIZONTAL }}>
          <BaseButton
            backgroundColor='white'
            color={COLORS.BLUE}
            type='clear'
            onPress={() => setCancel(true)}
            title={props.messages[MESSAGES.CLEAR]}
            containerStyle={{ marginRight: MARGIN_HORIZONTAL }}
          />
          <BaseButton
            backgroundColor={COLORS.TRANSPARENT_BLUE}
            color={COLORS.BLUE}
            type='solid'
            onPress={onSubmit}
            disabled={submitDisabled()}
            title={props.isRegister ? props.messages[MESSAGES.REGISTER] : props.messages[MESSAGES.UPDATE]}
            containerStyle={{ flexShrink: 0.5 }}
          />
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

import axios from 'axios';
import { get, omitBy, isNil, pick } from 'lodash';
import Constants from 'expo-constants';
import moment from 'moment';
import { Profile, ProfileWithPassword } from '#data/user';

const api_url = get(Constants, 'manifest.extra.apiUrl');

const login = async (email: string, password: string, remember = true) => {
  const url = `${api_url}/accounts/login`;
  return axios
    .post(url, {
      email,
      password,
      remember,
    })
    .then((response) => get(response, 'data'));
};

const register = async (profile: ProfileWithPassword) => {
  const url = `${api_url}/accounts/register`;
  const data = {
    ...profile,
    dob: get(profile, 'dob') ? moment(get(profile, 'dob')).format('YYYY-MM-DD') : null,
  };
  return axios.post(url, omitBy(data, isNil)).then((response) => get(response, 'data'));
};

const update = async (id: string, token: string, profile: Profile) => {
  const url = `${api_url}/accounts/${id}`;
  return axios.put(url, profile, { headers: { Authorization: `Bearer ${token}` } }).then((response) => get(response, 'data'));
};

const fetchProfile = async (id: string, token: string): Promise<Profile> => {
  const url = `${api_url}/accounts/${id}`;
  const profile = await axios
    .get(url, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => pick(get(response, 'data'), ['name', 'email', 'dob', 'gender', 'activated', 'subscribing', 'role']));
  return {
    ...profile,
    dob: profile.dob && moment(profile.dob).toDate(),
  };
};

const forgetPassword = async (email: string, locale: string, deeplink: string) => {
  const url = `${api_url}/accounts/forget-password`;
  return axios
    .post(
      url,
      {
        email,
        deeplink: `${api_url}/redirects/to?url=${deeplink}`,
      },
      {
        headers: { 'Accept-Language': locale },
      },
    )
    .then((response) => get(response, 'data'));
};

const resetPassword = async (token: string, password: string) => {
  const url = `${api_url}/accounts/reset-password`;
  return axios.post(url, { token, password }).then((response) => get(response, 'data'));
};

const changePassword = async (token: string, password: string, newPassword: string) => {
  const url = `${api_url}/accounts/change-password`;
  return axios.post(url, { password, newPassword }, { headers: { Authorization: `Bearer ${token}` } }).then((response) => get(response, 'data'));
};

export default {
  login,
  register,
  update,
  fetchProfile,
  forgetPassword,
  resetPassword,
  changePassword,
};

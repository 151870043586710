/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';
import COLORS from '#constants/colors';
import { BORDER_RADIUS, MARGIN_VERTICAL } from '#constants/styles';

export default StyleSheet.create({
  content: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 16,
  },
  logo: {
    borderRadius: BORDER_RADIUS,
    height: 150,
    marginVertical: MARGIN_VERTICAL,
    width: 150,
  },
  page: {
    alignItems: 'center',
    backgroundColor: COLORS.CHROME_GREY,
    flex: 1,
    justifyContent: 'center',
    overflow: 'hidden',
  },
});

/* eslint-disable react-native/no-color-literals */
import { BORDER_RADIUS, FONT_SIZE, MARGIN_HORIZONTAL } from '#constants/styles';
import COLORS from '#constants/colors';
import { StyleProp } from 'react-native';

const STYLES: StyleProp<any> = {
  button: { flex: 0 },
  buttonContainer: {
    borderRadius: 0,
    flex: 0,
    marginHorizontal: 0,
  },
  buttons: {
    borderTopColor: COLORS.GRAY,
    borderTopWidth: 0.5,
    flex: 0,
  },
  container: {
    alignItems: 'stretch',
    borderRadius: BORDER_RADIUS,
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    padding: 0,
    width: '85%',
    zIndex: 1000,
  },
  content: {
    fontSize: FONT_SIZE,
    marginHorizontal: MARGIN_HORIZONTAL,
    textAlign: 'center',
  },
  contentContainer: {
    alignItems: 'stretch',
    borderRadius: BORDER_RADIUS,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 24,
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginHorizontal: MARGIN_HORIZONTAL,
    padding: 0,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
};

export default STYLES;

import axios from 'axios';
import { get, omitBy, isNil, concat } from 'lodash';
import Constants from 'expo-constants';
import moment from 'moment';

const api_url = get(Constants, 'manifest.extra.apiUrl');

const saveMedication = async (userId: string | undefined, token: string | undefined, data: any) => {
  if (!userId || !token || !data) {
    throw new Error('Please login');
  }
  const url = `${api_url}/medications/${userId}`;
  return axios
    .post(
      url,
      omitBy(data, (value) => isNil(value) || value === ''),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => get(response, 'data'));
};

const updateMedication = async (userId: string | undefined, token: string | undefined, id: string, data: any) => {
  if (!userId || !token || !data || !id) {
    throw new Error('Please login');
  }
  const url = `${api_url}/medications/${userId}/${id}`;
  return axios
    .put(
      url,
      omitBy(data, (value) => isNil(value) || value === ''),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => get(response, 'data'));
};

const deleteMedication = async (userId: string | undefined, token: string | undefined, id: string) => {
  if (!userId || !token || !id) {
    throw new Error('Please login');
  }
  const url = `${api_url}/medications/${userId}/${id}`;
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => get(response, 'data'));
};

const getSingleMedication = async (userId: string | undefined, token: string | undefined, id: string) => {
  if (!userId || !token || !id) {
    throw new Error('Please login');
  }
  const url = `${api_url}/medications/${userId}/${id}`;
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => get(response, 'data'));
};

const getMedications = async (
  userId: string | undefined,
  token: string | undefined,
  page: number,
  size = 3,
  fromEpoch = undefined,
  toEpoch = undefined,
) => {
  if (!userId || !token) {
    throw new Error('Please login');
  }
  const epochQuery = fromEpoch && toEpoch ? `&fromEpoch=${moment(fromEpoch).valueOf()}&toEpoch=${moment(toEpoch).valueOf()}` : '';
  const url = `${api_url}/medications/${userId}?page=${page}&size=${size}${epochQuery}`;
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => get(response, 'data'));
};

const getAllMedications = async (userId: string | undefined, token: string | undefined, size = 10) => {
  const result = [];
  let page = 0;
  let totalPage = 1;
  while (page < totalPage) {
    const data = await getMedications(userId, token, page, size);
    result.push(...get(data, 'content', []));
    page += 1;
    totalPage = get(data, 'totalPages');
  }
  return result;
};

const getMedicines = async (token: string | undefined) => {
  if (!token) {
    throw new Error('Please login');
  }
  let result: any[] = [];
  const url = `${api_url}/medicines`;
  for (let page = 0; page < 100; page++) {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: page,
        size: 10,
      },
    });
    const data = get(response, 'data.content') || [];
    if (data.length === 0) {
      break;
    }
    result = concat(result, data);
  }
  return result;
};

export default {
  saveMedication,
  updateMedication,
  deleteMedication,
  getSingleMedication,
  getMedications,
  getMedicines,
  getAllMedications,
};

/* eslint-disable react-native/no-inline-styles */
import React, { useState } from 'react';
import { SafeAreaView, ScrollView, TouchableOpacity } from 'react-native';
import { Icon, CheckBox, Text } from '@rneui/themed';
import styles from '#pages/styles/settings.page';
import { MESSAGES, LANGUAGES } from '#constants/index';
import appUtil from '#utils/app.util';
import COLORS from '#constants/colors';
import { Messages } from '#data/extras';
import { NavigationProp } from '@react-navigation/core';
import { Settings } from '#data/settings';
import LoadingPage from './loading.page';

interface LanguagePageProps {
  settings: Settings;
  onChangeSettings(settings?: Settings, reload?: boolean, callback?: () => void): void;
  messages: Messages;
}

export function LanguagePage(props: LanguagePageProps) {
  const [loading, setLoading] = useState(false);
  const onChangeLanguage = (locale: string) => {
    setLoading(true);
    props.onChangeSettings({ ...props.settings, locale }, true, () => setLoading(false));
  };

  return loading ? (
    <LoadingPage withImage />
  ) : (
    <SafeAreaView style={styles.page}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <CheckBox
          iconType='material-community'
          checkedIcon='checkbox-marked-circle'
          uncheckedIcon='checkbox-blank-circle-outline'
          title={props.messages[MESSAGES.VIETNAMESE]}
          checked={props.settings.locale === LANGUAGES[MESSAGES.VIETNAMESE]}
          onPress={() => onChangeLanguage(LANGUAGES[MESSAGES.VIETNAMESE])}
          containerStyle={styles.checkBox}
          textStyle={styles.checkBoxText}
          checkedColor={COLORS.BLUE}
        />
        <CheckBox
          iconType='material-community'
          checkedIcon='checkbox-marked-circle'
          uncheckedIcon='checkbox-blank-circle-outline'
          title={props.messages[MESSAGES.ENGLISH]}
          checked={props.settings.locale === LANGUAGES[MESSAGES.ENGLISH]}
          onPress={() => onChangeLanguage(LANGUAGES[MESSAGES.ENGLISH])}
          containerStyle={styles.checkBox}
          textStyle={styles.checkBoxText}
          checkedColor={COLORS.BLUE}
        />
      </ScrollView>
    </SafeAreaView>
  );
}

interface SettingPageProps {
  messages: Messages;
  navigation: NavigationProp<any>;
}

function SettingsPage(props: SettingPageProps) {
  return (
    <SafeAreaView style={styles.page}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <TouchableOpacity style={styles.listItem} onPress={() => props.navigation.navigate('Language')}>
          <Icon name='translate' type='material-community' color={COLORS.BLUE} />
          <Text style={styles.listItemText}>{appUtil.formatSentence(props.messages[MESSAGES.LANGUAGE])}</Text>
          <Icon name='arrow-right' type='material-community' color={COLORS.BLUE} />
        </TouchableOpacity>
      </ScrollView>
    </SafeAreaView>
  );
}

export default SettingsPage;

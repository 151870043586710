/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React, { useState } from 'react';
import { Picker as ReactNativePicker } from '@react-native-picker/picker';
import { Platform, View } from 'react-native';
import { Icon, Input, Overlay, Text } from '@rneui/themed';
import styles from './styles/picker.input';
import { Messages } from '#data/extras';
import BaseButton from '#components/buttons';
import COLORS from '#constants/colors';
import { MESSAGES } from '#constants/index';
import { BORDER_RADIUS, MARGIN_VERTICAL, MARGIN_HORIZONTAL, FONT_SIZE, FONT_LABEL_SIZE } from '#constants/styles';
import appUtil from '#utils/app.util';

interface PickerProps {
  messages: Messages;
  label: string;
  placeholder?: string;
  data: (string | undefined)[];
  dataLabels: (string | undefined)[];
  value: string | undefined;
  onChange(value: string, index?: number): any | Promise<any>;
  icon: {
    name: string;
    type: string;
  };
}

export default function Picker(props: PickerProps) {
  const [showPicker, setShowPicker] = useState(false);
  return Platform.OS === 'ios' ? (
    <>
      <Input
        label={props.label}
        placeholder={props.placeholder}
        editable={false}
        value={props.value && appUtil.formatSentence(props.messages[props.value])}
        leftIcon={props.icon}
        inputStyle={{ fontSize: FONT_SIZE }}
        labelStyle={{ fontSize: FONT_LABEL_SIZE }}
        onPressOut={() => setShowPicker(true)}
      />
      <Overlay
        isVisible={showPicker}
        overlayStyle={{
          position: 'absolute',
          bottom: MARGIN_VERTICAL,
          flex: 1,
          borderRadius: 0,
          flexDirection: 'column',
          minWidth: '100%',
          backgroundColor: 'transparent',
          padding: 0,
        }}
        onBackdropPress={() => setShowPicker(false)}>
        <ReactNativePicker
          mode='dialog'
          selectedValue={props.value}
          onValueChange={props.onChange}
          style={{
            backgroundColor: 'white',
            borderRadius: BORDER_RADIUS,
            marginBottom: MARGIN_VERTICAL,
            marginHorizontal: MARGIN_HORIZONTAL,
            overflow: 'hidden',
          }}>
          {props.data.map((value: string | undefined, index: number) => (
            <ReactNativePicker.Item label={props.dataLabels[index]} value={value} key={index} />
          ))}
        </ReactNativePicker>
        <BaseButton
          type='solid'
          color={COLORS.BLUE}
          backgroundColor='white'
          title={props.messages[MESSAGES.DONE]}
          onPress={() => setShowPicker(false)}
          titleStyle={{ fontSize: 20 }}
          containerStyle={{ marginBottom: MARGIN_VERTICAL, marginHorizontal: MARGIN_HORIZONTAL }}
        />
      </Overlay>
    </>
  ) : (
    <View style={styles.inputContainerStyle}>
      <Text style={styles.label}>{props.label}</Text>
      <View style={styles.inputStyle}>
        <Icon {...props.icon} />
        <ReactNativePicker mode='dialog' selectedValue={props.value} onValueChange={props.onChange} style={{ flex: 1 }}>
          {props.data.map((value: string | undefined, index: number) => (
            <ReactNativePicker.Item label={props.dataLabels[index]} value={value} key={index} />
          ))}
        </ReactNativePicker>
      </View>
      <View style={styles.border} />
    </View>
  );
}
